import React from 'react'


export default function ButtonShine({...props}) {
  return (
    <div className={(props.position && props.position === true) ? 'btnshine-absolute':'btnshine-position'}>
    <div className="btnshine-container">
    <div className={props.center ? "btnshine-center" : "btnshine-div"}>
      <button 
      id="shine-btn"
      ref={props.theRef ? props.theRef : null}
      name={props.name}
      type={props.type ? props.type : "button"}
      disabled={props.disabled}
      onClick={props.onClick&&props.onClick}
      onPointerOut={props.onPointerOut&&props.onPointerOut}
      onPointerOver={props.onPointerOver&&props.onPointerOver}
      className={props.className?props.className:"btnshine"}>
        <svg id="btnshine-svg" width="180px" height="60px" viewBox="0 0 180 60" className="border">
          <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
          <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
        </svg>
        <span>{props.label}</span>
      </button>
    </div>
  </div>
  </div>
  )
}
