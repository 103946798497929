import React from 'react'
import ArticleImage from './ArticleImage';


export default function HomeArticleImages({...props}) {


  return (
    <div className='article-images' id={"toAnim"+props.articleNb}>
      {props.images && props.images.map((anImage: {path: string, alt:string}, index: number)=>(
          <ArticleImage
          isSmallDevice={props.isSmallDevice}
          id={"toAnim" + props.articleNb + index}
          articleId={props.id}
          nb={props.images.length}          
          articleNb={props.articleNb}
          key={index}
          index={index}
          path={anImage.path}
          alt={anImage.alt} />
      ))}
    </div>
  )
}
