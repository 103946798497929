import './style/App.css';
import './style/Bubble.css';
import Site from './pages/Site';
import { BrowserRouter } from 'react-router-dom';
import TheContextProvider from './contexts/EquilysContext';



declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }
}





function App() {
  
  const disableReactDevTools=()=>{
       
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
      }
    }
  }
  const disableConsoleLog=()=>{
    console.log = function () {};
  }
/* ICI */
if (!window.location.href.includes("http://localhost")){
    disableReactDevTools();
    disableConsoleLog();
} 

  const props = {siteName : "equilys-ingrid.fr", tel : "06 22 94 11 67"}
  return (
   
      <BrowserRouter>
      <TheContextProvider> 
        <Site {...props} />        
      </TheContextProvider>
     </BrowserRouter>
     
  
  );
}

export default App;
