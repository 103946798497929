import React from 'react'
import ButtonShine from './ButtonShine'

export default function SignInForm({...props}) {
  return (
    <div><div className='sign-form-wrapper'>
      <div className='sign-title'> Connectez-vous</div>
    <div className='sign-input-wrapper'>
      <label className='input-label' htmlFor='email'>Votre mail</label>
      <input id="email" name="email" className='form-input' type="email"></input>
    </div>
    <div className='sign-input-wrapper'>
      <label className='input-label' htmlFor='password'>Votre mot de passe</label>
      <input id="password" name="password" className='form-input' type="password"></input>
    </div>
    <div className='sign-button-wrapper'>
     <ButtonShine 
     position={false}
     onClick={props.onClick} 
     label={props.btnLabel}/>
    </div>
    </div>
    </div>
  )
}
