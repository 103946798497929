import React from 'react';
import teachIntro from '../images/home/teachIntro.jpg';
import HomeArticleImages from './HomeArticleImages';
import home01 from '../images/home/homePrestaBckg.png';
import prestaIntro from '../images/home/homePeda01.jpg';
import ButtonShine from './ButtonShine';
import { useNavigate } from 'react-router-dom';

export default function HomePresta({...props}) {
  const navigate= useNavigate();

  return (
    
   <div className='home-presta-container'>    
    <div className='home-presta-wrapper'>      
      <img src={prestaIntro} className="home-teaching-image"></img>
      <div className='home-presta-text-wrapper'>
        <h1 className='home-presta-title'>Accompagnement</h1>
        <div className='home-presta-text'>        
          <p className='home-presta-text-first'>
          Cours particuliers ou collectifs, Ingrid propose aussi ses services aux structures équestres dans les départements suivants : 31, 32, 81 et 82
    
          </p>
          <p className='home-presta-text-second'>          
          Elle encadre petits et grands avec la même énergie, tout en s'adaptant au niveau et à l'âge de chacun. Ingrid propose aussi le travail du cheval, à pied ou monté, dans des disciplines variées. Journées de stage thématiques, sorties au lac ou à la mer, elle adoooore proposer de nouveaux challenges à ses élèves !
          </p>          
        </div>
        <img className='home-presta-text-background' src={home01} alt="fond prestations"></img>
      <ButtonShine label="En savoir plus..." onClick={()=>navigate('/prestations')}/>
      </div>
      
    </div>
    <div className='home-article-container'>
    {props.imgs && 
      <HomeArticleImages             
      isSmallDevice={props.isSmallDevice}
      id={"imgBlock"+props.articleNb} 
      articleNb={props.articleNb} 
      images={props.imgs}
      />
    }
    </div>
    <div className='home-teaching-title-wrapper'></div>
   </div>
  )
}
