import React, { useEffect, useRef, useState } from 'react'
import { CwitnessData, witnessesData } from '../data'
import witnessHero from "../images/temoignages/witnesseHero.jpg";
import heroPhone from "../images/temoignages/witnessHeroPhone.jpg";
import { useLocation } from 'react-router-dom';
import AnimatedNavbar from '../components/AnimatedNavbar';
import PageTitle from '../components/PageTitle';
import AWitness from '../components/aWitness';

export default function Temoignages({...props}) {

    const location = useLocation();
    const timerRef = useRef<any>(null);
    const clicked = (location.state && location.state.clicked)? location.state.clicked : null

    useEffect(() => {
        props.setClickedNavId(-1);
        return () => {
          console.log("on quitte enseignement");
          props.setClickedNavId(-2);
          clearTimeout(timerRef.current);
        }
      
      }, [])
      useEffect(()=>{
        /**Scroll element into view */     
        if(clicked)   {
          let witnessDiv = document.getElementById("witness"+clicked);   
          if(witnessDiv){          
            witnessDiv.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }
        }else{
          window.scrollTo({top: 0, behavior:'smooth'});
        }
      })
    
    const heroProps = {
        ...props,
        className: "hero-title-witness", 
        title1: "", 
        title2: "", 
        hero: witnessHero, 
        classNameImage:"hero-photo",
        telHero: heroPhone
        
    }
  return (
    <><AnimatedNavbar {...props}{...heroProps}/>
  
        <div className={props.className}>
        <PageTitle title="Témoignages"/>
        <div className='witness-container'>
            {witnessesData && witnessesData.map((aData: CwitnessData)=>(
              <AWitness 
              {...aData}             
              /> 
            ))}
        </div>
        </div>
    </>
  )
}
