import React, { useEffect, useRef } from 'react'
import ButtonShine from './ButtonShine';
import { useNavigate } from 'react-router-dom';
import ObervedCompo from './ObservedCompo';
import ObservedCompo from './ObservedCompo';
import EquilysPlume from './EquilysPlume';

interface IpartageProps { 
    title: string, 
    path: string, 
    content: string[],
    practice: JSX.Element
  }

function ArticlePartage(aPartage: IpartageProps, key: number) {
    const sectionRef = useRef<any>(null);
    const navigate = useNavigate();

    useEffect(()=>{
    /**Interceptor for partage */
    const sectionObserver = new IntersectionObserver((observed)=>{
        if(observed[0].isIntersecting && sectionRef.current){          
          sectionRef.current.classList.add("active");
          console.log("is intersecting : ", sectionRef);
        }else{          
          if (sectionRef.current)
            sectionRef.current.classList.remove("active");
            console.log("no more intersection : ", sectionRef);
        }
      })
  
      if (sectionRef.current)
        sectionObserver.observe(sectionRef.current);
    return ()=>{
        sectionObserver.disconnect();
    }

    },[])
    
    return (
        <div key={key} className='partage-article-wrapper'>
          <EquilysPlume/>
            <div  className='partage-separation'>
              <div ref={sectionRef} className='partage-article-title'>Accompagnement en ligne | {aPartage.title}</div>         
           </div>
           <div className='partage-article'>
              <div className='partage-article-first'>                 
                <div >{aPartage.content.map((aContent:string, index: number)=>(
                  <ObservedCompo 
                    key= {index}
                    className='partage-article-content'>
                    {aContent}
                  </ObservedCompo>
                ))}
                
                </div>             
              </div>
              <div className='partage-article-second'>                
                <img className="partage-article-img" src={aPartage.path}></img> 
              </div>

              </div>
              <div className='practice-wrapper'>                
                <div className='practice-button-wrapper'>
                  {aPartage.practice}
                  {aPartage.title.includes("Communication") &&<ButtonShine label="Découvrir" onClick={(e: React.MouseEvent)=>navigate('/comanimale')}/>      
                  }
                </div>
              </div>
            </div>
    )
}

export default ArticlePartage
