import React, { useEffect, useState } from 'react'
import AnimatedNavbar from './AnimatedNavbar'
import SideNav from './SideNav';
import HeroPresta from './HeroPresta';

export default function HeroNavPresta({...props}, {...children}) {
  const windowHeight = window.innerHeight;  
  const [changeNav, setChangeNav] = useState<boolean>(true);
  const initHeroBottom = document.getElementById("hero-photo")?.getBoundingClientRect().bottom;
  const [heroBottom, setHeroBottom] = useState<number>(initHeroBottom?initHeroBottom:0);
  const [isImgLoaded , setIsImgLoaded] = useState<boolean>(false);
     
  const onLoadedData = () => {
    console.log("metadata loaded");
    setIsImgLoaded(true);
  };
  
  
  function formatVisibleDiv(theDiv: HTMLElement) {    
    // la div
    const topTitle = theDiv.getBoundingClientRect().top;
    const bottomTitle = theDiv.getBoundingClientRect().bottom;
    const isVisible = bottomTitle > 0 && topTitle < windowHeight;
    return isVisible;
  }
  function handleDivVisibility(cardDiv: HTMLElement) {
    const isVisibleDiv = formatVisibleDiv(cardDiv);   
    const newHeroBottom = cardDiv.getBoundingClientRect().bottom;
    if (newHeroBottom && heroBottom !== newHeroBottom)
    setHeroBottom(newHeroBottom); 
    setChangeNav(isVisibleDiv ? true : false);
  }
  const checkIndexScroll = () => {       
    const heroWrapper = document.getElementById("heroWrapper");
    if(heroWrapper)
    handleDivVisibility(heroWrapper);
    
  }
  function letsUpdateHero(newBottom: number){    
      setHeroBottom(newBottom);    
  }

  /**MoUNT */
  useEffect(() => { 
    /*init navbar at the bottom of hero*/    
    const heroBottom = document.getElementById("hero-photo")?.getBoundingClientRect().bottom;
    if (heroBottom){          
      setHeroBottom(heroBottom);
      //setChangeNav(true);
    }
    window.addEventListener('scroll', checkIndexScroll, false);
    window.addEventListener('resize', checkIndexScroll, false);
    
    return () => {
      window.removeEventListener('scroll', checkIndexScroll, false);
      window.removeEventListener('resize', checkIndexScroll, false);
    }
  }, [])
  
  /**UPDATE */
  useEffect(() => {
    const newHeroBottom = document.getElementById("hero-photo")?.getBoundingClientRect().bottom;
    if(isImgLoaded && heroBottom !== newHeroBottom){
      if (newHeroBottom){          
        setHeroBottom(newHeroBottom);
        //setChangeNav(true);
      }
    }
   
    
    return () => {
      
    }
  }, [heroBottom, isImgLoaded])
  
  const heroProps = {...props, isSmallDevice: props.isSmallDevice, heroBottom:heroBottom, letsUpdateHero: letsUpdateHero,  isVisibleHero:changeNav,together: props.together, onLoadedData: onLoadedData, ...children}
  return (
    <div className='hero-container'>
    {props.clickedNavId !== -1 && props.clickedNavId !== -2 && <SideNav {...heroProps}/>}
    <HeroPresta     
    id="hero-photo"
    {...heroProps}   
    />
    <AnimatedNavbar {...heroProps}/>
    </div>
    )
  }
