import React, { useEffect, useState } from 'react'
import { ArticleWitnessWrapper, LargeContainer, VisibleContainer } from '../common/StyledComponents'
import { CwitnessData, witnessesData } from '../data'
import {FiChevronLeft} from 'react-icons/fi';
import {FiChevronRight} from 'react-icons/fi';
import ButtonShine from './ButtonShine';
import { useNavigate } from 'react-router-dom';
import {HiUserCircle} from 'react-icons/hi';

export default function Witnesses({...props}) {

  const [scrolledPosition, setScrolledPosition] = useState<number>(0);
  const [truncatedWitnesses, setTruncatedWitnesses] = useState<CwitnessData[]>(witnessesData);
  const article = {width: 350, padding: 60};
  const navigate = useNavigate();
  

    useEffect(() => {
      const scrolledDiv = document.getElementById("scrolled-div");
      const scrolledDivPosition = scrolledDiv?.getBoundingClientRect().left;
      if(scrolledDivPosition){
        setScrolledPosition(scrolledDivPosition);
      }
      // truncate witnesses
      const truncatedWitnesses = witnessesData && witnessesData.map((aData: CwitnessData)=>{
        return truncateData(aData);
      })
      setTruncatedWitnesses(truncatedWitnesses);
      return () => {
        
      }
    }, [])

  /**
   * 
   * @param data 
   * @returns a CWitness object with a table of string truncated
   */
function truncateData(data: CwitnessData): CwitnessData    
{
 const truncatedDetails = data.details[0].slice(0,150) + "...";
 
 const newWitnessData = {...data, details: [truncatedDetails]};
 return newWitnessData;
}
const handleWitnessesClick = ( e: React.MouseEvent<HTMLButtonElement>, clicked: number) =>{
  const options = {state: {...props, clicked}};
  console.log ("state wit : ", options);
  e.preventDefault();
  navigate('/temoignages', options);
}
    
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) =>{      
      console.log("click : ", e.currentTarget);
      letsScrollElt(e.currentTarget.name);
    }
    function letsScrollElt(id: string){
      const scrolledDiv = document.getElementById("scrolled-div");
      if(scrolledDiv){
        let newPosition = scrolledPosition;
        if (id === "right-arrow"){
          console.log("right")
          newPosition = scrolledPosition + article.width;
            
        }else{
          console.log("left")
          newPosition = scrolledPosition - article.width;
         
        }
        scrolledDiv.scrollTo({left:newPosition, behavior:'smooth'});
       
      }
    }
    const handleScroll = (e: any)=>{
        const element = e.target;
        setScrolledPosition(element.scrollLeft);
       if (element.scrollWidth - element.scrollLeft - element.getBoundingClientRect().x < element.clientWidth) {
            // loop            
            element.scrollLeft=0;
            setScrolledPosition(0);
          }
          
    }
  return (
    <div className='witness-carousel-wrapper'>  
     <h1 className='home-gold'>Paroles d'élèves</h1>
   
    <VisibleContainer id='scrolled-div' className='scrolled-div' onScroll={(e:any)=>handleScroll(e)} windowWidth={window.innerWidth} itemPadding={article.padding} itemWidth={article.width} nb={witnessesData.length}>
       <LargeContainer id='large-container' windowWidth={window.innerWidth} itemPadding={article.padding} itemWidth={article.width} nb={witnessesData.length}>
        {truncatedWitnesses && truncatedWitnesses.map((aData: CwitnessData, index: number)=>(
            <ArticleWitnessWrapper key={index} itemPadding={article.padding} itemWidth={article.width}>
                <div className='block-badge-wrapper'>
                  <div key={index} className={aData.photo?'badge-bottom-img-wrapper':'badge-bottom-fake-wrapper'}>
                      {aData.photo ? <img src={aData.photo} className="badge-bottom-img" alt={aData.signature}></img>:<HiUserCircle className="badge-bottom-img"/>}
                    </div>
                  <div className='article-details'>
                  {aData.details}
                  </div>
                </div>
                <div className='article-signature'>
                {aData.signature}
                </div>
                <ButtonShine 
                position={false}
                label="... SUITE" 
                onClick={(e: React.MouseEvent<HTMLButtonElement>)=> handleWitnessesClick(e,aData.id)}>
                
                </ButtonShine>
            </ArticleWitnessWrapper>
        ))}
        </LargeContainer>
    </VisibleContainer>
    <div className='arrow-wrapper'>
      <button 
      className='arrow-btn'
      name='left-arrow' 
      onClick={(e: React.MouseEvent<HTMLButtonElement>)=> handleClick(e)}>
        <FiChevronLeft className='arrow-svg'/>
      </button> 
      <button 
      className='arrow-btn'
      name='right-arrow' 
      onClick={(e: React.MouseEvent<HTMLButtonElement>)=> handleClick(e)}>
        <FiChevronRight className='arrow-svg'/>
      </button>
    </div>
    </div>
  )
}
