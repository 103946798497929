import React, { useEffect, useRef, useState } from 'react'
import ActuCompo from '../components/ActuCompo';
import HeroNav from '../components/HeroNav';
import hero from "../images/actus/groupePtv.jpg";
import heroPhone from "../images/actus/groupePtv.jpg";
import PageTitle from '../components/PageTitle';

export default function Actualities({...props}) {   
    const timerRef = useRef<any>(null);    

    useEffect(() => {
      window.scrollTo({top: -500, behavior: 'smooth'}); 
      timerRef.current = setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 200);
      props.setClickedNavId(4);
      return () => {
        console.log("on quitte actualités");
        clearTimeout(timerRef.current);
      }
    
    }, [])

    const heroProps = {...props, 
      telHero: heroPhone, 
      hero: hero, 
      title1:"Ingrid Equilys",
      title2: "Les stages",
      classNameImage: "hero-photo-actus",     
      clickedNavId: props.clickedNavId,
      className: 'hero-com',
      heroTitle: "hero-com-title"
    
    }

  return (
    <>
    <HeroNav {...heroProps} />    
    <div className={props.className}>   
    <PageTitle title="Actualités"/>  
      <div className='wrapper-actualities'>      
        <ActuCompo {...props}/>    
      </div>
    </div>      
    </>
  )
}
