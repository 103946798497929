import React, { useEffect } from 'react'


interface PropsType{
 
  id: number,
  details: string[],
  signature?: string,
  title?: string,
  photo?: string
    
}


function AWitness(props: PropsType) {  
 
    useEffect(()=>{
    
    })
    return (
        <div id={"witness"+props.id} key={props.id} className='witness-data-container'>{props.title &&<h2 className="gold-witness">{props.title}</h2>}
        <div className='witnessData'>            
        <img className='p01-img' src={props.photo} alt="test badge" />
        <div className='witnessData-signature'>
          {props.signature}
        </div>

        <div className='witnessData-details'>
          {props.details.map((aDetail: string, index: number)=>(
            <div key= {index}>{aDetail}</div>
          ))}
        </div>

      </div>
      </div>
    )
}

export default AWitness
