import { useEffect, useRef, useState } from 'react';
import hero2 from '../images/home/Hero02.jpg';
import hero from '../images/home/Hero01.jpg';
import Witnesses from '../components/Witnesses';
import plume from '../images/common/equilys_plume.png';
import HeroNav from '../components/HeroNav';
import ALaUne from '../components/ALaUne';
import { useLocation } from 'react-router-dom';
import HomeIntro from '../components/HomeIntro';
import homeTeachImg01 from '../images/home/Cp.png';
import homeTeachImg02 from '../images/home/homeTeachBlock02.jpg';
import homeTeachImg03 from '../images/home/homeTeachBlock03.jpg';
import homeTeachImg04 from '../images/home/homeTeachBlock04.jpg';
import HomeTeaching from '../components/HomeTeaching';
import HomePresta from '../components/HomePresta';
import homePrestaBlock01 from '../images/home/prestaBlock01.jpg'; 
import homePrestaBlock02 from '../images/home/prestaBlock02.jpg';
import homePrestaBlock03 from '../images/home/prestaBlock03.jpg';
import homePrestaBlock05 from '../images/home/prestaBlock05.jpg';
import homePrestaBlock04 from '../images/home/prestaBlock04.png';
import Transition from '../components/Transition';
import HomeSharing from '../components/HomeSharing';
import HomeAnimalCom from '../components/HomeAnimalCom';
import BackgroundMek from '../components/BackgroundMek';

const teachImgs = [
  {path: homeTeachImg01, alt: "enseignement01"},
  {path: homeTeachImg04, alt: "enseignement02"},
  {path: homeTeachImg03, alt: "enseignement03"}, 
  {path: homeTeachImg02, alt: "enseignement04"}
]
const prestaImgs = [
  {path: homePrestaBlock05, alt: "prestations"},
  {path: homePrestaBlock01, alt: "prestations image01"},  
  {path: homePrestaBlock02, alt: "prestations image02"}, 
  {path: homePrestaBlock03, alt: "prestations image03"}, 
  {path: homePrestaBlock04, alt: "prestations image04"}
 
]

export default function Home({...props}) {
  const timerRef = useRef<any>(null);
  const location = useLocation();

  //MOUNT
useEffect(() => {
    props.setClickedNavId(0);
    window.scrollTo({top: -500, behavior: 'smooth'});
    let goTo: number | null | undefined = null;
    if (location.state && location.state.goToTrainingZone){
      console.log(" state from reserve : ", location.state);
      goTo = document.getElementById("aLaUne")?.getBoundingClientRect().top;
    }
   if(goTo){
    timerRef.current = setTimeout(() => {
      // if comeFrom Prestation        
          window.scrollTo({top:goTo?goTo:0});
    }, 200);
  }else{
    timerRef.current = setTimeout(() => {
      // if comeFrom Prestation        
          window.scrollTo({top:0});
    }, 200);
  }
   
   
    return () => {
      console.log("on quitte home");
      clearTimeout(timerRef.current);
      props.setClickedNavId(-2);
    }
  }, [])
 
  const heroProps = {...props, 
    telHero: hero, 
    hero: hero2, 
    title1:"Ingrid Equilys",
    title2: "Parlons cheval",
    classNameImage: "hero-photo",
    className: 'hero-com',
    heroTitle: "hero-com-title"
  }
  return (
    <>
    <BackgroundMek isActiv={true}/>     
    <HeroNav {...heroProps} />
    <div className='margin-div'></div>
    <div 
    className={props.className}>
   
    <div className={props.clickedNavId === 0 ? 'home-container': 'home-container-start'}>    
   {/*  <H1Prez className="gold" title="Présentation" image={home01} alt="home-image"/> */}
    
    <HomeIntro/>    
    <img src={plume} alt="equilys plume" className='equilys-plume'/>
     <HomeTeaching isSmallDevice={props.isSmallDevice} articleNb={2} imgs={teachImgs}/>
     <div className='home-trans-mask'><Transition/></div>
     <HomePresta isSmallDevice={props.isSmallDevice} articleNb={3} imgs={prestaImgs}/>
      </div>
      <div className='margin-div'></div>
      <HomeAnimalCom/>
      <div className='margin-div'></div>
      <HomeSharing isSmallDevice={props.isSmallDevice} articleNb={4}/>
      <div className='margin-div'></div>
        <div id="aLaUne" className='alaune-container'>
          <ALaUne/>         
        </div>
     <div className='margin-div-alaune'></div>
      <Witnesses isSmallDevice={props.isSmallDevice}/>
      </div>
    
    </>
    )
  }
