import React from 'react'
import effect from "../images/common/decoEffect.png";


function PageTitle({...props}) {
    
    return (
        <div className='title-wrapper'>
            <h1 className={props.isIntercepting ? 'home-gold-intercepting' :"home-gold"} >{props.title}</h1>
            <img className={props.isIntercepting ?'title-effect-intercepting':'title-effect'} src={effect} alt="Effet titre"></img>
        </div>
    )
}

export default PageTitle
