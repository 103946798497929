import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ButtonShine from '../components/ButtonShine';
import { carouselPresentiel, CPrestaArticleData, prestations } from '../data';
import hero from '../images/prestations/hero.jpeg';
import heroPhone from '../images/prestations/heroPhone.jpeg';
import bubbleImg from '../images/prestations/bubble.png';
import plume from '../images/common/equilys_plume.png';
import { InterceptImg } from '../components/PrestaImg';
import HeroNavPresta from '../components/HeroNavPresta';
import ObservedCompo from '../components/ObservedCompo';
import PageTitlePresta from '../components/PageTitlePresta';
import Bubble from '../components/Bubble';

export default function Prestations({...props}) {
  
  const timerRef = useRef<any>(null);
  const navigate = useNavigate();
  const [textClassName, setTextClassName] = useState<string>("presta-title-wrapper");
  const [isIntercepting, setIsIntercepting] = useState<boolean>(false);
  
  useEffect(() => {    
    
    function formatVisibleDiv(movingDiv: HTMLElement, refDiv: HTMLElement) {        
      // intercept container
      const bottomImage = refDiv.getBoundingClientRect().bottom;  
      // la div
      const topText = movingDiv.getBoundingClientRect().top;
      const bottomText = movingDiv.getBoundingClientRect().bottom;
      const isVisible = (bottomText > 0 && topText < bottomImage) ? true : false;
      return isVisible;
    }
    function handleDivVisibility(movingDiv: HTMLElement, refDiv: HTMLElement) {
      const isIntercepting = formatVisibleDiv(movingDiv, refDiv);
      setIsIntercepting(isIntercepting);
      setTextClassName(isIntercepting?"presta-title-wrapper-with-background":"presta-title-wrapper")
    }
    const checkIndexScroll = () => {       
      //handleDivVisibility(blockToAnim);        
      const imageBlock = document.getElementById("presta-intro-img");
      const textBlock = document.getElementById("presta-intro-text");
      if(imageBlock && textBlock){              
        handleDivVisibility(textBlock,imageBlock); 
      }
    }
    window.scrollTo({top: -500, behavior: 'smooth'}); 
    timerRef.current = setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 200);
    props.setClickedNavId(2);
    window.addEventListener('scroll', checkIndexScroll, false);
    window.addEventListener('resize', checkIndexScroll, false);
    
    return () => {
      props.setClickedNavId(-2);
      clearTimeout(timerRef.current);
      
      window.removeEventListener('scroll', checkIndexScroll, false);
      window.removeEventListener('resize', checkIndexScroll, false);
      
    }
    
  }, [])
  const heroProps = {
    ...props,
    className: "hero-title-witness",
    title1: "Accompagnement présentiel", 
    hero: hero, 
    classNameImage:"hero-photo" , 
    isSmallDevice: props.isSmallDevice,
    telHero: heroPhone,
    clickedNavId: props.clickedNavId,
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>)=>{
    e.preventDefault();
    console.log("name " , e.currentTarget.name);
    const to = '/'+ e.currentTarget.name;
    console.log("to : ", to);
    const state = {goToTrainingZone: true};
    const option = {state};
    navigate(to, option);
  }
  return (
    <>
    
    <HeroNavPresta {...heroProps} together={carouselPresentiel}/>
    
    <div className={props.className}>
    <PageTitlePresta title="« Chevaux et cavaliers unis dans le respect, pour une équitation harmonieuse et sensée »"/>
      
   
    <div className='presta-tut'>
    <Bubble ballClassName="ball presta" wrapperClassName="bubble-wrapper">
      <img src={bubbleImg} className="bubble-img" alt="bubble image"/>
      </Bubble>
    {prestations && prestations.map((aPresta: CPrestaArticleData)=>(
      <div key={aPresta.id} className='presta-container'>   
       
      <div className='presta-h2-wrapper'>
      <ObservedCompo className='presta-container-left'>&nbsp;</ObservedCompo>
      <ObservedCompo className='presta-animated-h2'>
      
        <h2 className='presta-h2'>                    
      {aPresta.title}      
      </h2></ObservedCompo>
      <ObservedCompo className='presta-container-right'>&nbsp;</ObservedCompo>
      </div>
      <div className='presta-intro-wrapper'>
        {carouselPresentiel.map((aData: {title: string, path: string})=>(
          aData.title === aPresta.title && <img className='img-presta-title' src={aData.path} alt={aData.title}></img>
        ))}
      </div>
      {/* {aPresta.id === 2 && <div id="video-teaching" className='video-container'>
      <PrestaVideo loadingImage={jaleante} type="video/mp4" className='bubble-video' url={ingridVideo}/>
    </div>} */}
    <div className='presta-details-container'> 
    <div className='presta-img-container'>                   
    {aPresta.img && aPresta.img.map((anImg: string, index: number)=>(
      (aPresta.img.length<=2 ? index <=1 : index === 0) && <InterceptImg {...aPresta} delay={index%2===0} key={index} anImg={anImg}/>
      
      ))}
      </div>
      <div className='presta-details'>
      <div className="presta-subtitle">{aPresta.subtitle && aPresta.subtitle}</div> 
      {aPresta.details && aPresta.details.map((aDetail: string, index: number)=>(
        <div key={index} className='presta-details-wrapper'>
        <span className='presta-text'>{aDetail}</span>
        
        </div>
        ))}
        {<img src={plume} alt="equilys plume" className='equilys-plume-deco'/>}
        </div>
        <div className='presta-img-container'>
        {aPresta.img && aPresta.img.map((anImg: string, index: number)=>(
          (aPresta.img.length>2 ? index >=1 : index>=2) && <InterceptImg {...aPresta} delay={index%2 === 0} key={index} anImg={anImg}/>
          
          ))}
          </div>
          <ButtonShine label={aPresta.button !== "contact" ? "en savoir plus": aPresta.button} name={aPresta.button} onClick={(e: React.MouseEvent<HTMLButtonElement>)=>handleClick(e)}/>
          </div>
          
          </div>
          ))}
          </div>
          <div className='presta-intro-container'>
          <div className='intro-presta-wrapper'>
          Quelle que soit votre problématique, Ingrid est à votre écoute et se déplace dans les départements 31 | 32 | 81 | 82.
          <ButtonShine 
          position={false}
          label="Contact" 
          name="contact"
          onClick={
            (e: React.MouseEvent<HTMLButtonElement>)=>{
              handleClick(e)
              props.onClick(5)        
            }
            
          }/>
          </div>
          </div>
          
          </div>
         
          </>
          )
        }
