import React, { useState } from 'react'
import { GoCalendar } from 'react-icons/go';
import { TbClockPin, TbHorseToy } from "react-icons/tb";
import { areEqualDates, autoCompleteDetails, convertDateToFr } from '../data';
import { handleIconDisplay } from '../data';
import ButtonShine from './ButtonShine';


function HomeActuCompo({...props}) {  
   
    let details = props.details;
    const splitedDetails = autoCompleteDetails(props.details, props.category);
    
    
    /*State*/
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <>
        <div key={props.index} className='card-wrapper'>
        {props.category ? handleIconDisplay(props.category, null): null}   
        <div key={props.article_id} className={isOpen ? 'home-actu-date-open' : 'home-actu-date'}>
            <GoCalendar className='alaune-calendar' />
            <div className='home-actu-compo-date'>
            <div className='home-actu-date-wrapper'>{props.dt_end && !areEqualDates(props.dt_start, props.dt_end) && "Du "}{props.dt_start && convertDateToFr(new Date(props.dt_start))}</div>
            {props.dt_end && !areEqualDates(props.dt_start, props.dt_end) && <div className='home-actu-date-wrapper'> au {props.dt_end && convertDateToFr(new Date(props.dt_end))}</div>}
            </div>
            </div>
            <div  className={isOpen?'open-card':'card'}> 
                        
            <div className={'card-front'}>
            <div className='home-actu-compo-title'>
                <div className='home-actu-block-title'>{props.article_title}</div>   
            </div> 
                <ButtonShine 
                className="btnshine-bckg" 
                position={true} 
                label="Plus d'infos" 
                type="button" 
                center={true}                 
                onClick={(e: React.MouseEvent<HTMLButtonElement>)=>setIsOpen(true)}
                 />
                {props.image&&
                    <img className='home-actu-compo-img' src={props.image&&props.image}></img>}               
                
            
            </div>
            
        <div className= {"card-back"} onClick={(e: React.MouseEvent<HTMLDivElement>) => { setIsOpen(false); } }>
                    <div className='home-actu-compo-textblock'>
                        <div className="subtitle-textblock">Description </div>
                        {splitedDetails && splitedDetails.map((aDetail: string, index: number) => (
                            <div key={index} className='detail-row'>
                                <p key={index} className='detail-row'>{aDetail}
                                
                                </p>
                            </div>
                        
                        ))}
                       
                        {props.at &&   
                        <div className='detail-row'>                     
                        <div className="subtitle-textblock">Lieu </div>
                        <p className='detail-row'>{props.at}</p></div>
                        }
                        
                        {props.hour &&   
                        <div className='detail-row'>                     
                        <div className="subtitle-textblock">Horaire <TbClockPin/></div>
                        <p className='detail-row'>{props.hour} </p>
                        
                        </div>
                        
                        }
                    {props.option1 && <div><TbHorseToy className='baby-horse'/> <span className='info-italic'>Accessible aux jeunes chevaux</span></div>}
                    </div>
                    
                    
        </div>       
        {isOpen && props.image&&<img className='bckg-actu-compo-img' src={props.image&&props.image}></img>}
       
        </div>
        
        </div>
                
            
            </>
    )
}

export default HomeActuCompo