import { useEffect, useState} from "react";



export default function TeachingVideo ({...props}){
  const [isVideoLoaded , setIsVideoLoaded] = useState<boolean>(false);
 
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  useEffect(()=>{
    const heroHeight = document.getElementById(props.id)?.getBoundingClientRect().bottom;
    if (heroHeight && heroHeight > 0 && heroHeight !== props.heroBottom){
      props.letsUpdateHero(heroHeight);
    }    
  }) 
    return(
    <div id={props.id} className="teaching-video-wrapper">    
    <img className={isVideoLoaded ? "teaching-img-video-loaded" : "teaching-img-vidoe-loading"} src={props.loadingImage} alt="chargement de la video en cours"></img>
    <video onLoadedMetadata={onLoadedData} className={isVideoLoaded ? "teaching-video-loaded" : "teaching-video-loading"} loop={true} autoPlay={true} muted={true} controls={false}>
    <source src={props.url} type="video/mp4"></source>
    </video>
    </div>
    )
    }