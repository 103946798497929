interface Props {
    label: string;
    tel: string;
    mail: string;
    site: string;
}

export const SiteName = (props: Props)=>(
    <span className='mentions-site'>{props.site}&nbsp;</span>   
)
export const Label = (props: Props)=>(
    <span className='signature-mentions'>{props.label}&nbsp;</span>
)
export const TitleLabel = (props: Props)=>(
    <span className='signature-mentions-title'>{props.label}&nbsp;</span>   
)
export const EquilysMail = ()=>(
    <a href="mailto:equilys.ingrid@gmail.com">equilys.ingrid@gmail.com&nbsp;</a>  
)
export const CgvParagraph = ({...props})=>(
    <p className='cgv-article-p'>
        {props.children}
    </p>
)
