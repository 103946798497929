import React, { useEffect, useRef, useState } from 'react'
import { articlePartage, carouselPartage } from '../data';
import hero from '../images/prestations/hero.jpeg';
import heroPhone from '../images/prestations/heroPhone.jpeg';
import AnimatedNavbar from '../components/AnimatedNavbar';
import AppearingCarousel from '../components/AppearingCarousel';
import SideNav from '../components/SideNav';
import ArticlePartage from '../components/ArticlePartage';
import { useNavigate } from 'react-router-dom';
import Transition from '../components/Transition';

interface IpartageProps { 
  title: string, 
  path: string, 
  content: string[],
  practice: JSX.Element
}


export default function Partage({...props}) {
  
  const timerRef = useRef<any>(null);
  const navigate = useNavigate();  
  const [isIntercepting, setIsIntercepting] = useState<boolean>(false);
 
  
    useEffect(() => {     
      props.setClickedNavId(2);
      function formatVisibleDiv(movingDiv: HTMLElement, refDiv: HTMLElement) {        
        // intercept container
        const bottomImage = refDiv.getBoundingClientRect().bottom;  
        // la div
        const topText = movingDiv.getBoundingClientRect().top;
        const bottomText = movingDiv.getBoundingClientRect().bottom;
        const isVisible = (bottomText > 0 && topText < bottomImage) ? true : false;
        return isVisible;
      }
      function handleDivVisibility(movingDiv: HTMLElement, refDiv: HTMLElement) {
        const isIntercepting = formatVisibleDiv(movingDiv, refDiv);
        setIsIntercepting(isIntercepting);
      }
      const checkIndexScroll = () => {       
          //handleDivVisibility(blockToAnim);        
          const imageBlock = document.getElementById("presta-intro-img");
          const textBlock = document.getElementById("presta-intro-text");
          if(imageBlock && textBlock){              
              handleDivVisibility(textBlock,imageBlock); 
          }
        }
      window.scrollTo({top: -500, behavior: 'smooth'}); 
      timerRef.current = setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 200);
      
      window.addEventListener('scroll', checkIndexScroll, false);
      window.addEventListener('resize', checkIndexScroll, false);
    return () => { 
      props.setClickedNavId(-2);
      clearTimeout(timerRef.current);
      window.removeEventListener('scroll', checkIndexScroll, false);
      window.removeEventListener('resize', checkIndexScroll, false);
    }
  
  }, [])
  const heroProps = {
    ...props,
    className: "hero-title-witness",
    title1: "Ingrid Equilys", 
    title2: "Partage", 
    hero: hero, 
    classNameImage:"hero-photo" , 
    isSmallDevice: props.isSmallDevice,
    telHero: heroPhone
    
}

  return (
    <>
      <SideNav {...heroProps}/>
      <AnimatedNavbar {...props} />
      <AppearingCarousel container="appearing-carousel-container" together={carouselPartage}/>
     <div className='partage-article-container'>
     <div className='home-trans-mask'><Transition/></div>
      <div className='partage-articles-intro'>Ingrid a mis en place une série d'accompagnements en ligne initiée à la demande de ses élèves. 
      Ils s'articulent en 4 modules complémentaires : 
      <p className="p-justify-2e">- Le coaching Live,</p>
      <p className="p-justify-2e">- le coaching vidéo, </p>
      <p className='p-justify-2e'>- la communication animale</p>
      <p className='p-justify-2e'>- et la formation à la communication animale.</p>
      Déménagement, horaires de travail compliqués, moyen de transport défaillant ou inexistant... 
      Chacun pour ses propres raisons, peut avoir un jour ou l'autre recours au coaching à distance. 
      </div>
        {articlePartage && articlePartage.map((aPartage: IpartageProps, index: number)=>(
           <ArticlePartage {...aPartage} key={index}/>
        ))}
       
       </div>

    </>
  )
}
