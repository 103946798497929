import React from 'react';
import horseGroup from '../images/home/horseGroup.jpeg'
import ButtonShine from './ButtonShine';
import { useNavigate } from 'react-router-dom';

export default function HomeSharing({...props}) {
  const navigate = useNavigate();
const options = {state: {location:"home"}};

  const goToShare = () =>{
    navigate('/partage', options);
  }
  return (
    
   <div className='home-share-container'>   
    <div className='home-share-wrapper'>
      <img src={horseGroup} className='home-share-img' alt= "Ingrid et ses chevaux"></img>
      <div className='home-share-text-wrapper'>
        <h1 className='home-share-title'>Le partage</h1>
        <div className='home-teaching-text'>
          <p className='home-teaching-text-first'>
          Ingrid vous propose de partager ses connaissances en ligne.
          </p>
          <div className='home-teaching-text-second'>          
          Au programme : Communication animale, travail à pied et monté. 
          Découvrez les bienfaits du coaching vidéo, en présentiel ou à distance, en visioconférence !
          <ButtonShine 
          position={false}
          label="Plus d'infos" 
          onClick={goToShare}/>
          </div> 

        </div>
        
      </div>
    </div>
    
    <div className='home-teaching-title-wrapper'></div>
   </div>
  )
}
