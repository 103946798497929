import React, { useContext, useEffect, useRef, useState } from 'react'
import { TheContext } from '../contexts/EquilysContext';
import TeachingVideo from './TeachingVideo';

export default function Hero({...props}) {
  const myContext = useContext(TheContext);
  const timerRef = useRef<any>(null);
 

  useEffect(() => {   
    
    if(myContext.animState === "update"){    
      timerRef.current = setTimeout(()=>{
      myContext.setAnimState("done");
    }, 100)
    
  }else{
      timerRef.current = setTimeout(()=>{
      myContext.setAnimState("done");
    }, 200)
  }
 
    return () => {
      myContext.setAnimState("update");
      clearTimeout(timerRef.current);
    }
  }, [])
  
  

  const heroImage = props.isSmallDevice ? props.telHero : props.hero;
  return (
    <div id="heroWrapper" className={(props.clickedNavId !== -2) ?'hero-wrapper':'start-hero-wrapper'}>
        <div className={props.className ? props.className : 'hero-text'}>
        {/* 1 : Enseignement */}
        <div className={(props.clickedNavId !== 1) ? 'hero-text-wrapper' : 'teaching-hero-text-wrapper'}>
          {props.children}
          <h1 className={props.heroTitle ? props.heroTitle : 'hero-title'}>{props.title1} &nbsp;</h1>
          {<h2 className={props.heroTitle ? props.heroTitle : 'hero-title'}>{props.title2} &nbsp;</h2>}
        </div>
        </div>
        
        {props.clickedNavId !== 1?
        <img onLoad={props.onLoadedData} id="hero-photo" src={heroImage} className={props.classNameImage} alt="Ingrid Equilys hero"></img>
        :<TeachingVideo {...props} loadingImage={props.loadingImage} url={props.url} id="hero-photo" />}
    </div>
  )
}
