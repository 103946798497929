import styled, {keyframes} from "styled-components";

interface NavLetterI{
    frequence: string | undefined,
    launchAnim: boolean
}

interface SlidingDivElt{
  divWidth: number;
  activId: number;
  id: number;
}
interface IcarouselContainer{
  length: number;
  aDivWidth: number;
  translateX: number;
  activNb: number;
  id: number;
}
interface IyearProp{
  zIndex: number,
  showIt: boolean
}
interface IpassedProp{
  height: number
}
interface IStyledNav{
  position: number;
  heroIsVisible: boolean;
  subMenuIsOpen?: boolean;
}
interface IanimFrom{
  position: number;
 
}
interface IStyledTabs{
  position: number;
  nbTabs: number;
  isIntersecting: boolean;
}

interface IlargeContainer{
  nb: number,
  itemWidth: number,
  itemPadding: number,
  windowWidth: number
} 

export const VisibleContainer = styled.div`
  text-align: justify;
  width: ${(props:IlargeContainer)=>(props.windowWidth-props.itemPadding)}px;
  max-width: ${(props:IlargeContainer)=>(props.windowWidth)}px;
  height: fit-content;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  border-top: 1px solid #eabd3c;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  margin: 0 auto;
  min-height: fit-content;
  perspective: 2000px;
  transform-origin: top;
  margin-top: 5em;
`
export const LargeContainer = styled.div`
  text-align: justify;
  width: ${(props:IlargeContainer)=>(props.itemWidth*props.nb*2)+(props.nb*props.itemPadding*2)}px;
  height: 500px;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
  min-height: fit-content;
  justify-content: center;
  align-items: center;
`
export const StarAnim = styled.img<IanimFrom> `
  position: fixed;
  top: ${(props: IanimFrom)=>props.position}px;
  width: 30px;
  height: 30px;
  transition: top 1.5s linear;
`
export const SlidingDiv = styled.div<SlidingDivElt>`
  width: var(--carouselImgWidth);
  height: inherit;
  opacity: ${(props: IcarouselContainer)=>props.activNb === props.id ? "1": "0"};
  transition: opacity 1s ease-in;
`
export const CarouselMask = styled.div<IpassedProp>`
  z-index:6;
   -webkit-mask-image: url("../images/partage/mask.png");
  mask-image: url("../images/partage/mask.png"); 
  -webkit-mask-position: center;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
  mask-origin: content-box;   
  height: ${(props: IpassedProp)=>props.height}px;
  width: 100%;
  position: relative;
`
export const AppearingDiv = styled.div<SlidingDivElt>`
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  opacity: ${(props: IcarouselContainer)=>props.activNb === props.id ? "1": "0"};
  transition: opacity 1s ease-in;
`
export const StyledNavbar = styled.div<IStyledNav> `
  position: fixed;
  top: ${(props: IStyledNav)=>props.position >= 0 ? props.position : 0 }px;
  left: 0;
  display: inline-flex;
  width: 100vw;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;  
  z-index: 1000;  
  border-bottom: 2px solid var(--appDarkGold);
  background-color: var(--appWhiteTransColor);  
 
 
`

export const SlidingContainer = styled.div<IcarouselContainer>`
  position: relative;
  width: ${(props: IcarouselContainer)=>props.length*props.aDivWidth}px;
  /* height: 400px; */
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;  
  left: ${(props: IcarouselContainer)=>props.translateX}px;
  transition: ${(props: IcarouselContainer)=>props.activNb !== 0 ? "left 1s linear": "none"};
 /*  transform: translateX(${(props: IcarouselContainer)=>props.translateX}px); */
 
`
/**
 * Sharing carousel
 */
export const AppearingContainer = styled.div<IcarouselContainer>`
  position: relative;
  width: ${(props: IcarouselContainer)=>props.length*props.aDivWidth}px;
  /* height: 400px; */
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;  
  left: 0px;
  transition: opacity 1s linear;
 
 
`
export const SideNavWrapper = styled.div<IStyledNav>`
  position: fixed;
  right: ${(props: IStyledNav)=>props.heroIsVisible ? -50 : 0}px;
  height: fit-content;
  width: fit-content;
  z-index: 10000;
  top: ${(props: IStyledNav)=>props.position/4}px;
  border-radius: 10px 0px 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  opacity : ${(props: IStyledNav)=>props.heroIsVisible ? 0 : 1};
  transition: right 0.5s linear, opacity 0.2s linear;
  &:hover {   
    cursor: pointer;
  
    -webkit-filter: brightness(1.25);
          filter: brightness(1.25);
  }
  background: var(--appMiddleBlue);
`
export const SideTabsWrapper = styled.div<IStyledTabs>`
  position: fixed;
  right: ${(props: IStyledTabs)=>props.isIntersecting?-20:-100}px;
  height: fit-content;
  width: 80px;
  z-index: 10000;
  opactity: ${(props: IStyledTabs)=>props.isIntersecting?0:1};
  top: ${(props: IStyledTabs)=>props.position}px;
  border-radius: 10px 0px 0px 10px;
  transition: right 0.5s linear, opacity 0.8s linear;
`
export const YearWrapper = styled.button<IyearProp>`
  position: relative;
  font-family: var(--appFfMain);
  font-size: 1.5em;
  color: var(--appGreenColor);
  transform: rotate(90deg) translateY(${(props:IyearProp)=>props.showIt?15:0}px);
  transition: transform 0.5s linear;
  height: 100%;
  width: inherit;
  margin: 0 auto;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 0px 0px 10px 20px;
  z-index: ${(props: IyearProp)=>props.zIndex?props.zIndex:1};
  background: var(--appWhiteColor);
  margin-top: 1em;
  margin-bottom: 1em;
&:hover{
  cursor: pointer;  
  -webkit-filter: brightness(1.25);
        filter: brightness(1.25);
}
`
export const PassedArticles =  styled.div<IpassedProp>`
  margin-top: 3em;
  height: ${(props: IpassedProp)=>props.height+100}px;
  position: relative;
  display: block;
 
`
export const ArticlesWrapper = styled.div<IyearProp>`
  margin: 0 auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  column-gap: 5%;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;
  align-content: center;
  grid-template-rows: auto;
  row-gap: 5%;
  margin-bottom: 3em;
  height: fit-content;
  transform: translateX(${(props:IyearProp)=>props.showIt?0:2000}px);
  transition: transform 0.5s ease-out;
  z-index: ${(props: IyearProp)=>props.zIndex?props.zIndex:1};
@media(max-width: 810px){
  display: block;
}
`
export const ArticleWitnessWrapper=styled.div`

  flex: 0 0 ${(props: IlargeContainer)=>props.itemWidth}px;
  width: ${(props: IlargeContainer)=>props.itemWidth}px;
  margin-left: ${(props: IlargeContainer)=>props.itemPadding}px;
  margin-right: ${(props: IlargeContainer)=>props.itemPadding}px;
  padding: ${(props: IlargeContainer)=>props.itemPadding/2}px;
  background-color: var(--appDarkBlue);
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 5px;
 /*  color: #fff; */
  font-size: 0.8em;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  scroll-snap-align: center;
  flex-direction: column;
  min-height: fit-content;
  line-height: 1.5em;
  height: 330px;

`
export const rotating = keyframes `
0% {
    opacity: 1;
    transform: rotateX(0deg);
  }
  50%{
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotateX(720deg);
  }
`;
export const slideOut = keyframes `
0% {      
  transform: translateX(0%);
}   
100% {       
    transform: translateX(100%);
}
`
export const birth = keyframes `
    0% {      
      transform: scaleX(0%);
      opacity: 0;
    }   
    100% {       
        transform: scaleX(100%);
        opacity: 1;
    }  
    `

export const slidein = (props: number)=> keyframes`
  0% {   
  transform: translateX(100vw); 
  } 
  100% {
    transform: translateX(${(props*(-50))}vw);
  }
`
export const slideinPhone = (props: number)=> keyframes`
  0% {   
  transform: translateX(100vw); 
  }
  100% { 
    
    transform: translateX(${(props*2*(-100))}vw);
  
  }
`





export const NavLetter = styled.div<NavLetterI>`
  display: inline-flex;
  animation: ${(props:NavLetterI)=>props.launchAnim && rotating} ${(props: NavLetterI)=>props.frequence}s linear;
  -webkit-animation: ${(props:NavLetterI)=>props.launchAnim && rotating} ${(props: NavLetterI)=>props.frequence}s linear;
`;

