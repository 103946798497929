import React, { useEffect, useRef, useState } from 'react'
import Bubble from '../components/Bubble';

import plume from '../images/common/equilys_plume.png';
import loadingImage from '../images/enseignement/loadingImage.jpg';
import url from '../media/presentation.mp4';
import bubbleImg from '../images/enseignement/ComAnimale.png';
import { CteachingData, enseignementData } from '../data';
import HeroNav from '../components/HeroNav';
import PageTitle from '../components/PageTitle';



export default function Enseignement({...props}) {  

  const heroProps = {...props, 
    telHero: loadingImage, 
    hero: loadingImage, 
    title1:"Ingrid Equilys", 
    title2:"Ensemble",
    classNameImage: "hero-photo",    
    clickedNavId: props.clickedNavId,
    loadingImage: loadingImage,
    url: url,
    heroTitle: "hero-com-title",
    className: 'hero-com'    
   
  }
  
  const timerRef = useRef<any>(null); 
  
  
  useEffect(() => {    
    props.setClickedNavId(1);
    window.scrollTo({top: -500, behavior: 'smooth'}); 

    timerRef.current = setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 200);
    
    return () => {
      
      console.log("on quitte enseignement");
      clearTimeout(timerRef.current);
     
    }
  
  }, [])
  console.log("clickedNavIdEnseignement : ", props.clickedNavId);
  return (
    <>
    <HeroNav {...heroProps}/>
    
    <div className={props.className}>
      <PageTitle title="Enseignement"/>
      <div className='enseignement-container'>  
      <Bubble wrapperClassName="bubble-wrapper">
      <img src={bubbleImg} className="bubble-img" alt="bubble image"/>
      </Bubble>
      <div className='enseignement-intro'>
      Respect, harmonie, échange sont les maîtres mots de l'enseignement proposé par Ingrid.
      Elle aide à progresser, trouver une réponse aux difficultés par la communication et l'écoute.
      Le tout à votre rythme, en respectant celui de votre cheval. 
      Plusieurs sphères d'apprentissage interviennent de façon complémentaire pour mener à cette réussite 
      </div>
      <img src={plume} alt="equilys plume" className='equilys-plume'/>
      
      <h2 className='h2-green-teaching'>Les sphères d'apprentissage</h2>
      <div className='enseignement-article-container'>
      {/* Before Video */}
      {enseignementData && enseignementData.map((aData: CteachingData)=>(
        aData.id < 3 && <div key= {aData.id} className='enseignement-article-wrapper'>
        <Bubble wrapperClassName="bubble-wrapper-small">
        {aData.icon ? aData.icon : (aData.svg ? aData.svg: null)}
        </Bubble>
        <div className='enseignement-article'>
        <div className='enseignement-article-title'>
        <h2 className='enseignement-title'>{aData.title}</h2>
        </div>
        {aData.details.map((aDetail: string, index: number)=>(
          <div key={index} className='enseignement-text'>
          {aDetail}
          </div>
          ))}
          </div>
          </div> 
          ))}</div>
         
         
          <div className='enseignement-article-container'>
          {enseignementData && enseignementData.map((aData: CteachingData)=>(
        aData.id >= 3 && <div key= {aData.id} className='enseignement-article-wrapper-latest'>
        <Bubble wrapperClassName="bubble-wrapper-small">
        {aData.icon ? aData.icon : (aData.svg ? aData.svg: null)}
        </Bubble>
        <div className='enseignement-article'>
        <div className='enseignement-article-title'>
        <h2 className='enseignement-title'>{aData.title}</h2>
        </div>
        {aData.details.map((aDetail: string, index: number)=>(
          <div key={index} className='enseignement-text'>
          {aDetail}
          </div>
          ))}
          </div>
          </div> 
          ))}
          </div>
      </div>
    </div>
    </>
  )
}
