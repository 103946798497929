import {Route, Routes} from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import Prestations from './Prestations';
import Enseignement from './Enseignement';
import Tarifs from './Tarifs';
import { useContext, useEffect, useState } from 'react';
import Temoignages from './Temoignages';
import { TheContext } from '../contexts/EquilysContext';
import Booking from './Booking';
import Sign from './Sign';
import MentionsFooter from '../components/MentionsFooter';
import Mentions from './Mentions';
import Actualities from './Actualities';
import ComAnimale from './ComAnimale';
import Partage from './Partage';







export default function Site ({...props}){ 
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false);
    const [clickedNavId, setClickedNavId] = useState<number>(-2);
    const windowWidth = window.innerWidth;
    const myContext = useContext(TheContext);
    /**On click only for "enseignement" others useeffect mount */
    const handleNavClick = ( id: number)=>{
        console.log("navclicked");
       if (id === 1){
        setClickedNavId(id);          
    }
}
    /*MOUNT*/
 useEffect(() => {
    if(windowWidth <= 850){
        setIsSmallDevice(true);
    }else{
        setIsSmallDevice(false);
    }
 
   return () => {
     
   }
 }, [])
 
 
    const siteProps = {
        ...props, 
        onClick: handleNavClick, 
        clickedNavId: clickedNavId, 
        isSmallDevice: isSmallDevice,
        setClickedNavId: setClickedNavId
    };
    
    console.log ("isDrawing : ", myContext.isDrawing);
    return (        
        <div className={myContext.isDrawing ? "App-static" : "App"}>
            <div className='site'>
            </div>
        <Routes>
        <Route path='/' element={<Home className="homewrapper" {...siteProps} />} />
        <Route path='/actualites' element={<Actualities className="compowrapper" {...siteProps} />} />
        <Route path='/prestations' element={<Prestations className="compowrapper" {...siteProps} />} />
        <Route path='/enseignement' element={<Enseignement  className="EnseignementWrapper" {...siteProps} />} />
        <Route path='/tarifs' element={<Tarifs  className="pricewrapper" {...siteProps} />} />
        <Route path='/contact' element={<Contact  className="compowrapper" {...siteProps} />} />
        
        <Route path='/temoignages' element={<Temoignages  className="compowrapper" {...siteProps} />} />
        <Route path='/reservation' element={<Booking  className="compowrapper" {...siteProps} />} />
        <Route path ='/login' element={<Sign className="compowrapper" {...siteProps}/>} />
        <Route path ='/mentionslegales' element={<Mentions className="compowrapper" {...siteProps}/>} />
        <Route path='/comanimale' element={<ComAnimale className="comwrapper" {...siteProps}/>}/>
        <Route path='/partage' element={<Partage className="comwrapper" {...siteProps}/>}/>
        </Routes>
        <MentionsFooter {...siteProps}/>
        </div>
        
        )
        
    }
