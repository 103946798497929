import React, { useState } from 'react';
import UploadSign from './UploadSignature';
import logo from '../images/common/equilys.png';
import { Ibooking, Icontact, Irider } from '../data';
import { GoLaw } from 'react-icons/go';


interface Props {
    isToPrint: boolean,
    rider: Icontact,
    paymentInfos: string[]
   

}



export default function PdfFicheContactToPrint(props: Props) {
  
    const{rider, paymentInfos} = props;
    
    return (
        <div className='pdf-print-wrapper'>       
           
        <div className='pdf-container'>
        <div className='pdf-wrapper'>      
        <div className='contact-civil-wrapper'>                        
        <img src={logo} alt="Ingrid Equilys logo" className='printpdf-logo-equilys'></img>
        <div className='civil-state'>
        
        <div className='contact-pdfform-wrapper'>
        <h1 className='pdf-printform-title'>Fiche Contact</h1>
        <span className='print-autorisation'>Autorisation de soins y compris pour les mineurs</span>
        <div className='input-title-print'>        
        Personne à prévenir en cas d’accident
        </div>        
        <div className='contact-pdfprint-details'>        
        <div className='input-pdffrom-group'>
            <span className='input-span'>Nom et prénom</span>
            <span  className="print-contact-info">{rider.contactName}</span>   
        </div>
        <div className='input-pdffrom-group'>
            <span className='input-span'>Téléphone</span>
            <span  className="print-contact-info">{rider.phone1}</span>
              
        </div>
        <div className='input-pdffrom-group'>
            <span className='input-span'>et / ou</span>
            <span  className="print-contact-info">{rider.phone2}</span>
        </div>
        </div>
        </div>
       
        </div>
        </div>
        <div className='contact-pdfprint-textblock'>
        Je, soussigné(e)&nbsp;
        <span className="print-contact-info">&nbsp;{rider.firstname}&nbsp;</span>
         
        <span className="print-contact-info">
            {rider.lastname}&nbsp;
        </span>
        représentant légal de l’enfant        
        <span className="print-contact-info">&nbsp;{rider.childName}&nbsp;</span>
        autorise l’établissement et les professionnels présents, à prendre toutes les mesures
        indispensables que nécessiterait l’état de santé de mon enfant et suivant l’urgence et la
        gravité de son état à faire transporter mon enfant à l’hôpital par le SAMU ou les pompiers.
        </div>

        <div className='contact-pdfform-wrapper'>
        <div className='input-title-print'>
            Allergies            
            <input type='radio'             
            name='allergy' id='yes'
            defaultChecked={rider.is_allergic}            
            >
            </input>
            <span >Oui</span>            
            <input type='radio'             
            name='allergy' id='no'
            defaultChecked={!rider.is_allergic}            
            >            
            </input>
            <span>Non</span>
        </div>          
            Si oui, lesquelles : 
        {rider.allergies.split("\n").map((aString:string, index:number)=>(
           <span className="print-contact-info" key={index}> {aString} {rider.allergies.split("\n").length>1 && "|" }</span>
        ))}
                

        </div>
        <div className='contact-pdfform-wrapper'>
        <div className='input-title-print'>
            Médecin traitant
        </div>        
        <div className='contact-pdfform-details'>        
        <div className='input-pdffrom-group'>
            <span className='input-span'>Nom et prénom du médecin</span>
            <span className="print-contact-info">
            {rider.doctor}
            </span> 
        </div>
        <div className='input-pdffrom-group'>
            <span className='input-span'>Téléphone</span>
            <span className="print-contact-info">{rider.doctorPhone}</span>      
        </div>
        <div className='input-pdffrom-group'>
            <span className='input-span'>Groupe sanguin</span>
            <span className="print-contact-info">
                {rider.blodGroup}           
            </span>
        </div>
     
        </div>
        <div className='input-pdffrom-group'>
            <div className='input-title-print'>
                Droit à l'image    
                <input type='radio'                 
                name='image' id='yes-image'
                defaultChecked={rider.acceptImage}            
                >
                </input>
                <span >Oui</span>
                
                <input type='radio'                
                name='image' id='no-image'
                defaultChecked={!rider.acceptImage}            
                >            
                </input>
                <span>Non</span>        
            </div>
            <div className='printable-radio-group'>          
            J’autorise les professionnels, à utiliser mon image ou celle de mon enfant lors d’activités équestres,
            à des fins d’information ou de promotion, y compris sur le site internet
             
            </div>   
        </div>
        <div className='input-pdffrom-group'>
            <div className='input-title-print'>
               Règlement
            </div>
            <div className='contact-pdfprint-textblock'>
                Le règlement des prestations peut se faire par chèque, espèce ou virement, aux coordonnées bancaires suivantes :
                
                {paymentInfos&& paymentInfos.map((anInfo: string, index: number)=>(
                    <span className="print-contact-info" key={index}>{anInfo}&nbsp;</span>
                ))}
            </div>
            <div className='contact-pdfprint-textblock'>
            Les prestations ne sont pas remboursables en cas de désistement. En revanche, elles sont reportables si elles sont
            annulées au moins <span className='important'>TROIS JOURS à l'avance</span>, en fonction des disponibilités de l'enseignante.
            <span className='important'> Le SOLDE DÛ est versé au plus tard en DÉBUT de séance pour les forfaits et les cours à l'unité</span></div>
        </div>
       
        <div className='input-pdffrom-group'>
            <div className='input-title-print'>                
               <div className='printable-radio-group'>          
               Conditions de vente | Lues et approuvées
                <input type='radio'                 
                name='cgvOk' id='yes-cgv'
                defaultChecked={rider.cgvOk}            
                >
                </input>
                <span >Oui</span>                
                <input type='radio'                 
                name='cgvOk' id='no-cgv'
                defaultChecked={!rider.cgvOk}
                >            
                </input>
                <span >Non</span>
                </div>   
            </div>
            <div className='contact-pdfprint-textblock'>
            Vous pouvez consulter les conditions générales de vente de prestations d'<span className='signature-mentions'>Ingrid Equilys </span>en cliquant sur l'onglet 
                <div className='cgv-pad'>                    
                    Conditions générales 
                    <GoLaw className='printform-simple-icon'/>
                </div>
            </div>
  
        </div>
        <div className='contact-pdfprint-textblock'>       
            Je soussigné(e)&nbsp;
            <span className="print-contact-info">         
            {rider.signatureName}&nbsp;         
            </span>
             (parent ou représentant légal majeur pour les mineurs)
            déclare avoir pris connaissance de ce formulaire contact ainsi que du document « conditions de ventes de prestations » et m’engage à les respecter
        </div>
        </div>
        </div>
        </div>
        </div>

)}


