import React from 'react'
import { CgvParagraph, EquilysMail, Label, SiteName, TitleLabel } from '../components/SiteInfos';
import { GiMultipleTargets } from 'react-icons/gi';
import { MdOutlineContactPage } from 'react-icons/md';
import ficheRetra from '../pdf/equilysRetractation.pdf';
import TargetFile from '../components/TargetFile';

interface Props {
    label: string;
    tel: string;
    mail: string;
    site: string;
    paymentInfos: string[];
}

function Cgv(props: Props) {
    const {label, tel, mail, site} = props;

    return (
        <div className='cgv-article-container'> 
        <h1>Conditions générales de vente de prestations <TitleLabel {...props} /></h1>
        {/* ARTICLE 1 */}
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>article 1 -  préambule / définitions</h2>
        <div className='cgv-textblock-wrapper'>
        Les présentes Conditions Générales d’Utilisation (CGU) régissent l’accès au Site et au Service qui y est proposé aux Utilisateurs.&nbsp;
        <Label {...props} /> désigne l’éditeur du Site, du Service et des Options.
        <CgvParagraph>Le Site désigne le site internet accessible à l’adresse <SiteName {...props}/></CgvParagraph>
        Le Client s’entend de toute personne utilisant le Site et ayant souscrit une prestation.
        <CgvParagraph>Un Compte désigne l’espace personnel créé pour l’Utilisateur lui permettant d’accéder au Service et aux Options.</CgvParagraph>
        L'Enseignante, Prestataire est désignée par Madame GAZEL Ingrid.
        
        </div>
        </div>
       {/*  ARTICLE 2 */}
        <div className='cgv-article-wrapper'>
            <h2 className='cgv-article-title'>article 2 -  objet</h2>
            <div className='cgv-textblock-wrapper'>
            Les présentes conditions Générales de Ventes ( dites “CGV”) s’appliquent sans restriction ni
            réserve aux services suivants :
            <CgvParagraph>
            Coaching présentiel et en ligne du domaine équestre,
            </CgvParagraph>
            <CgvParagraph>
            Séances de communication animale,
            </CgvParagraph>
            <CgvParagraph>
            Enseignement en ligne et en présentiel à la communication animale,
            </CgvParagraph>
            <CgvParagraph>
            Tels que proposés par le Prestataire aux Clients non professionnels sur le site&nbsp;
            <SiteName {...props}/>
            </CgvParagraph> 
            </div>
        </div>  
        {/*  ARTICLE 3 */}
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>article 3 -  prix</h2>
            <CgvParagraph>
            Les services sont fournis aux tarifs en vigueur figurant sur le site lors de l’enregistrement de la commande par le Prestataire. Les prix sont exprimés en Euros hors taxe, taxe non applicable.
            </CgvParagraph>
            Toutes les commandes quelle que soit leur origine géographique sont payables en euros.
            Les tarifs tiennent compte d’éventuelles réductions qui seraient consenties par le Prestataire
            sur le site&nbsp; <SiteName {...props}/>.  
            <CgvParagraph>
            Si la conjoncture économique l'exige, le Prestataire est en droit de réviser le montant de la prestation. Le présent contrat prévoit un délai de 15 jours au delà duquel la modification est effective. 
            </CgvParagraph>  
            <CgvParagraph>
            La facturation se fait sur la base du tarif en vigueur au moment de la validation de la commande.
            </CgvParagraph>   
            <CgvParagraph>
        Une facture peut être établie par le Prestataire et remise au Client à sa demande dès l’inscription ou la commande.
        </CgvParagraph>
        <CgvParagraph>
        Le paiement peut être réalisé :
        </CgvParagraph>
        <CgvParagraph>
        - par chèque,
        </CgvParagraph>
        <CgvParagraph>
        - en espèces uniquement pour les Services réalisés en présentiel,
        </CgvParagraph>
        <CgvParagraph>
        - par virement sur le compte bancaire du Prestataire,
        </CgvParagraph>
        <CgvParagraph>
        La grille tarifaire est disponible à la demande selon les prestations de formations désirées et est accessible sur le site <SiteName {...props}/>. En fonction du nombre de paiements, des frais supplémentaires de gestion peuvent être inclus  dans les mensualités au-delà du paiement en deux fois.
        </CgvParagraph>
        <h2>Coordonnées bancaires</h2>
        {props.paymentInfos&& props.paymentInfos.map((anInfo: string, index: number)=>(
                    <div key={index}>{anInfo}</div>
        ))}       
        </div> {/*  ARTICLE 4 */}
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>article 4 -  commande</h2>
            
            <h2>Pour l'ensemble des services</h2>
            
            <CgvParagraph>
            Le Prestataire se réserve le droit de ne pas enregistrer un paiement, de le rembourser et de ne pas confirmer une commande pour quelque raison que ce soit, et particulièrement si la demande ne correspond pas à l’esprit attendu ou en cas de dépassement du quota d’inscriptions.
            </CgvParagraph>
            <CgvParagraph>
            Pour toute commande, le Client peut contacter le Prestataire par le biais du formulaire de contact présent sur le site <SiteName {...props} />, du compte Facebook <Label {...props} /> ou de Whatsapp©. 
            Toute commande passée constitue la formation d’un contrat conclu à distance entre le Client
            et le Prestataire.
            De ce fait la vente ne sera considérée comme valide qu’après règlement intégral du prix ou réception des chèques si paiement en plusieurs fois.
            </CgvParagraph> 
            Le Client a procédé au paiement. Il accepte : 
            <CgvParagraph>
            Le contenu et les conditions de la commande du service souscrit et en particulier les présentes conditions générales de vente. 
            </CgvParagraph>
            <CgvParagraph>
            Il appartient au Client de vérifier l'exactitude de la commande ainsi que des renseignements qu'il fournit pour le service et de signaler immédiatement toute erreur.
            </CgvParagraph>
            <CgvParagraph>
            Il appartient au Client  de choisir le ou les services qu'il désire commander selon les modalités suivantes : 
            </CgvParagraph>
            <h2> Coaching présentiel et en ligne du domaine équestre</h2>
            <h3>Coachings en ligne</h3>
            
            <h3>Enseignement en LIVE</h3> 
            <CgvParagraph>
            Le Client complète et envoie par mail à <EquilysMail/> la <TargetFile/> téléchargeable sur le site <SiteName {...props} />, afin que l'Enseignante prenne connaissance de ses objectifs et de ses disponibilités.
            </CgvParagraph>
            <CgvParagraph>
            Une fois cette fiche reçue, l'Enseignante prend contact avec le Client afin :
            </CgvParagraph>
            <CgvParagraph>De trouver des disponibilités communes,</CgvParagraph>
             <CgvParagraph>De préparer la séance (matériel, dispositifs à mettre en place), </CgvParagraph>
             <CgvParagraph>De procéder au règlement</CgvParagraph>
            <CgvParagraph>La connexion peut se mettre en place via la plateforme Zoom©</CgvParagraph>
    <CgvParagraph>
    Le coaching "enseignement en LIVE", commence dix minutes avant le cours (connexion), pour une durée d'une heure, soit soixante minutes de cours.
    </CgvParagraph>
    <CgvParagraph>
    Les prestations ne sont pas remboursables en cas de désistement. Elles sont en revanche reportables si elles sont annulées au moins 72h à l'avance, et selon les disponibilités de l'Enseignante.
    </CgvParagraph>
    
    <h3>Enseignement avec correction de vidéos et exercices</h3>
    <CgvParagraph>
    Le coaching "enseignement et correction de vidéos" commence le 1er du mois suivant la réception de l’ordre de virement ou de l’ensemble des chèques en cas de règlement en plusieurs fois.
	</CgvParagraph>
    <CgvParagraph>
    Le Client complète et envoie par mail à <EquilysMail/> la <span className="cgv-pad">Fiche Objectifs <GiMultipleTargets className='form-simple-icon'/></span> téléchargeable sur le site <SiteName {...props} />, afin que l'Enseignante prenne connaissance de ses objectifs et de ses disponibilités.
            </CgvParagraph>
            <CgvParagraph>
            Une fois cette fiche reçue, l'Enseignante prend contact avec le Client afin :
            </CgvParagraph>
            <CgvParagraph>De trouver des disponibilités communes,</CgvParagraph>
             <CgvParagraph>De préparer la séance (matériel, dispositifs à mettre en place), 

             </CgvParagraph>
             <CgvParagraph>De procéder au règlement.</CgvParagraph>
            <CgvParagraph>
            L'Enseignante fait parvenir au Client une fiche pédagogique expliquant le déroulé de la prochaine séance de travail.
            </CgvParagraph>
            Le Client envoie une première vidéo (Whats app©, weTransfer© ou lien personnalisé) permettant à l'Enseignante de visionner les premiers exercices et d'en faire un retour commenté.
            <CgvParagraph>
            Ainsi, des exercices personnalisés sont ensuite proposés au Client afin d'améliorer les problématiques et difficultés rencontrées, ou de conforter des acquis.
            </CgvParagraph>
            <h3>Coaching en présentiel</h3>  
            <h3>Organisation et horaires</h3>           
            <CgvParagraph>
            Les cours sont assurés les jours de pluie, mais peuvent être reportables avec accord de l'Enseignante (terrain dangereux, empêchant la pratique à pied). 
            Les reprises ont lieu selon le planning mensuel qui est transmis par messages. Celui-ci est susceptible d'évoluer.
            </CgvParagraph> 
            <CgvParagraph>
            Ainsi il est effectué suivant les disponibilités du Client et la zone de déplacement de l'Enseignante.
            </CgvParagraph>
             
            <CgvParagraph>
            Les cavaliers peuvent s’inscrire un mois à l'avance afin de pouvoir réserver leurs créneaux, mais peuvent joindre l'Enseignante pour toute autre réservation.
            </CgvParagraph>
            <CgvParagraph>
            Le Client complète et envoie par mail à <EquilysMail/> la <span className="cgv-pad">Fiche Objectifs <GiMultipleTargets className='form-simple-icon'/></span> et 
            la <span className="cgv-pad">Fiche Contact </span><MdOutlineContactPage className='form-simple-icon'/>
             téléchargeables sur le site <SiteName {...props} />, pour que l'Enseignante prenne connaissance de ses objectifs et de ses disponibilités afin de mettre en place l'accompagnement.
            </CgvParagraph>
            <CgvParagraph>
            Le Prestataire lui transmet alors ses coordonnées bancaires
            </CgvParagraph>
            <h3>Règlements pour les forfaits</h3>
            <CgvParagraph>
            Les prestations ne sont pas remboursables en cas de désistement, mais reportables si elles sont annulées au moins 72h à l'avance, selon les disponibilités de l'Enseignante.
            </CgvParagraph>
            <CgvParagraph>
            L'Enseignante est présente environ 15-30 minutes avant ou après la séance.
            L’heure de cours commence à l'heure prévue.
            Il est tout à fait possible de modifier la durée du cours en fonction des souhaits. (30 minutes , 1 heure , 1 heure 30...)
            </CgvParagraph>
            <h2 >Stages et animations</h2>
            <CgvParagraph>
            Les inscriptions et/ou animations doivent être réglées à 50 % en guise d'arrhes.
            Ainsi en cas d'annulation de la part du Client, celles-ci ne sont pas remboursables.
            Si l'annulation intervient 48h à l'avance ou le jour J, le stage et/ou l'animation reste dû en totalité.
            Pour qu'une annulation ouvre droit à un rattrapage, et sauf en cas de force majeur, le Client doit envoyer par email ou message un certificat médical original. 
            Attention celui-ci est à effectuer au maximum le jour-même du stage.
            </CgvParagraph>       
        <CgvParagraph>
        Le Prestataire se réserve le droit d'annuler un stage ou une animation si le nombre de cavaliers présents n 'est pas supérieur à 4 (quatre).
        Le Client peut régler le solde de son inscription SUR PLACE LE JOUR  J  par chèque ou espèces.
        </CgvParagraph>
        <h3>Assurances</h3>
        Pour pratiquer l’équitation, la Licence Fédérale est conseillée.
        Lors de l’inscription, l’usager a la possibilité de prendre cette Licence Fédérale (qui lui permet d’être assuré dans tous les centres affiliés de France, de passer des examens fédéraux, ainsi que de participer à des concours officiels).
        Dans le cas contraire, il appartient à l’usager d’apporter la preuve qu’il possède une assurance personnelle multirisque en cours de validité couvrant les activités équestres, dégageant le moniteur de cette responsabilité.
        Si celle-ci n'est pas fournie, il ne peut en aucun cas tenir le Prestataire d'une quelconque responsabilité.  
        Le Prestataire est assuré chez ALLIANZ PRO.
        <h2>
            Prestations de communication animale
        </h2>
        <h3>Séances de communication animale</h3>
        <h3>Réservations</h3>           
           
            <CgvParagraph>1- Le Client complète la <span className="cgv-pad">
                Fiche Objectifs <GiMultipleTargets className='form-simple-icon'/></span> téléchargeable sur le site <SiteName {...props} />, afin de renseigner ses objectifs et de ses disponibilités,
            </CgvParagraph>
            <CgvParagraph>
                2- Il envoie la fiche dûment complétée par mail à <EquilysMail/>
            </CgvParagraph>
            
            
            <CgvParagraph>
           4- Important: le Client doit contacter le Prestataire directement par message au 06 22 94 11 67 ou sur Messenger© <Label {...props}/>, avec les informations indispensables à la mise en place de la communication avec son animal : 
            <p className='cgv-article-details-p'>
            - Une photo de l'animal seul,
            </p>  
            <p className='cgv-article-details-p'> - Son nom,</p>   
            <p className='cgv-article-details-p'>- Son âge,</p>     
            <p className='cgv-article-details-p'>- Les questions et messages à lui communiquer.</p>
            </CgvParagraph>
            
            
            <CgvParagraph>            
            Dès la réception des informations, le Prestataire informe le Client de la date de réalisation de la Communication animale.
            Si le Client n'a pas envoyé les informations demandées dans les 24h, le rendez-vous est annulé.
            </CgvParagraph>
            <CgvParagraph>
                5 - La séance de communication animale est convenue une fois le virement effectué.
            </CgvParagraph>
            <h3>Compte-rendu</h3>
            <CgvParagraph>
            Après règlement de la prestation, la communication animale est effectuée dans un délai d'environ 2 semaines à compter de la date convenue par téléphone ou message.
            Le Prestataire s’engage à faire ses meilleurs efforts pour fournir le service commandé par le Client, dans le cadre obligatoire de moyens et de délais précisés ci-dessus. 
            </CgvParagraph>
            <h3>Réclamation</h3>
            <CgvParagraph>
            Le Client dispose d’un délai de 24 heures à compter de la fourniture du service pour émettre
            des réclamations par courrier écrit en recommandé avec accusé réception, avec tous les
            justificatifs afférents, auprès du Prestataire.
            Aucune réclamation ne peut être valablement acceptée en cas de non respect de ces formalités et délais par le Client.
            Le Prestataire rembourse ou rectifie dans les plus brefs délais les services dont le défaut de conformité est reconnu par les deux parties.
            </CgvParagraph>
            <h2>Stages de Formation à la communication animale</h2>
        <h3>À distance</h3>       
        Pour valider sa commande, le Client envoie un mail avec les références de son virement, une fois celui-ci effectué.
        Le Client a accès à l'espace formation dans les 24 heures suivantes. 
        L‘accès au stage est limité aux modules choisis. La formation propose des vidéos, des audios et des conseils écrits donnant les clés pour communiquer et pour faire des soins énergétiques en toute bienveillance. 
        </div>
        <div className='cgv-article-wrapper-just'>
        <h2 className='cgv-article-title'>article 5 -  Conditions de paiement</h2>
        <CgvParagraph>
        Toute confirmation de commande et signature du présent contrat entraînent votre adhésion pleine et entière sans exception ni réserve. 
        Le prix est payé par voie de paiement sécurisé, selon les modalités suivantes :
        </CgvParagraph>
        <p className='cgv-article-details-p'>- Paiement par virement bancaire sur le compte bancaire du Prestataire (cf article 3), </p>
        <p className='cgv-article-details-p'>- Paiement par un ou plusieurs chèque(s), libellé(s) à l'ordre d'Ingrid Gazel.</p>
        <p className='cgv-article-details-p'>- Paiement en liquide, uniquement pour les services effectués en présentiel.</p>
        Le prix est payable comptant par le Client, en totalité au jour de la passation de la commande sauf, cas exceptionnel
        des formations en ligne, pouvant autoriser le paiement différé.
        Ceci étant stipulé sur la facture dûment signée par le Client, notifiant l'échéancier de paiement.
        <CgvParagraph>L’ensemble des données fournies et la confirmation enregistrée valent preuve de la transaction.</CgvParagraph>
        Une facture est émise immédiatement (pour les paiements différés) ou sur demande, une fois le délai de rétractation ou d’annulation expiré. 
       
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>article 6 -  Rétractation et conditions d'annulation</h2>
        <CgvParagraph>
        Selon les modalités de l’article L221-18 du Code de la consommation <span className="cgv-citation">
        “Le consommateur dispose d’un délai de quatorze jours pour exercer son droit de rétractation d’un contrat
         conclu à distance, à la suite d’un démarchage téléphonique ou hors établissement, sans
         avoir à motiver sa décision ni à supporter d’autres coûts que ceux prévus aux articles L
         221-23 à L 221-25. Le délai mentionné au premier alinéa court à compter du jour :
         De la conclusion du contrat, pour les contrats de prestation de services et ceux
         mentionnés à l’article L221-4.
         Le remboursement des sommes effectivement réglées par le Client sera effectué dans un
         délai de 14 jours à compter de la réception, par le Prestataire, suivant la notification de la
         rétractation du Client”</span>
         <p><a href={ficheRetra} target='_blank'>
         Télécharger le formulaire de rétractation
        </a></p>
        </CgvParagraph>
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 7 - responsabilité du Prestataire | garantie</h2>
      
        <h3>Coaching présentiel et en ligne du domaine équestre | prestations de communication animale
        </h3>
        <CgvParagraph>
            L’inscription à la formation est possible jusqu’à la notification de la fin des inscriptions et dans la limite des places disponibles. L'Enseignante seule décidera de la fin des campagnes d’inscription.
        </CgvParagraph>
        <CgvParagraph>
        La communication animale n’est pas une science exacte : le Prestataire peut "rater" la mise
        en contact télépathique avec l’animal, et cela n’engage pas sa responsabilité personnelle.
        Le Client en est informé et accepte les termes de ces garanties.
        </CgvParagraph>
        
        <CgvParagraph>
        Afin de faire valoir ses droits, le Client informe le Prestataire par écrit de l’existence
        de vices ou de défauts de conformité. Une fois ces défauts reconnus par les deux parties, le Prestataire rembourse ou rectifie les services jugés défectueux dans les meilleurs
        délais. Le remboursement peut intervenir par virement bancaire.
        </CgvParagraph>
        <CgvParagraph>
        La garantie du Prestataire est limitée au remboursement des services payés par le Client.
        Le Prestataire ne peut être considéré comme responsable ni défaillant pour tout retard ou
        inexécution consécutif à la survenance d’un cas de force majeure reconnu par la jurisprudence française.
        </CgvParagraph>
        <CgvParagraph>
        Les services fournis par <Label {...props}/>                   
         sont conformes à la réglementation française. La responsabilité du
         Prestataire ne saurait être engagée en cas de non-respect de la législation d'un autre pays que la France ; il appartient au Client, qui est seul responsable du choix des services proposés, de vérifier leur adéquation avec la législation en vigueur dans son pays.
        </CgvParagraph>        
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 8- Responsabilités et conditions</h2>
        <h2>Pour les prestations de communication animale
        </h2>
        <CgvParagraph>
        Les séances dites de scan thérapeutique ou liées à la connexion animale, méditation ou à toute autre technique de développement personnel pouvant faciliter l’apprentissage de la formation correspondent à une obligation de moyens et non de résultats.
        L’interprétation et l’usage que le Client pourrait en faire n’appartiennent qu’à lui et ne peuvent être faites que sous sa seule responsabilité.
        </CgvParagraph>
        <CgvParagraph>
        Les formations présentes sur le site, ne sont en aucun cas utilisées dans un cadre médical, mais strictement de bien-être afin de faciliter l’apprentissage. 
        Les mineurs doivent bénéficier d’une autorisation parentale pour participer aux formations proposées sur le site.
        </CgvParagraph>
        <CgvParagraph>
        Si, contrairement au but recherché, le Client ressent un mal-être ou des difficultés en pratiquant les conseils, exercices mentaux ou physiques proposés par le Prestataire, celui-ci lui conseille de stopper immédiatement, voire consulter un médecin ou appeler les services de secours, notamment si les douleurs ressenties montrent un risque de santé. 
        </CgvParagraph>
        <CgvParagraph>
        Le Prestataire ne peut être tenu responsable de toute mesure ou action prise suite à la formation ou aux conseils prodigués.
        En particulier, dans la mesure où cela est permis par la loi, le Prestataire ne donne aucune garantie quant à l’exactitude, l’exhaustivité ou la pertinence (pour quoi que ce soit) d’un avis et d’une information publiés dans le cadre de ses services.
        </CgvParagraph>
        <CgvParagraph>
        En tout état de cause, la connexion animale peut éventuellement amener un éclaircissement sur l'état de santé d’un animal mais ne peut jamais remplacer une consultation vétérinaire quand celle-ci est nécessaire.
        </CgvParagraph>
        <h2>Pour l'ensemble des services</h2>
        <CgvParagraph>
        Le Prestataire ne saurait être tenu responsable des dommages résultant d’une mauvaise  compréhension du programme.
        Celui-ci ne contient aucun élément pouvant pousser à porter atteinte à l’intégrité physique ou mentale de soi-même ou d’autrui.
        </CgvParagraph>
        <CgvParagraph>
        Enfin la responsabilité du Prestataire ne saurait être engagée pour tous les inconvénients ou  dommages inhérents à l’utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou la présence de virus informatiques.
        </CgvParagraph>
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 9 - Droit à l'image</h2>
        <CgvParagraph>En cochant "Oui" à la section droit à l'image de la <span className="cgv-pad">Fiche Contact </span><MdOutlineContactPage className='form-simple-icon'/>,
        le Client accepte la prise de photographies et de vidéos de lui-même ou de son enfant,   
        dans l’exercice de toute activité à laquelle il participe avec l'Enseignante Ingrid Gazel.
	    Celles-ci peuvent être utilisées par l'Enseignante pour alimenter son site internet et ses pages de réseaux sociaux (Facebook© / Youtube© ) ou pour une écurie partenaire. 
        Ces publications ont uniquement pour objet de promouvoir les activités et de communiquer. 
        Mme Ingrid GAZEL ne peut être tenue responsable des images et/ou vidéos réalisées par des personnes tierces et utilisées par elles-mêmes à l'insu du Client et du Prestataire, notamment sur leurs réseaux sociaux.
        </CgvParagraph>
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 10 - Tenue et sécurité</h2>
        <CgvParagraph>
        Pour monter à cheval, les cavaliers doivent avoir une tenue vestimentaire correcte et adptée, conforme aux usages traditionnels de l’équitation Française.
        </CgvParagraph>
<CgvParagraph>
Le port du casque est obligatoire. Il doit être porté afin de constituer une protection
effective pour le cavalier et être conforme à la norme NF EN 1384.
</CgvParagraph>
<CgvParagraph>
    Équipement recommandé :
Bottes, cravache (ou stick), gants, guêtres, protège-dos conseillé pour le CSO et obligatoire pour le cross
</CgvParagraph>

        
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 11 - données personnelles</h2>
        <CgvParagraph>
        Conformément à la loi, pour exercer ses droits le Client est informé que la collecte de ses données à caractère personnel sont nécessaires à la gestion de son service et à sa réalisation.
        </CgvParagraph>
        <h2>Collecte des données à caractères personnel</h2>
        <CgvParagraph>
        Commande de prestations par le Client :
        Nom, prénom, adresse postale, numéro de téléphone, adresse mail, nom de l’animal,
        lieu de détention de l'animal.
        Groupe sanguin, médecin traitant, personne à contacter en cas d'urgence.
        photo de l’animal (pour la communication animale)
        </CgvParagraph>

        <h3>Paiement</h3>
        <CgvParagraph>
        Nom, prénom, numéro du virement bancaire.
        </CgvParagraph>
        <h2>
        Destination des données à caractère personnel
        </h2>
        <CgvParagraph>
        Les données à caractère personnel sont réservées à l’usage unique du Prestataire.
        </CgvParagraph>
        <h2> Responsable de traitement</h2>
        <CgvParagraph>Le responsable de traitement des données est le Prestataire, au sens de la loi informatique
        et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des
        données à caractère personnel.
        </CgvParagraph>
        <h2>
        Limitation du traitement
        </h2>
        <CgvParagraph>
        Sauf si le Client exprime son accord, ses données à caractère
        personnel (prénom, nom de l’animal, photo de l’animal, histoire personnelle et compte-rendu
        du service), ne sont pas utilisées à des fins publicitaires ou marketing.
        </CgvParagraph>
        <h2>Durée de conservation des données</h2>
        <CgvParagraph>
        Le Prestataire conserve les données diffusées pendant un délai de 5 ans, couvrant le temps
        de la prescription de la responsabilité civile contractuelle applicable.
        </CgvParagraph>
        <h2>Sécurité et confidentialité</h2>
        <CgvParagraph>
        Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et
        physiques en matière de sécurité du numérique pour protéger les données personnelles
        contre les altérations, destructions et accès non autorisés. Toutefois, il est à signaler
        qu’Internet n’est pas un environnement complètement sécurisé et le Prestataire ne peut
        garantir la sécurité de la transmission ou du stockage des informations sur internet.
        </CgvParagraph>       
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 12 - propriété intellectuelle</h2>
        
        <CgvParagraph>
        Le contenu du site <SiteName {...props}/> est la propriété du vendeur et de ses partenaires.
        Il est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.
        Toute reproduction totale ou partielle de ce contenu est strictement interdite sans un accord écrit exprès de l’éditeur.
        </CgvParagraph>
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 13 - DROIT APPLICABLE – LANGUE</h2>
        <CgvParagraph>
        Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit
        français.
        Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient
        traduites en une ou plusieurs langues étrangères, seul le texte français ferait loi en cas de litige.

        </CgvParagraph>
        
        </div>
        <div className='cgv-article-wrapper'>
        <h2 className='cgv-article-title'>ARTICLE 14 – LITIGES</h2>
        <CgvParagraph>
        En cas de litige, les parties devront trouver une solution amiable.
        Pour toute réclamation, merci de contacter <Label {...props}/> par mail : <EquilysMail/>
        En cas de nécessité, le remboursement partiel ou total de la prestation mettra fin irrévocablement à tout litige, sans qu’il puisse être prétendu à des dommages et intérêts.
        En cas de besoin les tribunaux de Toulouse seront seuls compétents. Toute procédure sera exclusivement initiée en langue française.
        </CgvParagraph>
        </div>
        </div>
    )
}

export default Cgv
