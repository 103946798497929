import React, { useEffect, useRef, useState } from 'react'
import { SlidingDiv } from '../common/StyledComponents';


export default function SlidingCarouselElt({...props}) {

  return (
    
    <SlidingDiv activNb={props.activNb} id={props.index} className="sliding-div" >
    <img className='visible-image' src={props.image} alt={props.alt}/>
    </SlidingDiv>
  )
}
