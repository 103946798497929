import React, { useEffect, useRef, useState } from 'react'
import {NavLink} from 'react-router-dom';
import { NavLetter, StyledNavbar } from '../common/StyledComponents';
import logo from '../images/common/equilys.png';
import {navElts, subMenu} from "../data";
import {FaAlignJustify} from 'react-icons/fa';
import {GiBoomerangCross} from 'react-icons/gi';
import TelNumber from './TelNumber';

interface NavEltsI{
  id: number,
  label: string[],
  path: string  
}
class NavElts{
  id: number;
  label: string[];
  path: string;
  constructor(pId: number, pLabel: string[], pPath: string){
    this.id = pId;
    this.label = pLabel;
    this.path = pPath;
  }
}
interface DataNavEltsI{
  id: number,
  label: string,
  path: string
}

export default function AnimatedNavbar({...props}) {

const [launchAnim, setLaunchAnim] = useState(false);
const [burgerIsClicked, setBurgerIsClicked] = useState(false);
const [NavEltsState, setNavEltsState] = useState<NavEltsI[]>();
const timerRef = useRef<any>(null);
const timerTelRef = useRef<any>(null);
const timerBurgerCross = useRef<any>(null);
const [wrapperClass, setWrapperClass] = useState<string>('navbar-wrapper');
const [crossClass, setCrossClass] = useState<string>("burger-cross");
const [accompagnementIsClicked, setAccompagnementIsClicked] = useState(false);
const [launchTelAnim, setLaunchTelAnim] = useState<boolean>(false);
const aLetterTimer = useRef<any>(null);

const telTab = props.tel.split("");
const [activNumber, setActivNumber] = useState<number>(telTab.length-1);





useEffect(() => { 

// launch or not
 timerTelRef.current = setTimeout(() => {   
    setLaunchTelAnim(true);  
    setActivNumber(0);
 }, 5000);
// by letter
if (launchTelAnim){ 
  aLetterTimer.current = setTimeout(() => {                  
          if (activNumber < telTab.length){
              setActivNumber(activNumber + 1);  
          //end of table, keep text written             
          }else{
              setActivNumber(telTab.length-1);
              setLaunchTelAnim(false);
          }
  }, 400);
}
 timerRef.current = setTimeout(() => {
  setLaunchAnim(launchAnim === false ? true : false);  
 }, 10000);



 
  return () => {
   clearTimeout(timerRef.current);
   clearTimeout(timerTelRef.current);
   clearTimeout(aLetterTimer.current);
  }
})
// didmount
useEffect(() => { 
  console.log("props : ", props);
  setLaunchAnim(true);
  formatNavLetters(navElts);
   return () => {
   
   }
 }, [])
 

 const openSubMenu=()=>{
  return <div className={accompagnementIsClicked ? 'submenu-wrapper active' : 'submenu-wrapper'}>
    {subMenu && subMenu.map((aSubElt)=>(     
        <NavLink 
         onClick={(e: React.MouseEvent)=>handlePhoneMenu()}
         key={aSubElt.id}
         to={aSubElt.path}
         className={props.clickedNavId === aSubElt.id ? "li-menu-activ" : "li-submenu"}
        >
        {aSubElt.label}
        </NavLink>
    ))}
  </div>
}

function calculateFrequenceFromKey(nb: number){
  let u = 0.5;
  const r = 0.05;
  let myTable = [];
    for (let index = 0; index <= nb; index++) {
      u = u + r;
      myTable.push(u);
    }
    return myTable;
  
}

function formatNavLetters(dataNavElts: DataNavEltsI[]){
  let navElts: NavEltsI[];
  navElts = dataNavElts.map((anElt: DataNavEltsI)=>{  
    let splitedLabel =  anElt.label.split("");
    // communication animale
    if (anElt.id === 3){
      splitedLabel = ["C", "o", "m","m","u","n","i","c","a","t","i","o","n"+ " "+ "a","n","i","m","a","l","e"];
    } 
    /* if(anElt.id === 2){
      splitedLabel = ["M", "e", "s"+" "+"A","c","c","o","m","a","g","n","e","m","e","n","t","s"];
    } */
    const navElt = new NavElts(anElt.id, splitedLabel, anElt.path);
    return navElt;
  })
  setNavEltsState(navElts);
  console.log('navElts : ', navElts);
}

const handlePhoneMenu=()=> {
  if(props.isSmallDevice){
    setWrapperClass(wrapperClass === "navbar-wrapper" ? "opened-navbar" : "navbar-wrapper");
    setCrossClass("burger-cross-animated");
    timerBurgerCross.current = setTimeout(()=>{
      // reinit burger click for cross anim
      setCrossClass("burger-cross");
     }, 1500)
    }
}

  return (
    <StyledNavbar position= {props.heroBottom}> 
        <div className='nav-header-wrapper'>
          <h2 className="h2-nav">{telTab.map((aNumber: string, index: number)=>(
          <TelNumber
            activNumber={activNumber} 
            phoneLength={telTab.length}
            telNb={index}
            key={index}          
            launchTelAnim={launchTelAnim} 
            aNumber={aNumber}
            />          
            
          ))}</h2>
          
          <h2 className='h2-nav'> Ingrid Equilys</h2>
        </div>
        <img src={logo} alt="equilys-logo" className='logo-menu'/>
        <FaAlignJustify
          onClick={(e: React.MouseEvent)=>{          
          handlePhoneMenu()
          setBurgerIsClicked(burgerIsClicked ? false : true)
        }}        
        className='menu-tel-nopop'/>
        <ul className={wrapperClass}>
        <GiBoomerangCross 
         className= {crossClass}
         onClick={(e: React.MouseEvent)=>
          {handlePhoneMenu()
          setBurgerIsClicked(burgerIsClicked ? false : true)
          }        
        }
         />
          {NavEltsState && NavEltsState.map((anElt: NavEltsI)=>(
            <div key={anElt.id}>
            <NavLink 
            onClick={(e: React.MouseEvent)=>{
              props.onClick(anElt.id)
              anElt.id === 2 ? setAccompagnementIsClicked(accompagnementIsClicked ? false : true) : handlePhoneMenu()
            }}                     
            to={anElt.path}
            className={props.clickedNavId === anElt.id ? "li-menu-activ" : "li-menu"}
            >
              {anElt.label.map((aLabel: string, key: number)=>(
                <NavLetter 
                frequence={calculateFrequenceFromKey(anElt.label.length)[key].toString()}
                key={key}
                launchAnim={props.clickedNavId === anElt.id}
                >
                  {aLabel}                
                </NavLetter>
              ))}
             
            </NavLink>
             {anElt.id === 2 && accompagnementIsClicked && openSubMenu()}
             </div>
          ))}
        </ul>

    </StyledNavbar>
  )
}


