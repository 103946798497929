import React, { useEffect, useRef } from 'react'
import heroImage from '../images/com/comAnimale01.png';
import heroImagePhone from '../images/com/comAnimale01.png';
import nouba from '../images/com/noubaCom.jpg';
import loadingImage from '../images/enseignement/loadingImage.jpg';
import comHorse from "../images/com/comHorseIngrid.jpg";
import HeroNav from '../components/HeroNav';
import effect from '../images/com/comEffect.png';
import cartes from '../images/com/comCartes.png';
import doggies from '../images/com/comDoggies.png';
import PageTitleCom from '../components/PageTitleCom';
import comImg from "../images/home/comHome.jpg";
import ComModules from '../components/ComModules';
import com1 from "../images/com/moduleCom01.png";
import com2 from "../images/com/moduleCom02.png";
import comInit from "../images/com/patch.png";
import com1Pdf from "../pdf/moduleCom01.pdf";
import com2Pdf from "../pdf/moduleCom02.pdf";
import initPdf from "../pdf/initiationCa.pdf";
import MaskTest from '../components/MaskTest';
import background from '../images/com/bckgCom.png';
import backgroundPerf from '../images/com/comBckgPerf.png';
import device from '../images/com/device.png';
import comPrivilege from '../images/com/comPrivilege.png';
import ButtonShine from '../components/ButtonShine';
import { useNavigate } from 'react-router-dom';
import { GiMultipleTargets } from 'react-icons/gi';
import comBand from '../images/com/comBand.png';
import ObservedCompo from '../components/ObservedCompo';
import EquilysPlume from '../components/EquilysPlume';
import ComModulesTraining from '../components/ComModulesTraining';


export default function ComAnimale({...props}) {
   

  const heroProps = {...props, 
    telHero: heroImagePhone, 
    hero: heroImage, 
    title1:"Ingrid Equilys", 
    title2:"Communication animale",
    classNameImage: "hero-photo-com",   
    clickedNavId: props.clickedNavId,
    loadingImage: loadingImage,
    className: 'hero-com',
    heroTitle: "hero-com-title"
  }
  
  const timerRef = useRef<any>(null); 
  const navigate = useNavigate();
  
  useEffect(() => {
    props.setClickedNavId(3);
    window.scrollTo({top: -500, behavior: 'smooth'}); 
  

    timerRef.current = setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 200);
    
    return () => {     
      props.setClickedNavId(-2);
      clearTimeout(timerRef.current);      
    }
  
  }, [])
  
  const state = {...props};
 
  const handleClick = (path: string) =>{
    if(path){
      navigate(path, state);
    }
  }
  
   
   
  return (
    <>
    
    <HeroNav {...heroProps}>
      <img className="com-effect" src={effect}></img>
      <img className="com-effect-02" src={effect}></img>
     {/*  <img className="com-ingrid" src={ingrid}></img> */}
      
    </HeroNav>
    
    <div className={props.className}>    
      <PageTitleCom title="Un stage pour vous"/>
      <div className='com-container'>      
      <div className='com-animal-wrapper'>
      <div className='com-animal-img-wrapper'>
      <img className='home-animal-img' src={comImg} alt="communication animale" />
      </div>
      <div className='com-animal-text-third'>
      La communication animale est ouverte à tous ; elle ne demande que croyance et persévérance.
      Elle peut vous emmener loin dans la connaissance, au plus profond de vous-même et du lien construit avec votre compagnon.
      Je vous propose ici trois modules de formation qui vous permettront d'aborder en douceur cette exploration par étapes successives.
      Une nouvelle aventure s'ouvre à vous, qui vous fera évoluer personnellement et vous ouvrira des perspectives jusqu'alors inconnues.
      
      </div>
      <img className='img-dogs' src={doggies} alt='unies'></img>
      </div>
      <EquilysPlume/>
      <div className='com-animal-separation'>Les <ObservedCompo className="f-s-title">&nbsp;modules&nbsp;</ObservedCompo>de formation</div>
      {/* Initiation */}
      <h2 className='h2-green'>Module 1 - Initiation</h2>
      <div className='com-animal-wrapper3'>     
      <div className='com-animal-download-zone'>
      <div className='com-animal-details'>
      Ce module permet d'aborder les fondements de la communication animale et en pose les principes. Car pour bien comprendre cette pratique, 
      certaines connaissances sont indispensables.
      La méthodologie s'appuie sur trois notions : <span className='com-pink'>APPRENDRE - COMPRENDRE - APPLIQUER.&nbsp;</span>
      En présentiel, le stage d'initiation s'articule donc autour de deux demi-journées entre théorie et pratique. </div>
      <div className='com-animal-details'>
        
      <ComModules title="En présentiel" pdfSource={initPdf} imgSrc={comInit}/>
      <ButtonShine
        position={false}
        onClick={(e: React.MouseEvent)=>handleClick("/actualites")} label="LES STAGES" />
      </div>
      </div>
      <div className='df-r-c'>
        <div className='com-intro-wrapper'>
          <p>Une première porte s'ouvre alors sur la connaissance. </p>
          <p>La maîtrise s'acquiert dans le temps avec un accompagnement et un soutien réguliers.
          Pour cette raison, je vous propose également un suivi à distance.</p>
        </div>
        <div className='com-intro-wrapper'>
        <ComModulesTraining title="Initiation" pdfSource={com1Pdf} imgSrc={com1}/>
        
        
        
        </div>

      </div>     
      
     
      
      <img className='com-intro-text-background' src={background} alt="image présentation">

      </img>
      
      </div>
      {/* Perfectionnement */}
      <h2 className='h2-green'>Module 2 - Perfectionnement</h2>
      <div className='com-animal-wrapper3'>     
      <div className='com-animal-download-zone'>
      <div className='com-animal-details'>
      Ce module permet d'approfondir ses connaissances et de progresser dans la pratique de la communication animale.
      On y traitera des différents outils et méthodes, mais aussi des champs énergétiques. On abordera également le champ de conscience par la méditation et le voyage mental.
      </div>
      <div className='com-animal-details'>        
      
      <ButtonShine
        position={false}
        onClick={(e: React.MouseEvent)=>handleClick("/actualites")} label="LES STAGES" />
      </div>
      </div>
      <div className='df-r-c'>
        <div className='com-intro-wrapper'>
          <p>Ce stage permet au pratiquant de s'ouvrir à une nouvelle conscience des puissances qui nous entourent et de gagner en force de vie.</p>
          <p>
          Afin d'affiner votre nouvelle conscience du monde, je vous propose un suivi personnalisé à distance suivant trois formules</p>
        </div>
        <div className='com-intro-wrapper'>
        
        <ComModulesTraining title="Perfectionnement" pdfSource={com2Pdf} imgSrc={com2}/>
        
        
        </div>

      </div>     
      
     
      
      <img className='com-intro-text-background' src={backgroundPerf} alt="image présentation">
      </img>
      
      </div>

      </div>
      <h2 className='h2-pink'>Un suivi en ligne personnalisé</h2>
      
      <div className='com-container'>      
      <div className='com-animal-wrapper-2'>      
      <img className='animal-img-device' src={device} alt="communication animale" />
      
      <div className='com-animal-text-third'>
      Les trois formules d'accompagnement ouvrent droit à un accès illimité au groupe de soutien Facebook©.
      Je vous fournis sur un espace dédié, les éléments permettant de vous exercer : vidéos en ligne, supports et exercices pratiques.
      Selon votre besoin, vous avez aussi la possibilité d'accéder à un coaching en présentiel, en live ou privé.
      </div>
      
      </div>
      <div className='com-animal-formules-separation'>Les <ObservedCompo className="f-s-title">&nbsp;formules&nbsp;</ObservedCompo>de formation en ligne</div>
      <div className='com-animal-formules-container'>
          <div className='com-animal-formules-title-wrapper'>
          
          <h2 className='h2-green'>Formule 1 - Autonomie</h2>
          <div className='com-animal-formules-wrapper'>
          <ObservedCompo className="left-appear"><img src={comHorse} className="img-com-autonomie" alt='Formule 1'></img></ObservedCompo>
          <p className='com-animal-formules-txt'>
          Cette formule vous permet de découvrir la communication animale pendant 4 semaines. 
          Durant cette période, vous aurez donc accès à des exercices quotidiens et des vidéos de soutien consultables en ligne.    
          </p>
          </div>
      </div>
      <div className='com-animal-formules-title-wrapper'>
      <div className='com-animal-formules-wrapper'>
      <h2 className='h2-green'>Formule 2 - Intuition</h2>  
      <ObservedCompo className="right-appear"><MaskTest></MaskTest></ObservedCompo>
      </div>
      <p className='com-animal-formules-txt'>
      Cette formule permet de vous initier pleinement à la communication animale pendant 8 semaines. 
      Durant cette période, vous aurez accès à des exercices réguliers et aux vidéos de soutien consultables en ligne.
      Pour vous épauler, je vous propose un Live par semaine et deux jours de soutien en présentiel, pendant lesquels
      vous pourrez partager vos impressions, vos sensations et interrogations.
      </p>
      </div>
     
      <div className='com-animal-formules-container'>
    
      <ObservedCompo className='bottom-appear'><img src={comPrivilege} className="img-com-autonomie" alt='Formule 3'></img></ObservedCompo>
       </div>
       
      </div>
      
      </div>
      <h2 className='h2-green'>En pratique</h2>
      
      <div className='com-container'>        
        <div className='com-animal-wrapper5'>
        <div className='com-animal-wrapper5-txt'>Vous pouvez développer rapidement votre intuition et utiliser des exercices pour commencer à comprendre vos animaux.
        Quelle que soit la formule choisie, le suivi en communication animale commence 24 heures après confirmation de votre règlement.
        La formation propose des vidéos, des audios et des conseils écrits donnant les clés pour communiquer et faire des soins énergétiques en toute bienveillance.
        Vous aurez ainsi accès au contenu correspondant au module choisi durant la période prévue par votre formule. 
        La formule <span className='span-green'>Intuition </span> prévoit en outre des rendez-vous de coaching présentiel et à distance (Lives en groupe ou privés via Zoom©) 
        <ButtonShine label="Je m'inscris" onClick={(e: React.MouseEvent)=>handleClick("/reservation")}></ButtonShine>
        </div>
        <img className="com-cartes" src={cartes}></img>
       
        </div>
        <PageTitleCom title="Vous préférez que je commmunique avec lui..."/>
      </div>
      
      </div>
      <div className='com-container'>
        <div className='com-animal-wrapper4'>
        Vous rencontrez un problème de communication avec votre animal et ressentez sa frustration à travers la vôtre. 
        Vous pensez qu'il tente de vous faire comprendre quelque chose mais ne parvenez pas à décoder ses messages.
        <p>Si vous sentez qu'il est urgent de savoir et avoir un premier contact, je serai heureuse de vous aider.</p>
        </div>
        <div className='com-container-trans'>
          <img className='com-animal-band' src={comBand} alt="bandeau je communique avec lui">
          </img>
          </div>
          <div className='com-container'>
            <h2 className='h2-green'>En pratique</h2>
    <div className='img-communication-container'>
    <p className='p-justify'>1- Vous complétez la <span className="cgv-pad">Fiche Objectifs <GiMultipleTargets className='form-simple-icon'/></span> <a href={'/reservation'}>ici</a>, afin de renseigner vos objectifs et vos disponibilités puis la téléchargez,</p>
    <p className='p-justify'>2- Vous communiquez la fiche dûment complétée par mail à <a href="mailto:equilys.ingrid@gmail.com">equilys.ingrid@gmail.com</a></p>
    <p className='p-justify'>3- Vous me contactez par message au <a href="tel:+33622941167">06 22 94 11 67</a> , sur Messenger© Ingrid Equilys ou par mail, avec les informations indispensables à la mise en place de la communication :</p>
      <div className='img-communication-wrapper'>
        <div className='simple-center-wrapper'><p className='p-justify'>- Une photo de votre animal seul, </p>
        <p className='p-justify'>- Son nom,</p>
        <p className='p-justify'>- Son âge,</p>
        <p className='p-justify'>- Les questions et messages à lui communiquer.</p>
        </div>
        <div className='simple-center-wrapper'>
        <img className='photo-communication' src={nouba} alt="photo pour communication"></img>
        </div>
        </div>
    <p className='p-justify'>4- Nous convenons d'une date pour la communication et je vous fais un compte rendu détaillé une fois celle-ci réalisée.</p>
    </div>
    <ButtonShine
        position={false}
        onClick={(e: React.MouseEvent)=>handleClick("/contact")} label="CONTACT" />
   
    </div>
    </div>
    </>
  )
}
