import React, { useEffect, useRef, useState } from 'react'
import AnimatedNavbar from '../components/AnimatedNavbar';
import PageTitle from '../components/PageTitle';

import {ReactComponent as EbLogo} from "../svg/logoEnBouyssou.svg"

export default function Mentions({...props}) {
  
  const timerRef = useRef<any>(null);
  
    useEffect(() => {
      props.setClickedNavId(-1);
      window.scrollTo({top: -500, behavior: 'smooth'}); 
      timerRef.current = setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 200);
     
    return () => {
      props.setClickedNavId(-2);
      console.log("on quitte prestation");
      clearTimeout(timerRef.current);
      
    }
  
  }, [])

  return (
    <><AnimatedNavbar {...props} />
    <div className='mentions'>
      <PageTitle title="Mentions légales et cookies"/>
      <div className='mentions-infos-container'>

        <h2>
          Hébergement
        </h2>
        Le site <span className='mentions-site'>{props.siteName}</span> est hébergé chez Ionos

        
        <h2>
          Éditeur du site
        </h2>
        <span className="signature-mentions">Ingrid Équilys</span>
        <h2>
          Conception, réalisation et maintenance du site
        </h2>
        
          <div className="eb-wrapper">Aline BRAND 
          <a href='https://latoiledenbouyssou.fr' target="_blank">
            <EbLogo className="eb-logo"/>
          </a>
            
             
            <div className='web-group'>
            <span className="eb-span">La Toile d'En Bouyssou </span>
            <div className="eb-address">12 chemin d'en Bouyssou | 81220 Viterbe</div>
            </div>
          </div>
          <h2>
          Prises de vues
        </h2>
        <span className="signature-mentions">Salomé Dubart</span>
        <div className='carac-perso'>
          <h2>
            Droits d’auteurs et propriété intellectuelle
          </h2>
          Selon la loi du 11 mars 1957 (art.41) et du code de la propriété intellectuelle du 1er juillet 1992, le site <span className='mentions-site'>{props.siteName}</span>
          &nbsp; et son contenu constituent une œuvre protégée en France par le Code de la Propriété intellectuelle, et à l’étranger par les conventions internationales en vigueur sur le droit d’auteur.
        </div>
        <h2>
          Cookies
        </h2>
        Le site <span className='mentions-site'>{props.siteName} </span>n'utilise pas de cookies publicitaires.

        <h2>
          Informations personnelles
        </h2>
        Le site <span className='mentions-site'>{props.siteName}</span> ne collecte pas d'informations personnelles.
        En revanche, les coordonnées saisies dans le formulaire de contact sont envoyées
        par mail à Ingrid Equilys pour qu'elle puisse vous répondre.

      </div>
    </div></>
  )
}
