import React from 'react'
import { AppearingDiv } from '../common/StyledComponents';


export default function AppearingCarouselElt({...props}) {

  return (
    
      <AppearingDiv activNb={props.activNb} id={props.index} >
        <img id="mask-size" className='appearing-visible-image' src={props.image} alt={props.alt}/>
      </AppearingDiv>
 
  )
}
