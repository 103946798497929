
import React, { useContext, useEffect, useRef, useState } from 'react';
import {BsTrash} from 'react-icons/bs';
import {MdOutlineDraw, MdOutlineKeyboard, MdClose} from 'react-icons/md';
import ButtonShine from './ButtonShine';
import {TheContext} from "../contexts/EquilysContext";


interface PriceI{
    id: number,
    title: string,
    category: string,
    label: string
}
interface DescriptionI{
    id: number,
    category: string,
    title: string,
    infos: string,
    price: PriceI[]
}

export default function UploadSign({...props}) {
   
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasDrawingRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);    
    
    const [blobResult, setBlobResult] = useState<string>();
    const [imgText, setImgText] = useState("");
    /**Drawing */
    const [startDrawPosition, setStartDrawPosition] = useState<{x: number, y: number}>({x:-1,y:-1});
    const [drawPosition, setDrawPosition] = useState<{x: number, y: number}>(startDrawPosition);
    const [letsDraw, setLetsDraw] = useState<boolean>(false);
    const [letsDrawTouch, setLetsDrawTouch] = useState<boolean>(false);
    const [openSignature, setOpenSignature] = useState<boolean>(false);
    const canvasWidth = 400;
    const canvasHeight = 200;
    const fontSize = 50;
    const [clickedButton, setClickedButton] = useState("none");
    const [clickedMode, setClickedMode] = useState("drawing");
    const [myCanvas, setMyCanvas] = useState({height: canvasHeight, width: canvasWidth});
    const drawCanvas= {left:canvasDrawingRef.current?.clientLeft, top: canvasDrawingRef.current?.clientTop};

    /*While drawing, disable app scroll*/
    const myContext = useContext(TheContext);
    let bodyStyle: CSSStyleDeclaration, htmlStyle: CSSStyleDeclaration;
    
    useEffect(()=>{      

        htmlStyle = document.getElementsByTagName("html")[0].style;
        bodyStyle = document.getElementsByTagName("body")[0].style;
        if(letsDrawTouch) {
            bodyStyle.position = "static";
            bodyStyle.overflow="hidden";            
            htmlStyle.position = "static";
            htmlStyle.overflow="hidden";            
        }else{            
            htmlStyle.position="relative";
            htmlStyle.overflow="auto";
            bodyStyle.position="relative";
            bodyStyle.overflow="auto";
        }
        /* if(openSignature && clickedMode === "close"){
            setOpenSignature(false);
        } */

    });

    const drawText = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setImgText(e.currentTarget.value);
    console.log("text : ", imgText);
    if(canvasRef.current){
        let context = canvasRef.current.getContext("2d");
        if (context){
        context.translate(0.5, 0.5); // blury purpose
        context.font=`${fontSize}px Passions Conflict`;
        context?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        context?.fillText(e.currentTarget.value, 10, canvasHeight/2-fontSize, canvasWidth);
       }        
    }
    }
    const initDrawWithMouse = (e: React.MouseEvent)=>{
        
        setLetsDraw(true);
        if (canvasDrawingRef.current){
            let drawingContext = canvasDrawingRef.current.getContext("2d");         
            if (drawingContext){                
                const x = e.nativeEvent.offsetX;
                const y = e.nativeEvent.offsetY;
                
                console.log("draw position : x ", x);
                console.log("draw position : y ", y);
                setStartDrawPosition({x: x, y: y});
                setDrawPosition({x: x, y: y});
           }            
        }
    }
    const initDrawWithTouch = (e: React.TouchEvent)=>{
        
        setLetsDrawTouch(true);
        if (canvasDrawingRef.current){
            let drawingContext = canvasDrawingRef.current.getContext("2d");         
           
            if (drawingContext){                
                const x = e.targetTouches[0].clientX - canvasDrawingRef.current.getBoundingClientRect().x;
                const y = e.targetTouches[0].clientY - canvasDrawingRef.current.getBoundingClientRect().y;
                console.log("initPosition x: ",x);
                console.log("initPosition y: ",y);

                setStartDrawPosition({x: x, y: y});
                setDrawPosition({x: x, y: y});
           }            
        }
    }
    const drawOnCanvasWithMouse = (e: React.MouseEvent)=>{
       
        const color = "blue";
        const x = e.nativeEvent.offsetX;
        const y = e.nativeEvent.offsetY; 
        setDrawPosition({...drawPosition, x: x, y: y});
        if (canvasDrawingRef.current){
            let drawingContext = canvasDrawingRef.current.getContext("2d");
            if (drawingContext){
            if (letsDraw){
                if (canvasDrawingRef.current){
                    let drawingContext = canvasDrawingRef.current.getContext("2d");            
                    if (drawingContext){
                        drawingContext.beginPath();                            
                        drawingContext.moveTo(startDrawPosition.x,startDrawPosition.y);                 
                        drawingContext.lineCap = 'round';
                        drawingContext.lineJoin = 'round';
                        drawingContext.lineWidth=3;
                        drawingContext.strokeStyle=color;
                        drawingContext.lineTo(drawPosition.x,drawPosition.y);       
                        drawingContext.stroke();
                        setStartDrawPosition(drawPosition);
                        setDrawPosition({x: x, y: y});             
                        
                   }            
                }
            }
           }            
        }
    }
    const drawOnCanvasWithTouch = (e: React.TouchEvent)=>{
        /* e.preventDefault(); */
        const color = "blue";
       /*  const x = e.touches[0].clientX;
        const y = e.touches[0].clientY;  */
        
        if (canvasDrawingRef.current){
            let drawingContext = canvasDrawingRef.current.getContext("2d");
            const x = e.targetTouches[0].clientX - canvasDrawingRef.current.getBoundingClientRect().x;
            const y = e.targetTouches[0].clientY - canvasDrawingRef.current.getBoundingClientRect().y;
    
            setDrawPosition({...drawPosition, x: x, y: y});
            if (drawingContext){
            if (letsDrawTouch){
                if (canvasDrawingRef.current){
                    let drawingContext = canvasDrawingRef.current.getContext("2d");            
                    if (drawingContext){
                        drawingContext.beginPath();                            
                        drawingContext.moveTo(startDrawPosition.x,startDrawPosition.y);                 
                        drawingContext.lineCap = 'round';
                        drawingContext.lineJoin = 'round';
                        drawingContext.lineWidth=3;
                        drawingContext.strokeStyle=color;
                        drawingContext.lineTo(drawPosition.x,drawPosition.y);       
                        drawingContext.stroke();
                        setStartDrawPosition(drawPosition);
                        setDrawPosition({x: x, y: y});             
                        
                   }            
                }
            }
           }            
        }
    }
  
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        if (clickedButton === "type"){
        if(canvasRef.current){
            canvasRef.current.toBlob((blob) => {
                console.log("the blob " , blob)
                if(blob){
                    let url = URL.createObjectURL(blob);
                    setBlobResult(url);
                    // reset window open and clickedButton
                    setOpenSignature(false);
                    setClickedButton("none");
                }
               
            });
        }
        }
        if (clickedButton === "draw"){
            if(canvasDrawingRef.current){
                canvasDrawingRef.current.toBlob((blob) => {
                    console.log("the blob " , blob)
                    if(blob){
                        let url = URL.createObjectURL(blob);
                        setBlobResult(url);
                        setOpenSignature(false);
                        setClickedButton("none");
                    }                   
                });
            }
        }
    }
    const eraseCanvas = (e: React.MouseEvent)=>{
        // clear signatures
        setBlobResult("");
        setImgText("");   
        if (e.currentTarget.id==="draw-trash"){
            myContext.setIsDrawing(false);
            setLetsDraw(false);
            setLetsDrawTouch(false);
            if (canvasDrawingRef.current){
                let drawingContext = canvasDrawingRef.current.getContext("2d");         
                if (drawingContext){
                    drawingContext?.clearRect(0, 0, canvasDrawingRef.current.width, canvasDrawingRef.current.height);
                    setStartDrawPosition({x: -1, y: -1});
                    setDrawPosition({x: -1, y: -1});
               }
            }
        }
        if (e.currentTarget.id==="type-trash"){
            if (canvasRef.current){
                let drawingContext = canvasRef.current.getContext("2d");         
                if (drawingContext){
                    drawingContext?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
               }
            }
        }
        
    }
   
    const letsCloseSignatures=(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=> {
        setOpenSignature(false);
        setClickedMode(e.currentTarget.id);        
        console.log("close signatures");
    }

    const letsSign=(e: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {
        setOpenSignature(true);
        setClickedMode("drawing");
        setImgText(""); // reset input sign
    }
    const handleSignClick=(e: React.MouseEvent<HTMLDivElement>, arg: string)=> {
        setClickedButton(arg);
        
    }

  return (
    
    <form name="signatures-form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => { handleSubmit(e); } } /* id="divToPrint" */ className="signature-mode-wrapper">
          <div className={props.isToPrint ?'print-accept-zone':'accept-zone'}>
              <div className='accept-wrapper'>
                  <div className='place-g'>
                      <label className='input-label' htmlFor='place'>Fait à </label>
                      <input 
                      className='signature-form-input' 
                      placeholder='Ville' 
                      defaultValue={props.signature.signaturePlace}
                      type='text' 
                      id='place' 
                      name='signaturePlace' 
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.changeSignatureZone(e)}>             
                  </input>
              </div>
              <div className='place-g'>
                  <label className='input-label' htmlFor='date'>Le </label>
                  <input className='signature-form-input' defaultValue={props.signature.signatureDate} placeholder='date' type='text' id='date' name='signatureDate' onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.changeSignatureZone(e)} >
              </input>
          </div>
      </div>
      <div className={props.isToPrint? 'print-signature-zone':'signature-zone'} onClick={(e: React.MouseEvent<HTMLDivElement>) => letsSign(e)}>
            {blobResult ? <img ref={imgRef} className="signature-img" src={blobResult}></img> : "Cliquez pour signer"}
        </div>
    {openSignature &&<div className='signatures-modes-container'>
         {/*  buttons zone */}    
        <div className='sign-tabs-wrapper'>
            <button id="keyboard" className={clickedMode === "keyboard" ?'activ-sign-label':'left-sign-label'} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>setClickedMode(e.currentTarget.id)}>
                <MdOutlineKeyboard className='simple-icon'/>
            </button>
            <button id="drawing" className={clickedMode === "drawing" ?'activ-sign-label':'sign-label'} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>setClickedMode(e.currentTarget.id)}>
                <MdOutlineDraw className='simple-icon'/>
            </button>
            <button id="close" className={clickedMode === "close" ?'activ-sign-label':'right-sign-label'} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                letsCloseSignatures(e)
                }}>
                <MdClose className='simple-icon'/>
            </button>   
 
        </div>
        {/* Keyboard */}
        <div className= {clickedMode === "keyboard" ? 'signatures-modes-wrapper' : 'signatures-modes-hidden'}>      
            
            <input 
            id="sign-input"
            className='form-input' 
            placeholder='Saisissez votre signature'
            name="sign-input" 
            type='text'
            value={imgText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{drawText(e)}}
            >
            </input>
            <div className='drawing-zone'>
            <canvas 
            ref={canvasRef} 
            className='type-canva' 
            height={myCanvas.height} 
            width={myCanvas.width} 
        >
            </canvas>
            <BsTrash id="type-trash" className='trash' onClick={(e: React.MouseEvent)=>eraseCanvas(e)}/>
            </div>
            
            <ButtonShine label="Signer" type="submit" onClick={(e: React.MouseEvent<HTMLDivElement>)=>handleSignClick(e, "type")} name='type' >Signer</ButtonShine>
            
            </div>
            {/* dessin */}   
            <div className= {clickedMode === "drawing" ? 'signatures-modes-wrapper' : 'signatures-modes-hidden'} >
            <div className='drawing-zone'>
            <canvas 
            ref={canvasDrawingRef} 
            className='sign-canva' 
            height={myCanvas.height} 
            width={myCanvas.width}    
            onMouseUp={(e: React.MouseEvent)=>{
                setLetsDraw(false);
                console.log("mouseup");
            }}
            onMouseMove={(e: React.MouseEvent)=> {letsDraw && drawOnCanvasWithMouse(e)}}
            onMouseOut={(e: React.MouseEvent)=>{
                /* myContext.setIsDrawing(false); */
                setLetsDraw(false);
            }}
            onMouseDown={(e: React.MouseEvent)=>{
                console.log("mouseDown");
                myContext.setIsDrawing(true);
                initDrawWithMouse(e);
            }
        }
        onTouchEnd={
            (e: React.TouchEvent)=>{ 
                if(e.cancelable)
                e.preventDefault();
                setLetsDrawTouch(false);
                console.log("touch end");
                
            }
        }
        onTouchStart={(e: React.TouchEvent)=>{
            console.log("touch");
            if(e.cancelable)
            e.preventDefault();
            setLetsDrawTouch(true);
            myContext.setIsDrawing(true);
            initDrawWithTouch(e);
        }
    }
    onTouchMove={(e: React.TouchEvent)=>{
        letsDrawTouch && drawOnCanvasWithTouch(e)}}
        
        >
        </canvas>
        
        <BsTrash id="draw-trash" className='trash' onClick={(e: React.MouseEvent)=>eraseCanvas(e)}/>
        </div>
        <ButtonShine label="Signer" type="submit" onClick={(e: React.MouseEvent<HTMLDivElement>)=>handleSignClick(e, "draw")} name='draw'>Signer</ButtonShine>
        
        </div> 
    </div>}
   
    </div>
    </form>   
   
  )
}
