import React, { useContext } from 'react'
import { SideNavWrapper } from '../common/StyledComponents';
import {ReactComponent as Elearning} from '../svg/StarText.svg';
import { useNavigate } from 'react-router-dom';
import { TheContext } from '../contexts/EquilysContext';

export default function SideNav({...props}) {
  const navigate = useNavigate();
  const myContext = useContext(TheContext);

  return (  
        <SideNavWrapper heroIsVisible={props.isVisibleHero || myContext.tabsAreVisible} position={window.innerHeight}>
           <Elearning onClick={(e: React.MouseEvent)=>{navigate('/reservation')}} className='svg-learning'/>
        </SideNavWrapper>  
  )
}
