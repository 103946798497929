import React, { useEffect, useState } from 'react';
import logo from '../images/common/equilys.png';
import { Icontact } from '../data';
import { GoLaw } from 'react-icons/go';
import PdfFicheContactToPrint from './PdfFicheContactToPrint';


interface Props {
    isToPrint: boolean, 
    signature:{signatureDate: string, signaturePlace: string},
    paymentInfos: string[]

}

export default function PdfFicheContact(props: Props) {
   const taMaxSize = 240;
   const taRows = 4;
   const taColumns = 60;
   const taText = "en "+taMaxSize+" caractères max"
   
   const allergyPh= "Les allergies "+taText; 
  
 
   

    const defaultContact = 
        {
        contactName:"",
        phone1:"", 
        phone2:"",         
        lastname:"",
        firstname:"",
        childName:"",
        child:"",
        is_allergic:false,
        allergies:"",         
        doctor:"",
        doctorPhone:"",
        blodGroup:"",
        acceptImage:true,
        cgvOk:true,
        signaturePlace: props.signature.signatureDate,
        signatureDate: props.signature.signaturePlace,
        signatureName:""
    }
         
    
   
  
    const [rider, setRider] = useState<Icontact>(defaultContact);
    const [message, setMessage] = useState({allergies:""});
    
    
    const handleTextAreaChange=(e: React.ChangeEvent<HTMLTextAreaElement>)=>{
        const {name, value} = e.currentTarget;
        console.log("name : ", name);
        setMessage({...message, [name]: ""});    
        
        if( countRows(value) < 4 && value.split("").length < taMaxSize){
           setRider({...rider, [name]: value});
           
        }else{
            console.log("on set message ", name);
            setMessage({...message, [name]:"Vous avez dépassé le nombre de lignes ou de caractères autorisés"});
        }
      

    }
  
    function countRows(input: string): number{
        const stringTable = input.split("\n");
        let lastElement=-1;
       for (let i = 0; i<stringTable.length; i++){
        lastElement = i;
       }
       return lastElement;
    }
 
    return (
        <>
         {!props.isToPrint?
        <div className={!props.isToPrint?'pdf-form-container':'pdf-form-hidden'}>        
        <h1 className='pdf-form-title'>Fiche Contact</h1>
        <div className='pdf-container'>
        <div className='pdf-wrapper'>
        <h2 className='obj-form-subtitle'>
            Autorisation de soins y compris pour les mineurs
        </h2>
        <div className='civil-wrapper'>                        
        <img src={logo} alt="Ingrid Equilys logo" className='pdf-logo-equilys'></img>
        <div className='civil-state'>
        <div className='contact-pdfform-wrapper'>
        <div className='input-title'>
            Personne à prévenir en cas d’accident
        </div>        
        <div className='contact-pdfform-details'>        
        <div className='input-pdffrom-group'>
            <label className='input-label' htmlFor="contactName">Nom et prénom</label>
            <input
            id="contactName"
            name="contactName"
            type="text"                         
            value={rider.contactName}
            placeholder="Nom de la personne à contacter"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, contactName: e.currentTarget.value.toString() })}
            className='obj-pdfform-input'
            >
            </input>       
        </div>
        <div className='input-pdffrom-group'>
            <label className='input-label' htmlFor='phone1'>Téléphone</label>
            <input
            name="phone1"
            type="text"
            id="phone1"
            value={rider.phone1}
            placeholder="Numéro de téléphone"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, phone1: e.currentTarget.value.toString() })}
            className='obj-pdfform-input'
            >
            </input>        
        </div>
        <div className='input-pdffrom-group'>
            <label className='input-label' htmlFor='phone2'>et / ou</label>
            <input
            name="phone2"
            type="text"
            id="phone2"
            value={rider.phone2}
            placeholder="Numéro de téléphone"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, phone2: e.currentTarget.value.toString() })}
            className='obj-pdfform-input'
            >
            </input>
        </div>
        </div>
        </div>
        <div className='date-wrapper'>
   
        </div>
        </div>
        </div>
        <div className='contact-pdfform-textblock'>
        Je, soussigné(e)
        <input
        id="firstname"
        name="firstname"
        type="text"                          
        value={rider.firstname}
        placeholder="Votre prénom"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, firstname: e.currentTarget.value.toString() })}
        className='obj-pdfform-input'
        >
        </input>        
        <input
        id="lastname"
        name="lastname"
        type="text"                          
        value={rider.lastname}
        placeholder="Votre nom"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, lastname: e.currentTarget.value.toString() })}
        className='obj-pdfform-input'
        >
        </input>
        représentant légal de l’enfant        
        <input
        id="childName"
        name="childName"
        type="text"                          
        value={rider.childName}
        placeholder="Nom de l'enfant"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, childName: e.currentTarget.value.toString() })}
        className='obj-pdfform-input'
        >
        </input>
        autorise l’établissement et les professionnels présents, à prendre toutes les mesures
        indispensables que nécessiterait l’état de santé de mon enfant et suivant l’urgence et la
        gravité de son état à faire transporter mon enfant à l’hôpital par le SAMU ou les pompiers.
        </div>

        <div className='contact-pdfform-wrapper'>
        <div className='input-title'>
            Allergies            
            <input type='radio' 
            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setRider({...rider, is_allergic: true})} }
            name='allergy' id='yes'
            defaultChecked={rider.is_allergic}            
            >
            </input>
            <label htmlFor='yes'>Oui</label>
            
            <input type='radio' 
            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setRider({...rider, is_allergic: false})}} 
            name='allergy' id='no'
            defaultChecked={!rider.is_allergic}            
            >            
            </input>
            <label htmlFor='no'>Non</label>
        </div>          
            Si oui, lesquelles
        <textarea
                maxLength={taMaxSize}
                wrap="hard"
                rows={taRows}
                cols={taColumns}
                placeholder={allergyPh}
                id="allergies"
                name="allergies"
                value={rider.allergies}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)}
                className={props.isToPrint ? 'ta-form-hidden' : 'ta-form-input'}
                style={{resize:"none", overflow: "hidden"}}
        >
        </textarea>
        <span className='warning'>{message.allergies && message.allergies}</span>
        </div>
        <div className='contact-pdfform-wrapper'>
        <div className='input-title'>
            Médecin traitant
        </div>        
        <div className='contact-pdfform-details'>        
        <div className='input-pdffrom-group'>
            <label className='input-label' htmlFor="doctorName">Nom et prénom du médecin</label>
            <input
            id="doctorName"
            name="doctorName"
            type="text"                         
            value={rider.doctor}
            placeholder="Nom du médecin"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, doctor: e.currentTarget.value.toString() })}
            className='obj-pdfform-input'
            >
            </input>       
        </div>
        <div className='input-pdffrom-group'>
            <label className='input-label' htmlFor='doctorPhone'>Téléphone</label>
            <input
            name="doctorPhone"
            type="text"
            id="doctorPhone"
            value={rider.doctorPhone}
            placeholder="Numéro de téléphone"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, doctorPhone: e.currentTarget.value.toString() })}
            className='obj-pdfform-input'
            >
            </input>        
        </div>
        <div className='input-pdffrom-group'>
            <label className='input-label' htmlFor='blodGroup'>Groupe sanguin</label>
            <input
            name="blodGroup"
            type="text"
            id="blodGroup"
            value={rider.blodGroup}
            placeholder="Groupe sanguin"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, blodGroup: e.currentTarget.value.toString() })}
            className='obj-pdfform-input'
            >
            </input>
        </div>
     
        </div>
        <div className='input-pdffrom-group'>
        <h2 className='obj-form-subtitle'>
            Informations et autorisations
        </h2>
            <div className='input-title'>
                Droit à l'image    
                <input type='radio' 
                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setRider({...rider, acceptImage: true})} }
                name='image' id='yes-image'
                defaultChecked={rider.acceptImage}            
                >
                </input>
                <label htmlFor='yes'>Oui</label>
                
                <input type='radio' 
                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setRider({...rider, acceptImage: false})}} 
                name='image' id='no-image'
                defaultChecked={!rider.acceptImage}            
                >            
                </input>
                <label htmlFor='no'>Non</label>        
            </div>
            <div className='contact-radio-group'>          
            J’autorise les professionnels, à utiliser mon image ou celle de mon enfant lors d’activités équestres,
            à des fins d’information ou de promotion, y compris sur le site internet
             
            </div>   
        </div>
        <div className='input-pdffrom-group'>
            <div className='input-title'>
               Règlement
            </div>
            <div className='contact-pdfform-textblock'>
                Le règlement des prestations peut se faire par chèque, espèce ou virement, aux coordonnées bancaires suivantes :
                
                {props.paymentInfos&& props.paymentInfos.map((anInfo: string, index: number)=>(
                    <div key={index}>{anInfo}</div>
                ))}
            </div>
            <div className='contact-pdfform-textblock'>
            Les prestations ne sont pas remboursables en cas de désistement. En revanche, elles sont reportables si elles sont
            annulées au moins <span className='important'>TROIS JOURS à l'avance</span>, en fonction des disponibilités de l'enseignante.
            <span className='important'> Le SOLDE DÛ est versé au plus tard en DÉBUT de séance pour les forfaits et les cours à l'unité</span></div>
        </div>
       
        <div className='input-pdffrom-group'>
            <div className='input-title'>                
               <div className='contact-radio-group'>          
               Conditions de vente | Lues et approuvées
                <input type='radio' 
                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setRider({...rider, cgvOk: true})} }
                name='cgvOk' id='yes-cgv'
                defaultChecked={rider.cgvOk}            
                >
                </input>
                <label htmlFor='yes-cgv'>Oui</label>                
                <input type='radio' 
                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setRider({...rider, cgvOk: false})}} 
                name='cgvOk' id='no-cgv'
                defaultChecked={!rider.cgvOk}
                >            
                </input>
                <label htmlFor='no-cgv'>Non</label>
                </div>   
            </div>
            <div className='contact-pdfform-textblock'>
            Vous pouvez consulter les conditions générales de vente de prestations d'<span className='signature-mentions'>Ingrid Equilys </span>en cliquant sur l'onglet 
                <div className='cgv-pad'>                    
                    Conditions générales 
                    <GoLaw className='form-simple-icon'/>
                </div>
            </div>
  
        </div>
        <div className='contact-pdfform-textblock'>
       
            Je soussigné (e)
            <input 
            name="signatureName" 
            placeholder='Nom et prénom'
            type="text" 
            onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setRider({ ...rider, signatureName: e.currentTarget.value})} value={rider.signatureName} className='obj-pdfform-input'/> 
            (parent ou représentant légal majeur pour les mineurs)
            déclare avoir pris connaissance de ce formulaire contact ainsi que du document « conditions de ventes de prestations » et m’engage à les respecter
        </div>
        </div>
        </div>
        </div>
        </div>
        :
        <PdfFicheContactToPrint {...props} rider={rider}/>

        }
        </>
    )
}