
import { useEffect, useRef, useState } from 'react';
import { SlidingContainer } from '../common/StyledComponents';
import SlidingCarouselElt from './SlidingCarouselElt';

export default function ScrollingCarousel({...props}) {
  const data = props.together;
  const timerRef = useRef<any>(null);
  const refDiv = document.getElementById("ref-div");
  const refDivWidth = refDiv?.getBoundingClientRect().width;
  const refDivLeft = refDiv?.getBoundingClientRect().left;
  const [translateX, setTranslateX] = useState<number>(0);
  const [activNb, setActivNb] = useState<number>(0);  
  const [prevTitle, setPrevTitle] = useState<string>("");
 
 
  useEffect(() => {    
      if (timerRef.current){
          clearTimeout(timerRef.current);
      }  
      timerRef.current = setTimeout(() => {
          if(activNb<data.length-1){
            setActivNb(activNb + 1);  
            if(refDivWidth)            
             setTranslateX(translateX-refDivWidth);
            }else{
              setActivNb(0);             
              setTranslateX(0);                       
          }
          setPrevTitle(data[activNb].title);
      }, 5000);
  
    return () => {
      clearTimeout(timerRef.current);
    }
  })
  return (
    <div id={props.id} className='carousel-container'>
      
    <div id="ref-div" className='ref-div'>
      {data && data.map((aData: { title: string; path: string; }, index: number)=>(
        <div key={index} className={index === activNb ? (aData.title !== prevTitle ? 'animated-presentiel-title' : 'presentiel-title'): 'presentiel-no-title'}><span className='title-span'>{aData.title}</span></div>
      ))}
    </div>
    <div className='carousel-position-group'>
    <div className='carousel-group'>    
    <SlidingContainer activNb={activNb} id= "scrolling-container" translateX={translateX} aDivWidth={refDivWidth} length={data.length} className='carousel-container'>{data && data.map((aData: { title: string; path: string; }, index: number) => (
      <SlidingCarouselElt activNb={activNb} key={index} index={index} id={aData.title+index} data={data} length={data.length} alt={aData.title} image={aData.path}>
    </SlidingCarouselElt>
    ))}
    </SlidingContainer>
    </div>
    </div>
    <div className='carousel-effect'></div>
    </div>
  )
}
