import React, { useEffect, useRef, useState } from 'react'
import ContactForm from '../components/ContactForm'
import {AiOutlineInstagram, AiOutlineMail} from 'react-icons/ai';
import {AiOutlineFacebook} from 'react-icons/ai';
import {AiOutlinePhone} from 'react-icons/ai';
import AnimatedNavbar from '../components/AnimatedNavbar';
import MyAxiosClass from '../api/phpApi';
import PageTitle from '../components/PageTitle';


export default function Contact({...props}) {
  
  const timerRef = useRef<any>(null);
  const [captchaCltKey, setCaptchaCltKey] = useState<string>("");
  const [conditionsOk, setConditionsOk] = useState<boolean>(false);
  
  
  /* mount */
  useEffect(() => {
    props.setClickedNavId(6);
    window.scrollTo({top: -500, behavior: 'smooth'}); 
    timerRef.current = setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 200);
   
    
    initFromApi();
    async function initFromApi() {
      try {
        const myClientKey = await MyAxiosClass.getClientApiKey();
        if (myClientKey !== undefined) {
          if (myClientKey.myKey) {                       
            setCaptchaCltKey(myClientKey.myKey);
          } else { // clientKey.error
            console.log("erreur key form index : " + myClientKey.error);
          }
          
          
        }
      } catch (error) {
        console.log("on ne récupère pas de clé du serveur, bbouuuhh")
      }
    }
    
    return () => {
      props.setClickedNavId(-2);
      console.log("on quitte contact");
      clearTimeout(timerRef.current);
      
    }
    
 
  
  }, [])
  useEffect(()=>{
    console.log("contact update : conditionsOk ", conditionsOk)
  })
  return (
    <><AnimatedNavbar {...props}/>
    <div className={props.className}>
    <PageTitle title="Contact"/>   
      <div className='intro-contact'>
        Une envie ? Novice ou amateur, n'hésitez plus ! 
      </div>
      <div className='social-icons-wrapper'>        
        <div className='icon-line'>
          <a className='a-contact' href="mailto:ingrid.equilys@gmail.com"><AiOutlineMail className='outline-icons' /><span>equilys.ingrid@gmail.com</span></a>
        </div>
        <div className='icon-line'>
          <AiOutlinePhone className='outline-icons' /><a href="tel:+33622941167">06 22 94 11 67</a>
        </div>
        <div className='icon-line'>
          <a href="https://www.facebook.com/EquiLys.ingrid"><AiOutlineFacebook className='outline-icons' /></a><span>Suivez-moi sur Facebook</span>
        </div>
        <div className='icon-line'>
          <a href="https://www.instagram.com/equilys__/"><AiOutlineInstagram className='outline-icons' /></a><span>Et sur Instagram</span>
        </div>
      </div>
     
      <ContactForm
       conditionsOk={conditionsOk}
       setConditionsOk={setConditionsOk}
       cltApiKey={captchaCltKey}                               
       initialMessage="Taille maxi : 500 caractères"
       index={0}
       initialContent="Merci de laisser votre message et vos coordonnées"
      
      />
    </div></>
  )
}
