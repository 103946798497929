import React, { useContext, useEffect, useRef } from 'react'
import { TheContext } from '../contexts/EquilysContext';
import ScrollingCarousel from './ScrollingCarousel';

export default function HeroPresta({...props}) {
  const myContext = useContext(TheContext);
  const timerRef = useRef<any>(null);
  
  useEffect(() => {   
   
    if(myContext.animState === "update"){    
      timerRef.current = setTimeout(()=>{
      myContext.setAnimState("done");
    }, 100)
    
  }else{
      timerRef.current = setTimeout(()=>{
      myContext.setAnimState("done");
    }, 200)
  }
 
    return () => {
      myContext.setAnimState("update");
      clearTimeout(timerRef.current);
    }
  }, [])
  useEffect(()=>{
    const heroHeight = document.getElementById(props.id)?.getBoundingClientRect().bottom;
    if (heroHeight && heroHeight > 0 && heroHeight !== props.heroBottom){
      props.letsUpdateHero(heroHeight);
    }    
  }) 
  

  const heroImage = props.isSmallDevice ? props.telHero : props.hero;
  return (
    <div id="heroWrapper" className={(props.clickedNavId !== -2) ?'hero-wrapper':'start-hero-wrapper'}>     
        <div className='stable-title'>{props.title1}</div>
        <ScrollingCarousel {...props} together={props.together} id={props.id}/>
    </div>
  )
}
