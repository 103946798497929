import React, { useEffect, useRef, useState } from 'react'
import { Carticle, areEqualDates, convertDateToFr } from '../data';
import { GoCalendar } from 'react-icons/go';



function ObservableDates(props: Carticle) {
    const [dateIsVisible, setDateIsVisible] = useState<boolean>(false);
    const dateRef = useRef<any>(null);
    useEffect(()=>{
       /**
             * Observe visible dates
             */
       const myObserver = new IntersectionObserver(entries=>{
        if(entries[0].isIntersecting){
            setDateIsVisible(true);
            console.log("isIntersecting date");
            /* tabsRef.current.classList.add("active"); */
        }else{
            setDateIsVisible(false);
        }
    });
    myObserver.observe(dateRef.current);
        return () => {
            myObserver.disconnect();
          }
    }, [])
   
    return (
        <div ref={dateRef} className={dateIsVisible?'main-actu-compo-date':'main-actu-compo-date-hidden'}>
            <GoCalendar className='alaune-calendar' />
            <div className='home-actu-date-wrapper'>{props.dt_end && !areEqualDates(props.dt_start, props.dt_end) && "Du "}{props.dt_start && convertDateToFr(new Date(props.dt_start))}</div>
            {props.dt_end && !areEqualDates(props.dt_start, props.dt_end) && <div className='home-actu-date-wrapper'> au {props.dt_end && convertDateToFr(new Date(props.dt_end))}</div>}
        </div>
    )
}

export default ObservableDates
