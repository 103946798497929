import React, { useEffect, useState } from 'react'



export default function ArticleImage({...props}) {
  const[articleIsVisible, setArticleIsVisible] = useState<{id: string, isVisible: boolean}>({id:"toanim", isVisible:false});
  const windowHeight = window.innerHeight;
  
  useEffect(() => {
      window.addEventListener('scroll', checkIndexScroll, false);
      window.addEventListener('resize', checkIndexScroll, false);
  
    return () => {
      window.removeEventListener('scroll', checkIndexScroll, false);
      window.removeEventListener('resize', checkIndexScroll, false);
  
    }
  }, [])
  
  const calculateImgSize=()=>{     
    
    const ratio = 16/9;
    let imageHeight=200;
    let imageWidth=imageHeight*ratio;
    if(props.isSmallDevice){
      imageHeight= 100;
      imageWidth= imageHeight*ratio;
    }
  
    return {imageHeight: imageHeight, imageWidth: imageWidth};
  }

 
  
  function formatVisibleDiv(theDiv: HTMLElement) {        
    // la div
    const topTitle = theDiv.getBoundingClientRect().top;
    const bottomTitle = theDiv.getBoundingClientRect().bottom;
    const isVisible = (bottomTitle > 0 && topTitle < windowHeight) ? true : false;
    return isVisible;
  }
  function handleDivVisibility(cardDiv: HTMLElement) {
    const isVisibleDiv = formatVisibleDiv(cardDiv);
    setArticleIsVisible(
      {
          id: cardDiv.id,
          isVisible: isVisibleDiv ? true : false
      });
  }
  const checkIndexScroll = () => {       
        //handleDivVisibility(blockToAnim);        
          const imageBlock = document.getElementById(props.id);
          if(imageBlock){              
              handleDivVisibility(imageBlock); 
          }
        }

  return (
    
    <div className={articleIsVisible.isVisible ? 'p01-image-article-outcard' : 'p01-image-article-outcard-onload'}>
    <img
      style={{ width: calculateImgSize().imageWidth, height: calculateImgSize().imageHeight }}
      id={props.id}
      src={props.path}
      alt={props.alt} 
      className={"p01-article-img-prop"}
      />
      
      </div>
  )
}
