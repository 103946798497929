import React from 'react'
import { GiMultipleTargets } from 'react-icons/gi'

interface Props {}

function TargetFile(props: Props) {
    const {} = props

    return (
        <span className="cgv-pad"> Fiche Objectifs <GiMultipleTargets className='form-simple-icon'/></span>
    )
}

export default TargetFile
