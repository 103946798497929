import React from 'react'

import mek02 from '../images/home/Mek_trans.jpg';

interface Props{
    isActiv: boolean
}

function BackgroundMek(props: Props) {
    const {} = props

    return (
        <div className='background-manager'>
            <img className={props.isActiv === true ? 'background-home-first': 'background-home-bckg'} src={mek02} alt="Mek">
        </img>
      
   </div>
    )
}

export default BackgroundMek
