import React, { ReactNode, useEffect, useRef } from 'react'

interface Props {
    className: string | undefined;
    children: ReactNode;
    id?: string;
}

export default function ObservedCompo(props: Props) {
   const observedRef = useRef<any>(null);
   useEffect (()=>{
    const myObserver = new IntersectionObserver((observed)=>{
        if(observed[0].isIntersecting && observedRef.current){
            observedRef.current.classList.add("active");
        }else{
            observedRef.current.classList.remove("active");
        }
    })
    if(observedRef.current){
        myObserver.observe(observedRef.current);
    }
    return ()=>{
        myObserver.disconnect();
    }
}, [])

    return (
        <div id={props.id && props.id} ref= {observedRef} className={props.className}>{props.children}</div>
    )
}


