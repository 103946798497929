import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MyAxiosClass from '../api/phpApi';
import { Carticle } from '../data';
import FollowPoints from './FollowPoints'
import HomeActu from './HomeActu';

export default function ALaUne({...props}) {

    const navigate = useNavigate();
   
    useEffect(() => {
    
      return () => {
      
      }
    }, [])
    


    
    

 
  return (
    <div className='alaune-container'>
        <div className='alaune-title-wrapper'>         
        <h1 className='alaune-title'> Prochains stages</h1>
        <p className='alaune-intro'>Voici la liste des prochains évènements. </p>
        <p className='alaune-intro'> N'hésitez pas à vous manifester pour y participer ou initier d'autres rendez-vous !</p>
        <FollowPoints/>
        </div>
        <HomeActu/>
    </div>
  )
}
