import React from 'react'
import effect from "../images/common/decoEffect.png";



function PageTitleCom({...props}) {
    
    return (
        <div className='titlecom-wrapper'>
            <h2 className="com-title" >{props.title}</h2>
            <img className='title-effect' src={effect} alt="Effet titre"></img>
        </div>
    )
}

export default PageTitleCom
