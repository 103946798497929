import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AnimatedNavbar from '../components/AnimatedNavbar';
import PageTitle from '../components/PageTitle';
import TarifsCompo from '../components/TarifsCompo';
import { priceData } from '../data'
import mek02 from '../images/home/Mek_trans.jpg';
import background from '../images/price/background.jpg';


export default function Tarifs({...props}) {
  
  const timerRef = useRef<any>(null);
  const navigate = useNavigate();
  const state = {...props};
  const link = "/contact";
  const handleClick = () =>{
    if(link){
      navigate(link, state);
    }
  }
    useEffect(() => {
      props.setClickedNavId(5);
      window.scrollTo({top: -500, behavior: 'smooth'}); 
      timerRef.current = setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 200);
    
    return () => {
      props.setClickedNavId(-2);
      clearTimeout(timerRef.current);
      
    }
  
  }, [])
  return (
    <>
    <AnimatedNavbar {...props} id="tarifs" />
    <div className='background-manager'>      
      <img className='background-price-first' src={mek02} alt="Mek"></img>
     
    </div>
    <div className={props.className}>
    
<div className='intro-price-container'>
  <div className='formules-pagetitle'>
    <div className='intro-price-wrapper'>
    
    <img className='intro-price-image' src={background} alt="Mek&Ingrid"></img>
      <div className="intro-price-text-wrapper">« J'ai pour habitude de dire qu' il n' y a pas de hasard dans la vie, seulement des rendez-vous, des similitudes... 
       <p>À vous de choisir quelle sera sa thématique » </p></div>
      </div>
    
    <PageTitle title="Formules"/>
    </div>
    <div className='gradient'></div>
  </div>
  
    <TarifsCompo {...props} priceData={priceData} handleClick={handleClick}/>
    </div>

</>
  )
}
