import React, { useEffect, useRef, useState } from 'react'

interface Props {
    /* children: ReactNode;  */ 
    launchTelAnim: boolean;
    key: number;
    aNumber: string;
    activNumber: number;
    telNb: number;
    phoneLength: number;
}

function TelNumber(props: Props) {    
    const numberRef = useRef<any>(null);
    const {aNumber, activNumber, telNb, phoneLength} = props;
    const [modifiedNumber, setModifiedNumber] = useState<string>(aNumber);
    useEffect(()=>{     
       //ici
        if ( activNumber >= telNb){            
            if (activNumber === telNb){
                if(activNumber === phoneLength-1){
                    setModifiedNumber(aNumber);
                }else{
                    setModifiedNumber(aNumber + "| ");
                }
                
            }else{
                setModifiedNumber(aNumber);
            }
            numberRef.current.classList.add('active'); 
        }else{
            numberRef.current.classList.remove('active');
        }
    }) 

    return (
        <span ref={numberRef} className='tel-number'>{modifiedNumber}</span>
    )
}

export default TelNumber
