import React, { useEffect, useRef } from 'react';
import {NavLink} from 'react-router-dom';
import { navEltsFooter } from '../data';
import logo from '../images/common/equilys.png';
import BackgroundMek from './BackgroundMek';
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlinePhone } from 'react-icons/ai';

interface NavEltsI{
  id: number,
  label: string,
  path: string
}

export default function MentionsFooter({...props}) {
  const observerRef = useRef<any>(null);

  useEffect(()=>{
    const observer = new IntersectionObserver((results)=>{
      if(results[0].isIntersecting){
        if(observerRef){
          observerRef.current.classList.add("active");        
        }
      }else{
        observerRef.current.classList.remove("active");        
      }
    })
    observer.observe(observerRef.current);
    
    return ()=>{
      observer.disconnect();
    }
  }, [])
  return (
    <>    
    <div className="mentions-footer-wrapper">
    <BackgroundMek isActiv={true}/>
    <div className='mentions-container'ref={observerRef}>    
    <div className='mentions-logo-wrapper'>  
    <h2 className='mentionslst-span'>Ingrid Equilys</h2>
    <img src={logo} alt="Logo Ingrid Equilys" className='logo-footer'></img>
    
    </div>
    <div className='mentions-logo-wrapper'>&nbsp;</div>
    <div className='mentions-wrapper-footer'>
    <h2 className='mentionslst-span'>Contact</h2>
    <div className='icon-line-footer'>
          <AiOutlinePhone className='outline-icons' /><a className='icon-line-footer' href="tel:+33622941167">06 22 94 11 67</a>
        </div>
        <div className='icon-line-footer'>
          <a href="https://www.facebook.com/EquiLys.ingrid"><AiOutlineFacebook className='outline-icons' /></a><span>Suivez-moi sur Facebook</span>
        </div>
        <div className='icon-line-footer'>
          <a href="https://www.instagram.com/equilys__/"><AiOutlineInstagram className='outline-icons' /></a><span>Et sur Instagram</span>
        </div>
    </div>
    <div className='mentions-wrapper'>
    <h2 className='mentionslst-span'>Menu</h2>
    </div>
      <div className='mentions-wrapper'>         
          {navEltsFooter.map((navElt: NavEltsI, index: number)=>(
            index < 5 &&
            <div className='nav-element' key={navElt.id}>
            <div className='navlink-wrapper'> <NavLink className="mentionslst-span" 
              onClick={(e: any)=> props.onClick(navElt.id)}
              key={navElt.id} 
              to={navElt.path}
              
              >
               {navElt.label}
              </NavLink> 
              </div>
              </div>
              
          ))}
     
      </div>
    
    
    
    <div className='mentions-wrapper'>
    {navEltsFooter.map((navElt: NavEltsI, index: number)=>(
            index >= 5 &&
            <div className='nav-element' key={navElt.id}>
            <div className='navlink-wrapper'> <NavLink className="mentionslst-span" 
              onClick={(e: any)=> props.onClick(navElt.id)}
              key={navElt.id} 
              to={navElt.path}
              
              >
               {navElt.label}
              </NavLink> 
              </div>
              </div>
              
          ))}
      </div>
      <div className='mentions-logo-wrapper'> 
      <span className='mentionslst-span'>Prises de vues</span>
    
    </div>
    <div className='mentions-logo-wrapper'>
    <h2 className='mentionslst-span'>Salomé Dubart ©</h2>
    </div>
    </div>
   
    </div>
   
</>
  )
}


