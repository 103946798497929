import React from 'react'
import { CPriceData, CpriceDetails } from '../data';
import ButtonShine from './ButtonShine';
import ObservedCompo from './ObservedCompo';

export default function TarifsCompo({...props}) {
  return (
    <div className={props.className}>
      {props.priceData && props.priceData.map((aPrice: CPriceData)=>(
    <div className='price-container' key={aPrice.id}>
        <div className='gradient-top'></div>
      <div className='price-separation'>     
        <ObservedCompo id={aPrice.id} className="f-s-title-price">{aPrice.title}</ObservedCompo></div>
        
      <div className='price-details-wrapper'>              
        {aPrice.priceDetails && aPrice.priceDetails.map((aPriceData: CpriceDetails, index: number)=>(      
         
          <div key={aPriceData.id} className='price-article'>
          <div className='price-col-label'>
                {aPriceData.label}
             </div>             
              <div 
             
              className='price-col-price'>
                {aPriceData.price.toLocaleLowerCase() !== "sur devis" && <span className='euro-style'>€</span>}{aPriceData.price}
             
             </div>             
             <div className='price-col' id="details">
                {aPriceData.label2 && aPriceData.label2}
             </div>
             <div className='price-col'>
                <ButtonShine
                 position={false}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => { props.handleClick(); } } label="CONTACT" />
              </div>
             <div 
             id={aPrice.id}
             className='price-col-title'>                
                  <p>{aPriceData.title}</p>                
              </div>             
          </div>
               
        ))}    
       
       
      </div> 
      {aPrice.title.toLowerCase().includes("cours") && 
        <div className='note-wrapper'><span className='asterisque'>Info + </span><span>Ingrid se déplace dans les écuries ou chez vous. Les frais de déplacement sont à prévoir en plus du coût de la prestation.</span></div>}
      {aPrice.title.toLowerCase().includes("stage") && 
        <div className='note-wrapper'><span className='asterisque'>Info + </span><span>Des frais de déplacement sont à prévoir en plus du coût de la prestation suivant la région.</span></div>}
      
      <div className='gradient'></div>
    </div>  
   
    ))}
  </div>
  )
}
