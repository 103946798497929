import React, { useEffect, useRef } from 'react'


interface Props {pdfSource: string, imgSrc: string, title: string}

function ComModules(props: Props) {
    const {pdfSource, imgSrc, title} = props
    const observedRef = useRef<any>(null);
useEffect(()=>{
    const myInterceptor = new IntersectionObserver((observed)=>{
        if(observedRef.current){
            if(observed[0].isIntersecting){
                observedRef.current.classList.add("activ");
            }else{
                observedRef.current.classList.remove("activ");
            }
        }
    })
    myInterceptor.observe(observedRef.current);
    return ()=>{
        myInterceptor.disconnect();
    }
})
    
    return (
        <div className='home-comModule' ref={observedRef}>
            <div className='module-wrapper'>
                <h2 className='com-download-subtitle'>{title}</h2>
                
                <a href={pdfSource} target="_blank">
                <img className='module-img' src={imgSrc} alt="module initiation com animale">
                </img>                
                </a>
                <div className='download-info'>Le programme</div>
                
            </div>
        </div>
    )
}

export default ComModules
