
import { useEffect, useRef, useState } from 'react';
import AppearingCarouselElt from './AppearingCarouselElt';


export default function AppearingCarousel({...props}) {
  const data = props.together;
  const timerRef = useRef<any>(null);
  const [activNb, setActivNb] = useState<number>(0);  
 
  useEffect(() => {    
      if (timerRef.current){
          clearTimeout(timerRef.current);
      }  
      timerRef.current = setTimeout(() => {
          if(activNb<data.length-1){
            setActivNb(activNb + 1);              
            }else{
              setActivNb(0);               
          }          
      }, 5000);
  
    return () => {
      clearTimeout(timerRef.current);
    }
  })
  return (
    <div id={props.id && props.id} className={props.container}>     
    <div className='stable-title'>Accompagnement en ligne</div>
          {data && data.map((aData: { title: string; path: string; }, index: number)=>(
            <div key={index} 
            className={index === activNb ? 'appearing-stamp' : 'hidden-stamp'}>       
                <span className='appear-title-span'>{aData.title}</span>
              </div>
          ))}
     
   
        {data && data.map((aData: { title: string; path: string; }, index: number) => (
          <AppearingCarouselElt 
            activNb={activNb} 
            key={index} 
            index={index} 
            id={aData.title+index} 
            data={data} 
            length={data.length} 
            alt={aData.title} 
            image={aData.path}>
            </AppearingCarouselElt>
        ))}
        
   
    </div>
  )
}
