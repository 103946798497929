import React from 'react'
import intro01 from "../images/home/Intro01.jpg";
import mek02 from '../images/home/Mek_trans.png';

import Transition from './Transition';
import PageTitle from './PageTitle';
export default function HomeIntro() {
  return (
   <div className='home-intro-container'>
    <PageTitle title="Présentation"/>
    <div className='home-intro-wrapper'>   
      <img src={intro01} className="home-intro-image"></img>
      <div className='home-intro-text-wrapper'>  
        <div className='home-intro-text'>
          <p className='home-intro-text-first'>
          Monitrice BPJEPS, Ingrid propose une pédagogie différente, fondée sur la remise en question
          et l'écoute. 
          </p>
          <p className='home-intro-text-second'>          
          Elle favorise en douceur le dialogue cavalier | cheval et plus globalement la communication
          entre l'Homme et l'animal, pour avancer sereinement quel que soit l'objectif.    
          </p>          
        </div>
        <img className='home-intro-text-background' src={mek02} alt="image présentation"></img>
      </div>

    </div>
   
   </div>
  )
}
