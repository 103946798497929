import React, { useState } from 'react'
import savePdf from '../images/common/savePdf.png';



function SavePdf({...props}) {
    const [infoPrint, setInfoPrint] = useState<string>("");

    return (
        <div className='btn-pdf-wrapper'>
        <button 
        onPointerOver={()=>setInfoPrint("Télécharger le pdf")}
        onPointerOut={()=>setInfoPrint("")} 
        onClick={()=>{props.printDocument()}} >
            <img src={savePdf} className={props.isToPrint?'pdf-icon_hidden':'pdf-icon'}></img>
        </button>
        <h3 className='info-print'>{infoPrint}</h3>
        </div>
    )
}

export default SavePdf
