import React, { useState } from 'react';
import UploadSign from './UploadSignature';
import logo from '../images/common/equilys.png';
import { Ibooking, Irider } from '../data';


interface Props {
    isToPrint: boolean,
    rider: Irider,
    comLevels: string[],
    activities: Ibooking[]

}



export default function PdfFicheToPrint(props: Props) {
   
   const goalsPh = "Vos objectifs : ";
   const probsPh = "Vos problématiques : ";  
 
    
    const{rider, activities} = props;
    
    return (
        <div className='pdf-print-wrapper'>       
            <h1 className='pdf-print-title'>Fiche objectif</h1>
            <div className='pdf-container-print'>
                <div className='print-pdf-wrapper'>
                <h2 className='obj-form-subtitle'>Cavalier</h2>
                    <div className='civil-wrapper-print'>                        
                        <img src={logo} alt="Ingrid Equilys logo" className='pdf-logo-equilys-print'></img>
                        <div className='civil-state'>
                            <div className='print-name-wrapper'>
                                <div className='obj-form-details'>
                                <span className='input-title-print'>Nom</span>
                                <span className='obj-printable-input'>
                                    {rider.lastname}
                                </span>
                                </div>
                                <div className='obj-form-details'>
                                <span className='input-title-print'>Prénom</span>
                                <span className='obj-printable-input'>
                                    {rider.firstname}
                                </span>
                                </div>
                            <div className='obj-form-details'>
                                <label className='input-title-print'>Date de naissance</label>
                                <span className='small-obj-printable-input'>
                                    {rider.birthdate}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='printable-h3'>Coordonnées du cavalier</h3>
                    <div className='print-coord-wrapper'>                    
                    <div className='obj-form-details-toprint'>
                    
                    
                    <span className='input-label-print title'>Adresse : </span> 
                    <span className='input-label-print'>
                        {rider.address[0] &&rider.address[0]}
                    </span>
                        {rider.address[1] && <span className='input-label-print'>
                            {rider.address[1]}
                        </span>}
                        <span className='input-label-print'>{rider.cp}
                        </span>                   
                        <span className='input-label-print'>
                            {rider.city}
                        </span>
                    </div>                   
                    
                    <div className='print-address-wrapper'>
                        <div className='stable-wrapper'>
                        <span className='input-label-print title'>Téléphone : </span>                  
                            <span className='input-label-print'>{rider.phone}
                            </span>
                        </div>
                        <div className='stable-wrapper'>    
                        <span className='input-label-print title'>Adresse mail : </span>                        
                            <span className='input-label-print'
                            >{rider.mail}
                            </span>
                        </div>
                    </div>                    
                    <div className='stable-wrapper'>
                    <span className='input-label-print title'>Adresse de l'écurie : </span>
                    <span className='input-label-print'>
                        {rider.stable.split("\n").map((aStable: string, index : number)=><span key={index}>{index<rider.stable.length-1 ? " " + aStable + " " : aStable }</span>)}
                    </span>
                    </div>
                    <h3 className='printable-h3'>Informations cavalier</h3>
                    <div className='print-address-wrapper'>
                            <div className='print-address-wrapper'>
                            <div className='stable-wrapper'>  
                            <span className='input-label-print title'>Niveau équestre</span> <span className='input-label-print'> Galop : {rider.level}</span>
                            </div>
                            <div className='stable-wrapper'>
                            <span className='input-label-print title'>Numéro de licence : </span> <span className='input-label-print'>{rider.licenceNb}</span>
                            </div>
                        </div>
                    </div>
                    <h3 className='printable-h3'>{goalsPh}</h3>
                    <div className='goals-wrapper'>
                        <div className='textarea-render'>
                            {rider.goals.split("\n").map((aGoal:string, index: number)=><span key={index}>{index<rider.goals.length-1 ? aGoal + "  " : aGoal }</span>)}
                        </div>
                    </div>
                    <h3 className='printable-h3'>{probsPh}</h3>
                    <div className='goals-wrapper'>
                        <div className='textarea-render'>
                            {rider.problems.split("\n").map((aProb:string, index: number)=><span key={index}>{index<rider.problems.length-1 ? aProb + "  " : aProb }</span>)}
                        </div>
                    </div>
                    <div className='obj-form-dispos'>
                        <label className='input-label-print title' >Disponibilités du mois (préciser si aléatoire)</label>
                        <span className='obj-printable-input'>
                            {rider.dispos}
                        </span>
                    </div>
                    <div className='checkbox-wrapper-print'>
                <h3 className='printable-h3'>Je m'inscris pour : </h3>
             
            <div className='checkbox-group-print'>
            {activities.map((anActivity: Ibooking, index: number)=>(
                <div key={index} className='activity-wrapper-print'>
                <input 
                id={anActivity.id} 
                name={anActivity.id} 
                type="checkbox"
                defaultChecked={anActivity.toBook}                
                >                
                </input>
                <label htmlFor={anActivity.id}>{anActivity.label}</label>
                {anActivity.id === "com" && <span> Niveau {anActivity.option}</span>}
                
                </div>
            ))}
                </div>
               <div className='sentence'>
               <span className='input-label-print'>Je soussigné(e) &nbsp;{rider.signatureName} déclare avoir pris connaissance de ce formulaire objectif et atteste de l'exactitude des informations
               (parents pour les mineurs)</span>
               </div>
               </div>
            </div>
                </div>                
            </div>
        </div>

)}


