
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import PdfFicheInfo from '../components/PdfFicheInfo';
import UploadSign from '../components/UploadSignature';
import SavePdf from '../components/SavePdf';
import ButtonShine from '../components/ButtonShine';
import { EquilysMail } from '../components/SiteInfos';




export default function FicheObjectif({...props}) {
  const [isToPrint, setIsToPrint] = useState(false);
  const [askForNext, setAskForNext] = useState(false);
  const confirmRef = useRef<any>();
/**
    * 
    * @param param0 BASICS
    * @returns 
    */
let options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit' || 'short',
  day: '2-digit' || 'numeric'
 /*  weekday: 'long' */
};
  const localeFormat = "fr-fr";
  options = { year: 'numeric', month: 'short', day: 'numeric' };

  const [signature, setSignature] = useState<{signaturePlace: string, signatureDate: string}>({signaturePlace: "", signatureDate: new Date().toLocaleDateString(localeFormat, options)})
  const changeSignatureZone = (e: React.ChangeEvent<HTMLInputElement>)=>{
    const {name, value} = e.currentTarget;
    setSignature({...signature, [name]: value})
}
  useEffect(()=>{
    if(isToPrint){       
      const input = document.getElementById('divToPrint');
  
    if(input){
        html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
          /* pdf.output('dataurlnewwindow'); */
          pdf.save("FicheObjectif.pdf");
          // reset printable area visible
          setIsToPrint(false);
          // ask the user if he wants to fill-in contactFile
          setAskForNext(true);
        })
        ;
    }
  }
  if (askForNext && confirmRef){
    confirmRef.current.focus();
  }
  
     
  }, [isToPrint]);

  
      const printDocument=()=> {
        console.log("print");
        setIsToPrint(true);      
      }
      return (
    <div className='file-container'>
      
        <div id="divToPrint" className={isToPrint? "printable-pdf":"editable-pdf"}>             
        <PdfFicheInfo signature={signature} isToPrint={isToPrint}/>
        <UploadSign signature={signature} isToPrint={isToPrint} changeSignatureZone={changeSignatureZone} />
        <SavePdf isToPrint={isToPrint} printDocument={printDocument}/>
        </div>
        <div className={askForNext ? 'ask-user-window' : 'hidden-user-window'}>
        La fiche Objectif a bien été enregistrée dans vos téléchargements.
        <p>Vous y êtes presque ! Merci de compléter maintenant la fiche Contact et envoyer le tout (fiche objectif et fiche contact) par <span className="important">mail </span> à &nbsp;
        <EquilysMail/>
        </p>
        <ButtonShine 
          theRef={confirmRef}
          onClick={(e: React.MouseEvent<HTMLButtonElement>)=>{
            props.setActivForm("contact");
            setAskForNext(false);
          
          }
        } 
        label="Suivant"/>         
        
      </div>
    </div>
      
      );
     
    }
   
