

import styled, {keyframes} from "styled-components";
import '../style/App.css'

export default function FallBackFrame() {
 
 
  interface pointInter{
   id: number;
   label: string;
 }
  const points = [{id : 1, label: "."}, {id : 2, label: "."}, {id : 3, label: "."}]
  return (
        
          <div className = "points-wrapper">         
              {points.map((aPoint: pointInter)=>(               
               <MovingLoading key={aPoint.id} >{aPoint.label}</MovingLoading>  
               
              ))}     
                    
              
          </div>
        
      
  
   
  )
}

export const slideOut = keyframes `
0% {      
  transform: translateX(0%);
}   
100% {       
    transform: translateX(100%);
}

`
export const MovingLoading = styled.div`
    animation: .5s linear 1s infinite alternate ${slideOut}; 
    text-align: left;
    color: var(--appMainColor);
    font-size: 2em;
    padding: 0.1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    :nth-child(2n){
        animation: .5s linear .5s infinite alternate ${slideOut}; 
    }
`
