import React from 'react'

interface Props {}

function Transition(props: Props) {
    const {} = props

    return (
        <div className='transition'>&nbsp;</div>
    )
}

export default Transition
