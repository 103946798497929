import React, { useState } from 'react';
import logo from '../images/common/equilys.png';
import PdfFicheToPrint from './PdfFicheToPrint';
import { Ibooking, Irider } from '../data';

interface Props {isToPrint: boolean, signature:{signatureDate: string, signaturePlace: string}}

function PdfFicheInfo(props: Props) {
   const taMaxSize = 240;
   const taRows = 4;
   const taColumns = 60;
   const taText = "en "+taMaxSize+" caractères max"
   const goalsPh = "Vos objectifs "+ taText;
   const probsPh = "Vos problématiques "+ taText;
   const stablePh= "Le cas échéant, l'adresse de la pension "+taText; 
  
    
    const defaultUser = {
        lastname: "",
        firstname: "",
        birthdate: "",
        city: "",
        cp: "",
        address: ["",""],
        phone: "",
        mail: "",
        stable: "",
        licenceNb: "",
        level: 1,
        problems: "",
        goals: "",
        dispos:"",
        signaturePlace: props.signature.signatureDate,
        signatureDate: props.signature.signaturePlace,
        signatureName:""
     
    }
   
  
    const [rider, setRider] = useState<Irider>(defaultUser);
    const [message, setMessage] = useState({stable:"", goals:"", problems:""});
    

    const levels = ["1","2","3","4","5","6","7","8"];
    const comLevels = ["1", "2", "3"];
    const [activities, setActivities] = useState([
        {id:"live", label: "Coaching présentiel", toBook: false, option:""}, 
        {id: "web", label:"Coaching en ligne", toBook: false, option:""}, 
        {id: "comfo", label: "Formation communication animale", toBook: false, option:""},
        {id: "video", label:"Coaching vidéo", toBook: false, option:""},
        {id: "stage", label:"Stage", toBook: false, option:""},
        {id: "com", label:"Communication animale", toBook: false, option:""},
    ])
      

    
    
    const handleBooking = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) =>{
        const {name, value} = e.currentTarget;
        console.log("name : ", name);
        console.log("value : ", value);
        const tempBookings = activities.map((anActivity: Ibooking)=>{
            return {...anActivity, 
                toBook: anActivity.id === name ? (anActivity.toBook.toString() === "true"?false:true):anActivity.toBook,
                option: anActivity.id+"Level" === name && anActivity.id === "comfo" ? value : ""
            
            };
        })
        setActivities(tempBookings);
        console.log("booking : ", tempBookings);        
        console.log ("name :  ", name);
        console.log ("value :  ", value);
    }
    
    const handleTextAreaChange=(e: React.ChangeEvent<HTMLTextAreaElement>)=>{
        const {name, value} = e.currentTarget;
        console.log("name : ", name);
        setMessage({...message, [name]: ""});    
        
        if( countRows(value) < 4 && value.split("").length < taMaxSize){
            setRider({ ...rider, [name]: value });
        }else{
            console.log("on set message ", name);
            setMessage({...message, [name]:"Vous avez dépassé le nombre de lignes ou de caractères autorisés"});
        }
      

    }
    const handleAddressChange=(e: React.ChangeEvent<HTMLInputElement>, pIndex: number)=>{
        console.log ("rider on change : ", rider);
        const tempAddress = rider.address.map((currentAddress: string, index: number)=> {
            return index === pIndex ? e.currentTarget.value.toString() : currentAddress })   
            setRider({
            ...rider, 
            address: tempAddress}) 
    }
    function countRows(input: string): number{
        const stringTable = input.split("\n");
        let lastElement=-1;
       for (let i = 0; i<stringTable.length; i++){
        lastElement = i;
       }
       return lastElement;
    }
 
    return (
        <>
        {!props.isToPrint? <div className={!props.isToPrint?'pdf-form-container':'pdf-form-hidden'}>        
        <h1 className='pdf-form-title'>Fiche objectif</h1>
        <div className='pdf-container'>
        <div className='pdf-wrapper'>
        <h2 className='obj-form-subtitle'>Cavalier</h2>
        <div className='civil-wrapper'>                        
        <img src={logo} alt="Ingrid Equilys logo" className='pdf-logo-equilys'></img>
        <div className='civil-state'>
        <div className='name-wrapper'>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor="lastname">Nom</label>
        <input
        id="lastname"
        name="lastname"
        type="text"                         
        value={rider.lastname}
        placeholder="Votre nom"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, lastname: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='firstname'>Prénom</label>
        <input
        name="firstname"
        type="text"
        id="firstname"
        value={rider.firstname}
        placeholder="Votre prénom"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, firstname: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        </div>
        <div className='date-wrapper'>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='birthdate'>Date de naissance</label>
        <input
        id="birthdate"
        name="birthdate"
        type="text"                          
        value={rider.birthdate}
        placeholder="Votre date de naissance"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, birthdate: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        </div>
        </div>
        </div>
        <h3>Coordonnées du cavalier</h3>
        <div className="address-wrapper">
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='address1'>Adresse ligne 1</label>
        <input
        name="address1"
        id="address1"
        type="text"
        value={rider.address[0]}
        placeholder="Votre adresse"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(e, 0)}
        className='obj-form-input'
        >
        </input>
        </div>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='address2'>Adresse ligne 2</label>
        <input
        name="address2"
        id="address2"
        type="text"
        value={rider.address[1]}
        placeholder=" "
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(e, 1)}
        className='obj-form-input'
        >
        </input>
        </div>
        </div>
        <div className='coord-wrapper'>
   
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='postalCode'>Code postal</label>
        <input
        name="postalCode"
        id="postalCode"
        type="text"
        value={rider.cp}
        placeholder="Votre code postal"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, cp: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='city'>Ville</label>
        <input
        name="city"
        id="city"
        type="text"
        value={rider.city}
        placeholder="Nom de votre ville"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, city: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        </div>
        <div className='coord-wrapper'>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='phone'>Téléphone</label>
        <input
        name="phone"
        id="phone"
        type="text"                    
        value={rider.phone}
        placeholder="Votre téléphone"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, phone: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='email'>Adresse mail</label>
        <input
        name="email"
        id="email"
        type="email"                           
        value={rider.mail}
        placeholder="Votre adresse mail"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, mail: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        </div>
        
        <div className='stable-wrapper'>
        <h3><label className='input-label' htmlFor='stable'>Adresse de l'écurie</label></h3>
        <textarea
        maxLength={taMaxSize}
        wrap="hard"
        rows={taRows}
        cols={taColumns}
        placeholder={stablePh}
        id="stable"
        name="stable"
        value={rider.stable}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)}
        className={props.isToPrint ? 'ta-form-hidden' : 'ta-form-input'}
        style={{resize:"none", overflow: "hidden"}}
        >
        </textarea>
        <span className='warning'>{message.stable && message.stable}</span>
        <div className='textarea-render'>
        {/* rider.stable.split("\n").map((aStable: string, index : number)=><p key={index}>{aStable}</p>) */}
        </div>
        </div>
        <h3>Informations cavalier</h3>
        <div className='coord-wrapper'>
        <div className='obj-form-details'>
        <label className='input-label' htmlFor='licence'>Numéro de licence</label>
        <input
        id="licence"
        name="licence"
        type="text"                         
        value={rider.licenceNb}
        placeholder="Votre numéro de licence"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, licenceNb: e.currentTarget.value.toString() })}
        className='obj-form-input'
        >
        </input>
        </div>
        <div className='obj-form-details'>
        
        <label className='input-label' htmlFor='level'>Niveau équestre</label>
        <div className='coord-wrapper'>
        <label className='input-label'>Galop </label>
        
        <select
        name="level"
        id="level"                                
        value={rider.level}        
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setRider({ ...rider, level: parseInt(e.currentTarget.value.toString(), 10) | 0 })}
        className='obj-form-select-ar'
        >
        {levels && levels.map((aLevel: string, index: number) => (
            <option
            key={index}
            /* selected={aCategory.id === props.category} */
            value={parseInt(aLevel, 10)}
            >
            {aLevel}
            </option>
            ))}
            </select>
            </div>
            </div>
            </div>
            <h3>Vos objectifs en quelques mots...</h3>
            <div className='goals-wrapper'>                        
            <textarea
            maxLength={taMaxSize}
            wrap="hard"
            rows={taRows}
            cols={taColumns}
            placeholder={goalsPh}
            id="goals"
            name="goals"
            value={rider.goals}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)}
            className={props.isToPrint ? 'ta-form-hidden' : 'ta-form-input'}
            style={{resize:"none", overflow: "hidden"}}
            >
            </textarea>
            <span className='warning'>{message.goals && message.goals}</span>
            <div className='textarea-render'>
            {/* rider.goals.split("\n").map((aGoal:string, index: number)=><p key={index}>{aGoal}</p>) */}
            </div>
            </div>
            <h3>Vos problématiques en quelques mots...</h3>
            <div className='goals-wrapper'>                        
            <textarea
            maxLength={taMaxSize}
            wrap="hard"
            rows={taRows}
            cols={taColumns}
            placeholder={probsPh}
            id="problems"
            name="problems"
            value={rider.problems}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)}
            className={props.isToPrint ? 'ta-form-hidden' : 'ta-form-input'}
            style={{resize:"none", overflow: "hidden"}}
            >
            </textarea>
            <span className='warning'>{message.problems && message.problems}</span>
            <div className='textarea-render'>
            {/* rider.problems.split("\n").map((aProb:string, index: number)=><p key={index}>{aProb}</p>) */}
            </div>
            </div>
            <div className='obj-form-details'>
            <label className='input-label' htmlFor='dispos'>Disponibilités du mois (préciser si aléatoire)</label>
            <input
            name="dispos"
            id="dispos"
            type="text"                    
            value={rider.dispos}
            placeholder="Vos disponibilités"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRider({ ...rider, dispos: e.currentTarget.value.toString() })}
            className='obj-form-input'
            >
            </input>
            </div>
            </div>
            <div className='checkbox-group'>
                <h3>Je m'inscris pour : </h3>
            <div className='checkbox-group'>
            {activities.map((anActivity: Ibooking, index: number)=>(
                <div key={index} className='activity-wrapper'>
                <input 
                id={anActivity.id} 
                name={anActivity.id} 
                type="checkbox"
                checked={anActivity.toBook}
                onChange={(e: React.ChangeEvent<HTMLInputElement>)=>handleBooking(e)}
                >                
                </input>
                <label htmlFor={anActivity.id}>{anActivity.label}</label>
                {anActivity.id === "comfo" && <span className='level-txt'>Niveau</span>}
                {anActivity.id==="comfo" &&<select
                
                name="comfoLevel"
                id="comfoLevel"                                
                value={anActivity.option}                
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleBooking(e)}
                className='obj-form-select-ar'
                >
                {comLevels && comLevels.map((aLevel: string, index: number) => (
                    <option
                    key={index}
                    value={aLevel}
                    >
                    {aLevel}
                 </option>
                ))}
            </select> }
                </div>
            ))}
                </div>
               <div className='sentence'>
               <h3>Je soussigné (e)</h3><input name="signatureName" type="text" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>setRider({ ...rider, signatureName: e.currentTarget.value})} value={rider.signatureName} className='obj-form-input'/> 
               (parent ou représentant légal majeur pour les mineurs)
               </div>
               déclare avoir pris connaissance de ce formulaire objectif et atteste de l'exactitude des informations renseignées
               </div>
            </div>
            </div>
            
           :            
            <PdfFicheToPrint comLevels={comLevels} isToPrint={props.isToPrint} rider={rider} activities={activities}/>
            
           
            
        }
        
        </>
    )
}
   

export default PdfFicheInfo
