import React from 'react';
import teachIntro from '../images/home/teachIntro.png';
import HomeArticleImages from './HomeArticleImages';
import home01 from '../images/home/homeTeachBckg.png';
import ButtonShine from './ButtonShine';
import { useNavigate } from 'react-router-dom';

export default function HomeTeaching({...props}) {
  
  const navigate = useNavigate();
  
  return (
    
   <div className='home-teaching-container'>
      <img className='home-teaching-text-background' src={home01} alt="fond enseignement"></img>
      <div className='home-teaching-wrapper'>
        <div className='home-teaching-text-wrapper'>
          <h1 className='home-teaching-title'>Enseignement</h1>
          <div className='home-teaching-text'>
            <p className='home-teaching-text-first'>
              Un enseignement diversifié pour la réussite dans la décontraction
            </p>
            <p className='home-teaching-text-second'>
              La connexion commence à pied, avec l'equifeel (dialogue et réussite commune sur des challenges progressifs), l'étude du comportement et les longues rênes ; elle prend tout son sens en dressage et en équitation de travail, où le couple ne fait plus qu'un.
            </p>
            <ButtonShine label="En savoir plus..." onClick={() => navigate('/enseignement')} />
          </div>

        </div>

        <img src={teachIntro} className="home-teaching-image"></img>

      </div>
      <div className='home-article-container'>
        {props.imgs &&
          <HomeArticleImages
            isSmallDevice={props.isSmallDevice}
            id={"imgBlock" + props.articleNb}
            articleNb={props.articleNb}
            images={props.imgs} />}
      </div>
      <div className='home-teaching-title-wrapper'></div>

    </div>
  )
}
