import React, { useEffect, useState, useRef, useContext } from 'react'
import {  Carticle, autoCompleteDetails, handleIconDisplay } from '../data'
import MyAxiosClass from '../api/phpApi';
import SideTabs from './SideTabs';
import { TheContext } from '../contexts/EquilysContext';
import { ArticlesWrapper, PassedArticles } from '../common/StyledComponents';
import Bubble from './Bubble';
import ObservableDates from './ObservableDates';
import FollowPoints from './FollowPoints';
import { TbHorseToy, TbClockPin } from 'react-icons/tb';



export default function ActuCompo({...props}) {
    const [articles, setArticles] = useState<Carticle[]>();
    const divObserverRef = useRef<any>(null);      
    const myContext = useContext(TheContext);
    const [articleHeight, setArticleHeight] = useState<number>(0);
    const [activYears, setActivYears] = useState<number[]>(initPrevYears(new Date().getFullYear(), 3));
    
    
    
    function initPrevYears (activYear: number, nbYears: number){
        let tempYears: number[] = [];        
        for (let i=activYear; i>= activYear - nbYears; i--){
            tempYears = [...tempYears, i];
        }    
        return tempYears;
    }
    
    /**
    * 
    * @param param0 BASICS
    * @returns 
    */
    let options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit' || 'short',
        day: '2-digit' || 'numeric',
        weekday: 'long'
    };  
    
 
    function furtherArticles():Carticle[] | undefined{
        const furtherArticles = articles && articles.filter((anArticle: Carticle)=>(new Date(anArticle.dt_start).getTime() >= new Date().getTime()))
        if(furtherArticles){
            furtherArticles.sort((a, b) => new Date(a.dt_start).getTime() - new Date(b.dt_start).getTime());
            return furtherArticles;
        }
        
    }
    function previousArticles(aYear: number):Carticle[] | []{
        const previousArticles = articles && articles.filter((anArticle: Carticle)=>(new Date(anArticle.dt_start).getTime() < new Date().getTime() && anArticle.dt_start.getFullYear() === aYear))
        if(previousArticles){
            previousArticles.sort((a, b) => new Date(a.dt_start).getTime() - new Date(b.dt_start).getTime());
            return previousArticles;
        }else{
            return [];
        }
        
    }
    /**UPDATE */
    useEffect(()=>{
        console.log("props actu compo : ", props);
        activYears && activYears.forEach((aYear: number)=>{
            if (aYear === myContext.clickedYear){           
                let newHeight = document.getElementById("articles-wrapper"+aYear)?.getBoundingClientRect().height;
                if(newHeight === 0){newHeight = 100};
                if(newHeight !== articleHeight && newHeight){
                    setArticleHeight(newHeight);
                }
            }
        })
        
        const tempActivYears = initPrevYears(new Date().getFullYear(), 3);
        if (activYears.length === 0){
            setActivYears(tempActivYears);
        }
    })
    /**MOUNT */
    useEffect(() => {
        /**
        * Observe pastArticles visible
        */
        const myObserver = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting){
                /* setIsIntersecting(true); */
                myContext.setTabsAreVisible(true);
                console.log("isIntersecting");
                //tabsRef.current.classList.add("active");
            }else{
                myContext.setTabsAreVisible(false);
            }
        });
        myObserver.observe(divObserverRef.current);
        
        
        getArticlesFromApi();
        async function getArticlesFromApi(){
            const loadedData = await MyAxiosClass.getArticles();
            let tempArticles: Carticle[];
            if (loadedData !== undefined && loadedData.articles !==undefined && loadedData.articles.data !== undefined){
                
                tempArticles = Object.values(loadedData.articles.data).map((anArticle: any)=>{
                    let respArticle =new Carticle(parseInt(anArticle.article_id, 10), anArticle.article_title, anArticle.title, anArticle.at, parseInt(anArticle.category, 10), new Date(anArticle.dt_start), (anArticle.dt_end ? new Date(anArticle.dt_end):null), anArticle.details, anArticle.icon, parseInt(anArticle.company, 10), anArticle.image, anArticle.folder);
                    respArticle.hour = anArticle.hour ? anArticle.hour : "";
                    respArticle.option1 = (anArticle.option1 && (anArticle.option1.toString() === "1" || anArticle.option1 === true)) ? true : false;
                    return respArticle;
                     
                })
                if(tempArticles){
                    setArticles(tempArticles);
                    
                }
            }
        }
        myContext.setClickedYear(new Date().getFullYear())
        return () => {
            myObserver.disconnect();
        }
    }, [])
    
    
    return (
        <div className="actu-container">
        <div className='main-actu-compo-container'>        
        <SideTabs activYears={activYears} isIntersecting={myContext.tabsAreVisible}/>
        <div className='main-actu-compo-wrapper'>
       
        <div className='intro-further-actu'>
        <p>Toujours prête pour de nouveaux défis, Ingrid vous propose 
        de partager entre cavaliers et montures, stages de travail
        et expériences inédites
        </p>
        <h2 className='main-actu-compo-title2'>
        <div className='main-actu-intro'>Prochainement <div className='actu-points-wrapper'><FollowPoints /></div> </div>
        </h2>
        </div>
        <div className='main-actu-compo-articles-wrapper'>                
        {articles && furtherArticles()?.map((anActu: Carticle, index)=>(
            <div key={anActu.article_id} className='main-actu-compo-article'>                        
            {!props.isSmallDevice && index%2 === 0 ? 
                <>
                <div className='main-actu-main-block'>
                {anActu.image && <img className='main-actu-compo-img' src={anActu.image && anActu.image}></img>}
                </div>
                <div className='main-actu-side-block'>
                <div className='main-actu-compo-titleblock'>
                <span className='main-actu-block-title'>{anActu.article_title}</span>
                </div>
                <Bubble wrapperClassName="bubble-wrapper-small">
                {anActu.category ? handleIconDisplay(anActu.category, 'bubble-icon') : null}
                </Bubble>
                
                
                <ObservableDates {...anActu}/>
                <div className='main-actu-compo-textblock'>
                <div className="subtitle-textblock">Infos </div>
                {autoCompleteDetails(anActu.details, anActu.category).map((aDetail: string, index: number) => (
                    <p key={index} className='detail-row'>{aDetail}           
                    </p>
                ))}
                    </div>
                    {anActu.at && <div className='main-actu-compo-textblock'>
                    <div className="subtitle-textblock">Lieu </div>
                    {anActu.at}
                    </div>}
                    {anActu.hour &&   
                        <div className='detail-row'>                     
                        <div className="subtitle-textblock">Horaire <TbClockPin/></div>
                        <p className='detail-row'>{anActu.hour} </p>
                        
                        </div>                        
                    }
                    {anActu.option1 && <div><TbHorseToy className='baby-horse'/> <span className='info-italic'>Accessible aux jeunes chevaux</span></div>}
                    </div>
                    
                    </>
                    :
                    
                    <>
                    <div className='main-actu-side-block'>
                    <div className='main-actu-compo-titleblock'>
                    <span className='main-actu-block-title'>{anActu.article_title}</span>
                    
                    </div>
                    <Bubble wrapperClassName="bubble-wrapper-small">
                    {anActu.category ? handleIconDisplay(anActu.category, 'bubble-icon') : null}
                    </Bubble>
                    
                    
                    <ObservableDates {...anActu}/>
                    <div className='main-actu-compo-textblock'>
                    <div className="subtitle-textblock">Infos </div>
                    {autoCompleteDetails(anActu.details, anActu.category).map((aDetail: string, index: number) => (
                        <p key={index} className='detail-row'>{aDetail}
                        
                        </p>
                        ))}
                        </div>
                        {anActu.at && <div className='main-actu-compo-textblock'>
                        <div className="subtitle-textblock">Lieu </div>
                        {anActu.at}
                        </div>}
                        {anActu.hour &&   
                        <div className='detail-row'>                     
                        <div className="subtitle-textblock">Horaire <TbClockPin/></div>
                        <p className='detail-row'>{anActu.hour} </p>
                        
                        </div>                        
                    }
                    {anActu.option1 && <div><TbHorseToy className='baby-horse'/> <span className='info-italic'>Accessible aux jeunes chevaux</span></div>}
                        </div>
                        <div className='main-actu-main-block'>
                        {anActu.image && <img className='main-actu-compo-img-right' src={anActu.image && anActu.image}></img>}
                        </div>
                        </>
                        
                    }
                    { anActu.image&&<img className='main-bckg-actu-compo-img' src={anActu.image&&anActu.image}></img>}
                    </div>
                    ))}
                    
                    </div>
                    </div>
        </div>
        <div className='actu-hole'></div>
        <div ref={divObserverRef} className='main-actu-compo-container'>
                    <span className='intro-further-actu'>Retour sur de belles journées </span>
                    <h2 id="article-ref" className='main-actu-compo-title2'>
                    <div className='main-actu-intro'>Rétro <div className='actu-points-wrapper'><FollowPoints /></div> </div>
                    </h2>
                    
                    <PassedArticles height={articleHeight}>
                    {activYears && activYears.map((aYear:number, index: number)=>(
                        
                        <ArticlesWrapper id={"articles-wrapper"+aYear} showIt={ aYear === myContext.clickedYear} key={index} >                    
                        {articles && (previousArticles(aYear) && previousArticles(aYear).length>0 ? previousArticles(aYear)?.map((anActu: Carticle, index)=>(
                            <div key={anActu.article_id} className='main-actu-compo-article'>
                            {!props.isSmallDevice && index%2 === 0 ?
                                <>
                                <div className='main-actu-main-block'>
                                {anActu.image && <img className='main-actu-compo-img' src={anActu.image}></img>}
                                </div>
                                <div className='main-actu-side-block'>
                                <div className='main-actu-compo-titleblock'>
                                <span className='main-actu-block-title'>{anActu.article_title}</span>
                                </div>
                                <div className='main-actu-icon'>
                                <Bubble wrapperClassName="bubble-wrapper-small">
                                {anActu.category ? handleIconDisplay(anActu.category, 'bubble-icon') : null}
                                </Bubble>
                                </div>
                                <ObservableDates {...anActu}/>
                                <div className='main-actu-compo-textblock'>
                                <div className="subtitle-textblock">Infos </div>
                                {autoCompleteDetails(anActu.details, anActu.category).map((aDetail: string, index: number) => (
                                    <p key={index} className='detail-row'>{aDetail}
                                    {aDetail.toLocaleLowerCase().includes("jeunes chevaux") && <TbHorseToy className='baby-horse'/>}
                                    </p>
                                    ))}
                                    </div>
                                    {anActu.at && <div className='main-actu-compo-textblock'>
                                    <div className="subtitle-textblock">Lieu </div>
                                    {anActu.at}
                                    </div>}
                                    {anActu.hour &&   
                        <div className='detail-row'>                     
                        <div className="subtitle-textblock">Horaire <TbClockPin/></div>
                        <p className='detail-row'>{anActu.hour} </p>
                        
                        </div>                        
                    }
                    {anActu.option1 && <div><TbHorseToy className='baby-horse'/> <span className='info-italic'>Accessible aux jeunes chevaux</span></div>}
                                    </div>
                                    </>    
                                    :
                                    <>
                                    <div className='main-actu-side-block'>
                                    <div className='main-actu-compo-titleblock'>
                                    <span className='main-actu-block-title'>{anActu.article_title}</span>
                                    </div>
                                    <div className='main-actu-icon'>
                                    <Bubble wrapperClassName="bubble-wrapper-small">
                                    {anActu.category ? handleIconDisplay(anActu.category, 'bubble-icon') : null}
                                    </Bubble>
                                    </div>
                                    <ObservableDates {...anActu}/>
                                    <div className='main-actu-compo-textblock'>
                                    <div className="subtitle-textblock">Infos </div>
                                    {autoCompleteDetails(anActu.details, anActu.category).map((aDetail: string, index: number) => (
                                        <p key={index} className='detail-row'>{aDetail}
                                        {aDetail.toLocaleLowerCase().includes("jeunes chevaux") && <TbHorseToy className='baby-horse'/>}
                                        </p>
                                        ))}
                                        </div>
                                        {anActu.at && <div className='main-actu-compo-textblock'>
                                        <div className="subtitle-textblock">Lieu </div>
                                        {anActu.at}
                                        </div>}
                                        {anActu.hour &&   
                        <div className='detail-row'>                     
                        <div className="subtitle-textblock">Horaire <TbClockPin/></div>
                        <p className='detail-row'>{anActu.hour} </p>
                        
                        </div>                        
                    }
                    {anActu.option1 && <div><TbHorseToy className='baby-horse'/> <span className='info-italic'>Accessible aux jeunes chevaux</span></div>}
                                        </div>
                                        <div className='main-actu-main-block'>
                                        {anActu.image && <img className='main-actu-compo-img-right' src={anActu.image}></img>}
                                        
                                        </div>
                                        </>
                                    } 
                                    { anActu.image&&<img className='main-bckg-actu-compo-img' src={anActu.image&&anActu.image}></img>}                   
                                    </div> 
                                    )): 
                                    <div className='main-actu-compo-message'>
                                    Les stages de cette année ne sont pas disponibles
                                    </div>
                                    )}
                                    </ArticlesWrapper> 
                                    
                                    ))}
                                    </PassedArticles>
        </div>
        </div>
                                    
    )
}
