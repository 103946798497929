import React, { useEffect } from 'react';



export function InterceptImg ({...props}){
    const interceptorRef = React.useRef<HTMLImageElement>(null);

    useEffect(()=>{
     /**Interceptor for bubbles */
     const BubbleObserve = new IntersectionObserver((bubble)=>{
        if(bubble[0].isIntersecting && interceptorRef.current){          
          interceptorRef.current.classList.add("active");
        }else{          
          if (interceptorRef.current)
            interceptorRef.current.classList.remove("active");
        }
      })
      if(interceptorRef.current)
      BubbleObserve.observe(interceptorRef.current);
      return()=>{
        BubbleObserve.disconnect();
      }
},[])


return(<div className='presta-img'>
    <img ref={interceptorRef} src={props.anImg} className={props.delay?"presta-img-wrapper-delay":"presta-img-wrapper"} alt={props.aPresta && props.aPresta.details[props.key]}/>
</div>)
}