import React, { useContext, useEffect, useState } from 'react'
import { SideTabsWrapper, YearWrapper } from '../common/StyledComponents'
import { TheContext } from '../contexts/EquilysContext';


class Czindex{
    id: number;
    value: number;
    constructor(pId: number, pValue: number){
        this.id = pId;
        this.value = pValue;
    }
}


function initNextYears (activYear: number, nbYears: number){
    let tempYears: number[] = [];        
    for (let i=activYear; i < activYear + nbYears; i++){
        tempYears = [...tempYears, i];
    }    
    return tempYears;
}
const handlePrevClick = (e: React.MouseEvent<HTMLButtonElement>)=>{
    console.log ("charge prev");
}
const handleNextClick = (e: React.MouseEvent<HTMLButtonElement>)=>{
    console.log ("charge next");
}
function SideTabs( {...props}) {

    const activYears = props.activYears;
    const [zIndexes, setZindexes] = useState<(Czindex | undefined)[] | undefined>(initZindexes());
    const[showId, setShowId] = useState<number>(-1);
    const myContext = useContext(TheContext);
    
    function initActivYear(){        
        const tempActivYear = activYears && activYears.filter((aYear: number)=>(
            aYear === new Date().getFullYear()
        ));
           if (tempActivYear)
            return tempActivYear;
    }
    
    const handleYearClick=(e: React.MouseEvent<HTMLButtonElement>)=>{
        let tempZ = zIndexes;
        // clicked times=> change only first time clicked
        let times = [{id: -1, time: -1}];
        tempZ = zIndexes && zIndexes.map((zIndex: Czindex|undefined) => {
            if(zIndex){
                    times=[...times, {id: zIndex.id, time: -1}];
                    if (parseInt(e.currentTarget.name, 10) === zIndex?.id){
                        
                    if(times[zIndex.id].time < 0){
                        zIndex.value = zIndex.value + 100;
                        times=[...times, {id: zIndex.id, time: times[zIndex.id].time+1}];
                        setShowId(zIndex.id); 
                        console.log("showId : ", zIndex.id);
                        console.log("year : ", activYears[zIndex.id]);
                        myContext.setClickedYear(activYears[zIndex.id]);
                         
                    }
                }else{
                    // reinit positions
                    zIndex = new Czindex(zIndex?.id, Math.trunc((1/((zIndex?.id+1)*2))*100));
                }
            }
            return zIndex;
       });
       setZindexes(tempZ);    
       
    }
    function initZindexes(){
        const tempZindexes = activYears && activYears.map((aYear: number, index: number)=>{
            return new Czindex(index, Math.trunc((1/((index+1)*2))*100));
        })      
        return tempZindexes;
    }
useEffect(()=>{
    
    function initActivButton(){
        let tempZ = zIndexes;
        // clicked times=> change only first time clicked
        let times = [{id: -1, time: -1}];
        tempZ = zIndexes && zIndexes.map((zIndex: Czindex|undefined) => {
            if(zIndex){
                    times=[...times, {id: zIndex.id, time: -1}];
                    // 0 is id of current year
                    if (zIndex?.id === 0){                        
                    if(times[zIndex.id].time < 0){
                        zIndex.value = zIndex.value + 100;
                        times=[...times, {id: zIndex.id, time: times[zIndex.id].time+1}];
                        setShowId(zIndex.id); 
                        console.log("showId : ", zIndex.id);
                        console.log("year : ", activYears[zIndex.id]);
                        myContext.setClickedYear(activYears[zIndex.id]);
                         
                    }
                }else{
                    // reinit positions
                    zIndex = new Czindex(zIndex?.id, Math.trunc((1/((zIndex?.id+1)*2))*100));
                }
            }
            return zIndex;
       });
       setZindexes(tempZ);
    }
    initActivButton();
}, []);
    return (
        <SideTabsWrapper isIntersecting = {props.isIntersecting} position={window.innerHeight/6} nbTabs={activYears.length-1}>
            {/* <button onClick={(e: React.MouseEvent<HTMLButtonElement>)=>handlePrevClick(e)} className="tabs-prev">V</button> */}
            {activYears && activYears.map((aYear: number, index: number)=>(    
                <YearWrapper showIt={index === showId} name= {zIndexes && zIndexes[index]?.id} onClick={(e: React.MouseEvent<HTMLButtonElement>)=>handleYearClick(e)} key={index} zIndex={(zIndexes && zIndexes[index]) && zIndexes[index]?.value}>{aYear}</YearWrapper>           
            ))}
           {/*  <button onClick={(e: React.MouseEvent<HTMLButtonElement>)=>handleNextClick(e)} className="tabs-next">V</button> */}
        </SideTabsWrapper>
    )
}

export default SideTabs
