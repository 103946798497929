import React, { useEffect, useRef, useState } from 'react';
import background from '../images/common/equilys_plume.png';
import PageTitle from '../components/PageTitle';
import FicheObjectif from './FicheObjectif';
import Cgv from './Cgv';
import FicheContact from './FicheContact';
import {GiMultipleTargets} from 'react-icons/gi';
import {MdOutlineContactPage} from 'react-icons/md';
import {GoLaw} from 'react-icons/go';
import { EquilysMail } from '../components/SiteInfos';
import Jaleante from '../images/common/Jaleante.png';
import HeroNav from '../components/HeroNav';

export default function Booking({...props}) {

    const [activForm, setActivForm] = useState("objectif");
    const timerRef = useRef<any>(null);
    const [goalFileOk, setGoalFileOk] = useState(false);
    const [contacFileOk, setContactFileOk] = useState(false);
    const paymentInfos = [
      "Ingrid GAZEL ","IBAN FR69 2004 1010 0209 3768 6Z02 346","BIC PSSTFRPPCHA"];

      function usePrevious(value: any) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value; //assign the value of ref to the argument
        },[value]); //this code will run when the value of 'value' changes
        return ref.current; //in the end, return the current ref value.
    }
    console.log("activ : ", activForm);
      const prevTab = usePrevious(activForm)
      if (activForm !== prevTab){        
        const tabs = document.getElementById('tabs');        
        if (tabs){      
          console.log("tabs : ", tabs);
          tabs.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
        
      }
    useEffect(() => {
      props.setClickedNavId(-1);
      window.scrollTo({top: -500, behavior: 'smooth'}); 
      timerRef.current = setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 200);
   
      
    return () => {
      console.log("on quitte contact");
      clearTimeout(timerRef.current);       
    }
  
  }, [])

  let targetUrl: string;
  const email = "alient4%40hotmail.com";
  const site = "equilys-ingrid.fr";
 /*  targetUrl = "https://sejda.com/sign-pdf?files=http://localhost:3000/static/media/ficheInfo.3f144e3b56e99f1711e9.pdf"+ficheInfo; */
  targetUrl = "https://www.sejda.com/sign-pdf?files=%5B%7B%22downloadUrl%22%3A%22https%3A%2F%2Fingrid-equilys.alienshareonline.fr%2Fstatic%2Fmedia%2FficheInfo.3f144e3b56e99f1711e9.pdf%22%7D%5D&returnEmail=alient4%40hotmail.com";
  targetUrl = `https://www.sejda.com/sign-pdf?files=%5B%7B%22downloadUrl%22%3A%22https%3A%2F%2F${site}%2Fstatic%2Fmedia%2FficheInfo.3f144e3b56e99f1711e9.pdf%22%7D%5D&returnEmail=${email}`;
  
  const infos = {label: "Ingrid Equilys", tel : "06.22.94.11.67", mail : "equilys.ingrid@gmail.com", site: "https://equilys-ingrid.fr"};
  const heroProps = {...props, 
    telHero: Jaleante, 
    hero: Jaleante, 
    title1:"Ingrid Equilys", 
    title2:"Inscriptions",
    classNameImage: "hero-photo-com",   
    clickedNavId: props.clickedNavId,
    className: 'hero-com',
    heroTitle: "hero-com-title"
  }
  
  return (
    <>
    <HeroNav {...heroProps}/>      
    
    <div className={props.className}> 
    <div className='link-pdf'>
    <PageTitle title="Inscriptions en ligne"/>
    <img className='contact-title-background' src={background} alt="image présentation">
    </img>
    <p className='booking-intro'>Pour toute demande, vous pouvez contacter Ingrid au : <a href="tel:+33622941167">06 22 94 11 67</a></p>
    <p className='booking-intro'>L'inscription à un stage se fait par <span className="important">mail </span>
    à : <EquilysMail/></p>
    
    <p className='booking-intro'>S'il s'agit de votre première participation,  
     
     la fiche <span className="important">objectif</span> et la fiche <span className="important">contact</span> doivent être complétées et envoyées par <span className="important">mail </span> à <EquilysMail/></p>
     

     <p className='booking-intro'>Dans tous les cas, un virement bancaire à titre d'arrhes correspondant à <span className="important">50% du montant du stage</span> enterrinera votre participation.</p>
    <div className='booking-form-intro'>
     <p className='booking-intro'><span className='important'> Important :</span> Les fiches de renseignement qui suivent aideront Ingrid à vous connaître, à <span className='important'>cibler vos attentes</span> et à intervenir en cas d'<span className="important">urgence médicale.</span></p>
     <p className='booking-intro'> Merci de les compléter avec soin.</p>
     <p className='booking-intro'> Il est également important de bien lire les <span className='important'>conditions générales</span> qui encadrent l'accompagnement effectué par <span className="signature-mentions">Ingrid Equilys </span>: leur validation
    est une étape indispensable </p></div>
    <div id="tabs" className='form-tabs-wrapper'>
      <div className={activForm === "objectif" ?'activ-form-label':'left-form-label'} >
            <button  className={activForm === "objectif" ?'activ-button-wrapper':"button-wrapper"} id="objectif" onClick={(e:React.MouseEvent<HTMLButtonElement>)=>setActivForm(e.currentTarget.id)}>
                Fiche Objectifs <GiMultipleTargets className='form-simple-icon'/>
            </button>
      </div>
            <div className={activForm === "contact" ?'activ-form-label':'form-label'} >
            <button className={activForm === "contact" ?'activ-button-wrapper':"button-wrapper"} id="contact" onClick={(e:React.MouseEvent<HTMLButtonElement>)=>setActivForm(e.currentTarget.id)}>
            Fiche Contact <MdOutlineContactPage className='form-simple-icon'/>
            </button>
            </div>
            <div className={activForm === "cgv" ?'activ-form-label':'right-form-label'} >
              <button className={activForm === "cgv" ?'activ-button-wrapper':"button-wrapper"} id="cgv" onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                  setActivForm(e.currentTarget.id)
                  }}>
                  Conditions générales <GoLaw className='form-simple-icon'/>
              </button>   
            </div>
        </div>
    {activForm === "objectif" && <FicheObjectif  setActivForm={setActivForm} /> }
    {activForm === "contact" && <FicheContact setActivForm={setActivForm} paymentInfos={paymentInfos}/> }
    {activForm === "cgv" && <Cgv paymentInfos={paymentInfos} {...infos}/>}
        <div className='links-wrapper'>
        {/* <p><a href={ficheInfo} target='_blank'>
          Fiche contact sans signature
        </a></p>
        <a href={targetUrl} target='_blank'>Fiche de renseignements</a> */}
        </div>
      </div>
   </div>
    </>
  )
}
