
import { useLocation, useNavigate } from 'react-router-dom';
import cheval from '../images/common/signIn.jpeg';
import SignInForm from '../components/SignInForm'
import SignUpForm from '../components/SignUpForm'
import { useEffect, useRef } from 'react';
import AnimatedNavbar from '../components/AnimatedNavbar';


export default function Sign({...props}) {

  const location = useLocation();
  const navigate = useNavigate();   
  const timerRef = useRef<any>(null);
  
  /**MOUNT */
    useEffect(() => {
      props.setClickedNavId(-1);
      window.scrollTo({top: 50, behavior: 'smooth'}); 
      timerRef.current = setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 20);
    
    return () => {
      clearTimeout(timerRef.current);
      
    }
  
  }, [])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>)=>{
    console.log("click");
      const data = location.state; 
      const options = {state: data};
      console.log("options from sign : ", options);
      navigate('/reservation',  options);
  }
  

  return (
    <><AnimatedNavbar {...props} id="booking"  />
    <div className='sign-container'>
    <h2 className='h2-sign'>Réservation</h2>
      <div className='sign-form-container'>
        <div className='sign-image-wrapper'>
          <img src={cheval} alt="photo-sign" className='photo-sign'></img>
        </div>
        <div className='sign-wrapper'>
          <SignInForm btnLabel="Se connecter" onClick={(e: React.MouseEvent<HTMLButtonElement>)=>handleClick(e)} />
          <SignUpForm btnLabel="Créer et se connecter" onClick={(e: React.MouseEvent<HTMLButtonElement>)=>handleClick(e)} />
        </div>
      </div>

    </div></>
  )
}
