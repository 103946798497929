import React, { Component } from 'react'

interface IparentProps{
    children: React.ReactNode
}

const defaultState = {    
    animState:"init",
    clickedYear: 0,
    isDrawing: false,
    setAnimState: (pState: string)=>{},
    setClickedYear: (pClicked: number)=>{},
    setIsDrawing: (pIsDrawing: boolean)=>{},
    setTabsAreVisible: (pAreVisible: boolean)=>{},
    tabsAreVisible: false
    
}
const TheContext = React.createContext(defaultState);

export default class TheContextProvider extends Component<IparentProps> {
    constructor(props: IparentProps){
        super(props);
    }
    /**
     * init / done / update
     */
    state={
        animState:"init", 
        clickedYear: 0,
        isDrawing: false,
        tabsAreVisible: false
    }
    setIsDrawing = (pIsDrawing: boolean)=>{
        this.setState({isDrawing : pIsDrawing});
    }
    setAnimState=(pAnimState: string)=>{
        this.setState({animState: pAnimState});        
    }
    setClickedYear=(pClickedYear: number)=>{
        this.setState({clickedYear: pClickedYear});
    }
    setTabsAreVisible=(pAreVisible: boolean)=>{
        this.setState({tabsAreVisible: pAreVisible});
    }
  render() {
    return (
        <TheContext.Provider value={{
            ...this.state, 
            setAnimState: this.setAnimState,
            setClickedYear: this.setClickedYear,
            setIsDrawing: this.setIsDrawing,
            setTabsAreVisible: this.setTabsAreVisible
        }}>
        
        {this.props.children}
        </TheContext.Provider>
    );
  }
}
const TheContextConsumer = TheContext.Consumer;
export function withContext(Component: any){
    return function ConsumerWrapper(props: any){
        return <TheContextConsumer>
            {value=> <Component {...props} context={value}/>}
        </TheContextConsumer>
}}
export {TheContextProvider, TheContextConsumer, TheContext};