

export default function Bubble({...props}) {
  return (
    <div className={props.wrapperClassName}>
    <section className="stage">
      <figure className={props.ballClassName ? props.ballClassName + " bubble": "ball bubble"}>        
      </figure>
      {props.children}
    </section>
    </div>
  )
}
