import React, { useEffect, useRef, useState } from 'react'
import comImg from "../images/home/comHome.jpg";
import mek02 from '../images/home/Mek_trans_left.png';
import ComModules from './ComModules';
import ButtonShine from './ButtonShine';
import { useNavigate } from 'react-router-dom';

interface Props {}

function HomeAnimalCom(props: Props) {
    const {} = props
    const [launchAnim, setLaunchAnim] = useState(false);
    const [launchMek, setLaunchMek] = useState(false);
    const observerRef = useRef<any>(null);
    const secondRef = useRef<any>(null);
    const navigate = useNavigate();

    useEffect(()=>{
    /** line animation : launches when star appears*/
    const starObserver = new IntersectionObserver((entries)=>{
        if(entries[0].isIntersecting){
            setLaunchAnim(true);
        }else{
            setLaunchAnim(false);
        }
    })
    //imageAnimation on block appear
    const blockObserver = new IntersectionObserver((entries)=>{
        if(entries[0].isIntersecting){
            setLaunchMek(true);
        }else{
            setLaunchMek(false);
        }
    })
    starObserver.observe(observerRef.current);
    blockObserver.observe(secondRef.current);

    return () => {
        starObserver.disconnect();
        blockObserver.disconnect();
    }
    },[])
    

    const handleClick = () =>{
        navigate('/comanimale');
    }

    return (
        <div className='home-com-container'>
            <h1 className='home-animal-title'>Communication animale</h1>
            <div className='home-animal-container'>
            
            <div  id="home-star-anim" className='home-animal-first-group'>
                <div className={launchAnim ? 'home-animal-anim' : 'home-animal-hidden'}>
                </div>
                
            </div>
            
            <div className='home-animal-text-container'>            
                <div className='home-animal-wrapper'>
                    <div className='home-animal-text-first'>
                        La communication animale est un savoir ancestral qu'Ingrid pratique au quotidien et qui se transmet sans aucun prérequis
                    </div>
                    <div className='home-animal-text-second'>
                        Sur demande, Ingrid communique avec votre animal vivant ou défunt et vous ouvre les portes du dialogue.
                    </div>
                    <div className='home-animal-text-second'>
                        Elle propose aussi des stages et une formation en ligne sur 3 modules pour découvrir puis approfondir la communication animale.
                    </div>
                    
                    </div>
                    <div ref={observerRef} className='home-animal-wrapper2'>
                    <div ref={secondRef} className='home-animal-text-third'>
                    «Pour utiliser parfaitement la communication Animale, vous devez comprendre son fonctionnement et l'action que cela engendre chez vos animaux.. J’apprends chaque jour à découvrir la vie, ses détails… je m’émerveille par ces expériences qui m'entourent. À mon tour, je souhaite partager ce qu’elle me fait ressentir et découvrir lors de mes séances, en vous accompagnant personnellement, professionnellement, en duo avec votre monture. 
                    J'accompagne les couples à révéler le meilleur d'eux-mêmes et ça croyez-moi, ça passe par l'effet miroir.
                    »
                    </div>                  

                </div>
            </div>
            <div>&nbsp;</div>
            <div className='home-animal-img-wrapper'>
                <img className='home-animal-img' src={comImg} alt="communication animale" />
            </div>
            <div className='home-animal-img-button-wrapper'>
                <ButtonShine label="Découvrir" onClick={handleClick}/>                
                
            </div>
            
            <div className="img-com-position" >&nbsp;<img className={launchMek ? 'home-intro-text-anim':'home-intro-text-image'} src={mek02} alt="image présentation"></img></div>
        </div>
        
       

        </div>
    )
}

export default HomeAnimalCom
