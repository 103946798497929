import React from 'react'
import intro from "../images/com/fond.png";

interface Props {}

function MaskTest(props: Props) {
    const {} = props

    return (
        <img src={intro} alt="" className="svg-mask" />

    )
}

export default MaskTest


