import React from 'react'
import plume from '../images/common/equilys_plume.png';

interface Props {}

export const EquilysPlume=(props: Props) =>{
    const {} = props

    return (
        <img src={plume} alt="equilys plume" className='equilys-plume'/>
    )
}

export default EquilysPlume
